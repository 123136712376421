import React from "react";

// UI
import FormPromotion from "../FormPromotion/FormPromotion";

interface Props {
  row: any;
  edit?: boolean;
  onCompleted?: any;
  iconButton?: boolean;
}

const EditPromotion: React.FC<Props> = (props) => {
  return (
    <FormPromotion
      iconButton={props.iconButton}
      edit={props.edit}
      row={props.row}
      onCompleted={props.onCompleted}
    />
  );
};

export default EditPromotion;
