import React from 'react'

// UI
import uiStyles from '../../../assets/styles/styles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Paper,
  Grid,
  InputLabel,
  Typography,
  Container
} from '@material-ui/core'
import { FormattedDate } from '../../../components/FormattedData/FormattedData'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Loading from '../../../components/Loading/Loading'
import { ErrorAlert, SuccessAlert } from '../../../components/FlashMessage/FlashMessage'
import AppLocation from '../../../components/AppLocation/AppLocation'
import { SubmitActionBtn, LoaderBtn } from '../../../components/FormActionLinks/FormActionLinks'

// GraphQL
import { Query, Mutation } from 'react-apollo'
import { SHOW_USER_QUERY, UPDATE_USER_MUTATION } from '../repositoryGraphQL'




interface Props extends WithStyles<typeof uiStyles> {
  updateCurrentView: any
}

type State = {
  id: string
  firstName: string
  lastName: string
  email: string
  createdAt: string
  restaurant: {
    name: string
  }
}

class ShowProfile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      createdAt: '',
      restaurant: {
        name: ''
      }
    }
  }

  componentDidMount() {
    this.props.updateCurrentView({
      name: 'Moje konto'
    })
  }

  handleQueryComplete = (data: any) => {
    const { viewer } = data

    this.setState({
      id: viewer.id ? viewer.id : '',
      firstName: viewer ? viewer.firstName : '',
      lastName: viewer ? viewer.lastName : '',
      email: viewer.email ? viewer.email : '',
      createdAt: viewer.createdAt ? viewer.createdAt : '',
      restaurant: {
        name: viewer.restaurant ? viewer.restaurant.name : ''
      }
    })
  }

  render() {
    const { classes  } = this.props
    const { id, firstName, lastName, email, createdAt, restaurant } = this.state

    return (
      <Query
        query={SHOW_USER_QUERY}
        onCompleted={query_data => this.handleQueryComplete(query_data)}
        fetchPolicy="network-only"
      >
        {({ loading, error }) => {
          if (loading) return <Loading />
          if (error) return <ErrorAlert error={error} />
          return (
            <Mutation
              mutation={UPDATE_USER_MUTATION}
              variables={{ id, email, firstName, lastName }}
              onCompleted={(data: any) => this._confirm(data)}
            >
              {(submitForm: any, { loading, data, error }: any) => {
                return (
                  <>
                    <Paper className={classes.paper}>
                      <Container component="main">
                        <Typography className={classes.bottomSpace} component="h1" variant="h6">
                          Moje Konto
                        </Typography>
                        <ValidatorForm
                          ref="form"
                          onSubmit={submitForm}
                          onError={(errors: any) => console.log(errors)}
                        >
                          <Grid className={classes.bottomSpace} container spacing={2}>
                            <Grid item sm={4}>
                              <TextValidator
                                fullWidth
                                id="firstName"
                                label="Imię"
                                name="firstName"
                                autoComplete="firstName"
                                value={firstName}
                                onChange={(e: { target: { value: string } }) => this.setState({ firstName: e.target.value })}
                                validators={['required']}
                                errorMessages={['Pole wymagane.']}
                              />
                            </Grid>
                            <Grid item sm={8}></Grid>
                            <Grid item sm={4}>
                              <TextValidator
                                fullWidth
                                id="lastName"
                                label="Nazwisko"
                                name="lastName"
                                autoComplete="lastName"
                                value={lastName}
                                onChange={(e: { target: { value: string } }) => this.setState({ lastName: e.target.value })}
                                validators={['required']}
                                errorMessages={['Pole wymagane.']}
                              />
                            </Grid>
                            <Grid item sm={8}></Grid>
                            <Grid item sm={4}>
                              <TextValidator
                                required
                                fullWidth
                                id="email"
                                label="Adres e-mail"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={(e: { target: { value: string } }) => this.setState({ email: e.target.value })}
                                validators={['required', 'isEmail']}
                                errorMessages={['Pole wymagane.', 'Adres e-mail jest nieprawidłowy.']}
                              />
                            </Grid>
                            {restaurant && restaurant.name &&
                            <>
                              <Grid item sm={6}></Grid>
                              <Grid item sm={6}>
                                <InputLabel
                                  shrink
                                  className={classes.inputLabel}>
                                  Restauracja
                                      </InputLabel>
                                <Typography
                                  variant="body2"
                                  component="p"
                                  className={classes.typography}
                                >
                              {restaurant.name }
                                </Typography>
                              </Grid>
                            </>
                            }
                            <Grid item sm={6}></Grid>
                            <Grid item sm={6}>
                              <InputLabel
                                shrink
                                className={classes.inputLabel}>
                                Utworzono
                              </InputLabel>
                              <Typography
                                variant="body2"
                                component="p"
                                className={classes.typography}
                              >
                                {createdAt && <FormattedDate value={createdAt} />}
                              </Typography>
                            </Grid>
                            <Grid item sm={6}></Grid>
                          </Grid>
                          {!loading && <SubmitActionBtn text="Zapisz" />}
                          {loading && <LoaderBtn />}
                        </ValidatorForm>
                      </Container>
                    </Paper>
                    {(!loading && data) && <SuccessAlert />}
                    {error && <ErrorAlert error={error} />}
                  </>
                )
              }}
            </Mutation>
          )
        }}
      </Query>
    )
  }

  _confirm = async (data: any) => { }
}

export default AppLocation(withStyles(uiStyles)(ShowProfile))