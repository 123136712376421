import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

const drawerWidth = 260

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: {
      ...theme.mixins.toolbar,
      padding: '0.6em 0.1em',
      textAlign: 'center',
    },
  }),
)

export default uiStyles