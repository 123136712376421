import { createStyles, Theme } from '@material-ui/core/styles'

const uiStyles = (theme: Theme) => createStyles({
  paper: {
    position: 'relative',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  box: {
    position: 'relative',
    marginTop: theme.spacing(4),
  },
  actions_links: {
    marginTop: theme.spacing(2),
  },
  fab_edit: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  btn_add_contact: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  btn_add: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  btn_add_outside: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  title: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  inputLabel: {
    margin: theme.spacing(3, 0, 1, 1),
  },
  typography: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  btn_delete: {
    marginRight: theme.spacing(1)
  },
  fieldDate: {
    marginTop: theme.spacing(1),
    minWidth: '30%'
  },
  formControl: {
    minWidth: '50%',
  },
  dataPicker: {
    minWidth: '60%'
  }
})

export default uiStyles