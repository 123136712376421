export interface AuthUserState {
  clientProfile: {
    id?: string
    createdAt: string
  },
  firstName: string
  lastName: string
  email: string
}

export const SET_AUTH_USER = 'SET_AUTH_USER'

interface SetAuthUserAction {
  type: typeof SET_AUTH_USER
  payload: object
}


export type AuthUserActionTypes = SetAuthUserAction 