import React from 'react'

// UI
import uiStyles from '../../assets/styles/styles'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import {
  Paper,
  Typography,
  Badge,
} from '@material-ui/core'
import Loading from '../../components/Loading/Loading'
import { ErrorAlert } from '../../components/FlashMessage/FlashMessage'
import AppLocation from '../../components/AppLocation/AppLocation'
import DataGridEmpty from '../../components/DataGridEmpty/DataGridEmpty'

// GraphQL
import { Query } from 'react-apollo'
import { INDEX_WAITERS_QUERY } from './repositoryGraphQL'
import DataGridWaiters from './DataGridWaiters/DataGridWaiters'

const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    top: theme.spacing(2),
    right: theme.spacing(-2),
  },
}))(Badge)

interface Props extends WithStyles<typeof uiStyles> {
  updateCurrentView: any
}

class IndexWaiters extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.updateCurrentView({
      name: 'Kelnerzy'
    })
  }

  render() {
    const { classes } = this.props
    const activeOnly = false

    return (
      <Query
        query={INDEX_WAITERS_QUERY}
        variables={{ activeOnly }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loading />
          if (error) return <ErrorAlert error={error} />

          const { waiters } = data || {}

          return (
            <Paper className={classes.paper}>
              <StyledBadge badgeContent={waiters ? Object.keys(waiters).length : 0} color="primary">
                <Typography variant="h5" component="h3">
                  Kelnerzy
                </Typography>
              </StyledBadge>
              <Typography variant="caption" component="p">
                Zarządzanie kelnerami
              </Typography>
              <Typography className={classes.marginTop} variant="body2" component="div">
                {waiters.length > 0 ? <DataGridWaiters rows={waiters} /> : <DataGridEmpty />}
              </Typography>
            </Paper>
          )
        }}
      </Query>
    )
  }
}

export default AppLocation(withStyles(uiStyles)(IndexWaiters))