import React from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import ROUTES from '../../../routes'
import { prepareRouteUrl } from '../../../utils/utils'

import {
  Chip,
  Link,
  Tooltip,
  IconButton
} from '@material-ui/core'
import { RemoveRedEye } from '@material-ui/icons'
import { DataGridTable } from '../../../components/DataGridTable/DataGridTable'

interface Props  {
  rows: Array<{}>
  updateDataGrid?: any
}

interface State {
  rows: any
  columns: Array<{
    field: string
    title: string
    render?: any,
    cellStyle?: any,
    headerStyle?: any
  }>
}

class DataGridDictionaries extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      columns: [
        { field: 'name', title: 'Nazwa', render: row => this.createLink(row) },
        { field: 'sysName', title: 'Nazwa Systemowa', headerStyle: { textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: row => <Chip style={{ marginLeft: '-24px' }} label={row.sysName} className="countChip" size="small" /> },
        { field: 'actions', title: 'Akcje', headerStyle: { minWidth: '20%', textAlign: 'right' }, cellStyle: { textAlign: 'right' }, render: row => this.ActionsLinks(row) }
      ],
      rows: this.props.rows
    }
  }

  ActionsLinks = (row) => {

    let actions: Array<any> = []

    const showUrl: string = prepareRouteUrl(ROUTES.dictionaries.values.path, { sysName: row.sysName})

    actions.push(
      <Tooltip style={{ marginLeft: '-8px' }} key={`ShowId-${row.id}`} title="Podgląd">
        <IconButton aria-label="Show" size="medium" color="primary" component={LinkRouter} to={showUrl}>
          <RemoveRedEye fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )

    return actions
  }

  createLink = (row: any) => {
    const showUrl: string = prepareRouteUrl(ROUTES.dictionaries.values.path, { sysName: row.sysName })
    return (
      <Link component={LinkRouter} to={showUrl}>{row.name}</Link>
    )
  }

  render() {

    const {
      rows, columns
    } = this.state

    return (
      <DataGridTable columns={columns} rows={rows} />
    )
  }
}

export default DataGridDictionaries