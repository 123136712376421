import React from 'react'
import clsx from 'clsx'

// UI
import uiStyles from '../../assets/styles/styles'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import { Paper, Box, Typography, Badge, AppBar, Tabs, Tab } from '@material-ui/core'
import Loading from '../../components/Loading/Loading'
import { ErrorAlert } from '../../components/FlashMessage/FlashMessage'
import AppLocation from '../../components/AppLocation/AppLocation'
import DataGridEmpty from '../../components/DataGridEmpty/DataGridEmpty'

// GraphQL
import { Query } from 'react-apollo'
import { INDEX_RESTAURANTS_QUERY } from './repositoryGraphQL'
import DataGridRestaurants from './DataGridRestaurants/DataGridRestaurants'

const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    top: theme.spacing(2),
    right: theme.spacing(-2),
  },
}))(Badge)

interface Props extends WithStyles<typeof uiStyles> {
  updateCurrentView: any
}

type State = {
  tabIndex: number;
}

class IndexRestaurants extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      tabIndex: 0,
    }
  }

  componentDidMount() {
    this.props.updateCurrentView({
      name: 'Użytkownicy'
    })
  }

  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue,
    });
  };

  render() {
    const { classes } = this.props
    const { tabIndex } = this.state
    const activeOnly = false
    const types: Array<boolean> = [true, false]

    return (
      <Query
        query={INDEX_RESTAURANTS_QUERY}
        variables={{ activeOnly}}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <ErrorAlert error={error} />

          const { restaurants } = data
          const activated: number = restaurants ? restaurants.filter(restaurant => restaurant.activated).length : 0
          const toActivate: number = restaurants ? restaurants.filter(restaurant => !restaurant.activated).length : 0

          return (
            <>
              <Paper className={classes.paper}>
                <StyledBadge badgeContent={restaurants ? Object.keys(restaurants).length : 0} color="primary">
                  <Typography variant="h5" component="h3">
                    Restauracje
                  </Typography>
                </StyledBadge>
                <Typography variant="caption" component="p">
                  Zarządzanie restauracjami
                </Typography>
              </Paper>
              <AppBar position="static" color="default" className={classes.menuTabs}>
                <Tabs
                  value={tabIndex}
                  onChange={this.handleTabChange}
                  variant="fullWidth"
                  indicatorColor="secondary"
                >
                  <Tab label={`Aktywowane (${activated})`} />
                  <Tab label={`Do aktywacji (${toActivate})`} />
                </Tabs>
              </AppBar>
              <Paper className={classes.tabs}>
                <Typography className={classes.marginTop} variant="body2" component="div">
                  {
                    types.map((item, index) => (
                      <Box key={index} component="div" paddingLeft={2} paddingRight={2} className={clsx(classes.tab, tabIndex === index && classes.tabShow)}>
                        {restaurants ? <DataGridRestaurants rows={restaurants.filter(restaurant => restaurant.activated === item)} /> : <DataGridEmpty />}
                      </Box>
                    ))
                  }
                </Typography>
              </Paper>
            </>
          )
        }}
      </Query>
    )
  }
}

export default AppLocation(withStyles(uiStyles)(IndexRestaurants))