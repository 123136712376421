// GraphQL
import gql from 'graphql-tag'

export const INDEX_WAITERS_QUERY = gql`
  query{
    waiters{
      id
      email
      activationState
      lastActivityAt
      firstName
      lastName
      waiterProfile {
        id    
      }
    }
  }
`

export const SHOW_WAITER_QUERY = gql`
  query waiter($id : ID!){
    waiter(id: $id){
      id
      email
      activationState
      lastActivityAt
      lastLoginFromIpAddress
      firstName
      lastName
      waiterProfile {
        id
        accountNumber
        accountCountryCode
        birthDate
        city
        id
        postcode
        street
        createdAt
        tips {
          id 
          value
          settled
          transfer {
            settlement {
              id
              createdAt
            }
          }
          order {
            id
            uniqueNumber
            createdAt
            restaurant {
              id
              name
            }
          }
        }
      }
    }
  }
`
export const WAITER_UPDATE_MUTATION = gql`
  mutation($waiter_id: ID!, $street: String, $city: String, $postcode: String, $accountNumber: String, $accountCountryCode: String, $birthDate: ISO8601Date ){
    waiterUpdate(input:{id: $waiter_id, street: $street, city: $city, postcode: $postcode, accountNumber: $accountNumber, accountCountryCode: $accountCountryCode, birthDate: $birthDate }){
      errors {
        field
        messages
      }
    }
  }
`