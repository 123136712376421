import React from 'react'

// UI
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
  Button
} from '@material-ui/core'
import { ValidatorForm } from 'react-material-ui-form-validator'
import PaymentIcon from '@material-ui/icons/Payment'
import { ErrorAlert, SuccessAlert } from '../../../components/FlashMessage/FlashMessage'
import { CustomDialogTitle } from '../../../components/Dialog/Dialog'
import { ClosePopupBtn, SubmitActionBtn, LoaderBtn } from '../../../components/FormActionLinks/FormActionLinks'
import { FormattedDate } from '../../../components/FormattedData/FormattedData'

// GraphQL
import { Mutation } from 'react-apollo'
import { SETTLEMENT_MARK_SETTLED } from '../repositoryGraphQL'

interface Props {
  id: string
  createdAt: string
  icon?: boolean
  onCompleted: any
}

type State = {
  open: boolean
  confirm: boolean
}


class MarkSettled extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      open: false,
      confirm: false
    }
  }

  open = (): void => this.setState({ open: true })

  close = (): void => this.setState({ open: false })

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirm: e.target.checked })
  }

  render() {
    const { id, createdAt, icon } = this.props
    const { confirm } = this.state

    return (
      <>
        <Tooltip title="Rozlicz">
          {
            icon ? 
              <IconButton aria-label="Settlement" size="medium" onClick={this.open}>
                <PaymentIcon fontSize="inherit" />
              </IconButton>
            :
            <Button  style={{ marginTop: 30, marginLeft: 20 }} aria-label="Settlement" variant="outlined" size="small" onClick={this.open}>
              <PaymentIcon style={{marginRight: 10}} fontSize="inherit" /> Rozlicz
            </Button>
          }
            
          </Tooltip>
        <Mutation
          mutation={SETTLEMENT_MARK_SETTLED}
          variables={{ id }}
          onCompleted={(data: any) => this._confirm(data)}
        >
          {(submitForm: any, { loading, error, data }: any) => (
            <>
              <Dialog open={this.state.open} onClose={this.close} aria-labelledby="form-dialog-title">
                <CustomDialogTitle id="form-dialog-title" onClose={this.close}>
                Czy na pewno chcesz zmienić status rozliczenia ?
                </CustomDialogTitle>
                <ValidatorForm
                  ref="form"
                  onSubmit={submitForm}
                >
                  <DialogContent>
                    <DialogContentText>
                    Rozliczenie z dnia :
                    <Typography color="primary" component={'span'} display="block">{<FormattedDate format="DD.MM.YYYY HH:mm:ss" value={createdAt} />}</Typography>
                    </DialogContentText>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch checked={confirm} onChange={this.handleChange} value="1" />
                        }
                        label="Rozlicz"
                      />
                    </FormGroup>
                  </DialogContent>
                  <DialogActions>
                    {!loading && <ClosePopupBtn onClick={this.close} text="Anuluj" />}
                    {!loading && <SubmitActionBtn disabled={!confirm} text="Rozlicz" />}
                    {loading && <LoaderBtn />}
                  </DialogActions>
                </ValidatorForm>
              </Dialog>
              {(!loading && data) && <SuccessAlert message="Operacja wykonanan pomyślnie." />}
              {error && <ErrorAlert error={error} />}
              {(data && data.settlementMarkSettled.errors) && <ErrorAlert modelName="settlement" fieldError={data.settlementMarkSettled.errors} />}
            </>
          )}
        </Mutation>
      </>
    )
  }
  _confirm = async (data: any) => {
    if (!data) return

    this.setState({
      open: false,
      confirm: false
    })

    this.props.onCompleted()
  }
}

export default MarkSettled