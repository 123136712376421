import React from 'react'

// UI
import uiStyles from '../../assets/styles/styles'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import {
  Paper,
  Typography,
  Badge,
} from '@material-ui/core'
import Loading from '../../components/Loading/Loading'
import { ErrorAlert } from '../../components/FlashMessage/FlashMessage'
import AppLocation from '../../components/AppLocation/AppLocation'
import DataGridEmpty from '../../components/DataGridEmpty/DataGridEmpty'
import DataGridClients from './DataGridClients/DataGridClients'

// GraphQL
import { Query } from 'react-apollo'
import { INDEX_CLIENTS_QUERY } from './repositoryGraphQL'

const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    top: theme.spacing(2),
    right: theme.spacing(-2),
  },
}))(Badge)

interface Props extends WithStyles<typeof uiStyles> {
  updateCurrentView: any
}

class IndexClient extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.updateCurrentView({
      name: 'Klienci'
    })
  }

  render() {
    const { classes } = this.props

    return (
      <Query
        query={INDEX_CLIENTS_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loading />
          if (error) return <ErrorAlert error={error} />

          const { clients } = data || {}

          return (
            <Paper className={classes.paper}>
              <StyledBadge badgeContent={clients ? Object.keys(clients).length : 0} color="primary">
                <Typography variant="h5" component="h3">
                  Klienci
                </Typography>
              </StyledBadge>
              <Typography variant="caption" component="p">
                Zarządzanie klientami
              </Typography>
              <Typography className={classes.marginTop} variant="body2" component="div">
                {clients.length > 0 ? <DataGridClients rows={clients} /> : <DataGridEmpty />}
              </Typography>
            </Paper>
          )
        }}
      </Query>
    )
  }
}

export default AppLocation(withStyles(uiStyles)(IndexClient))