import React from 'react'

// UI
import uiStyles from '../../../../assets/styles/styles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormGroup,
  FormControlLabel,
  FormControl,
  Switch,
  Tooltip,
  IconButton
} from '@material-ui/core'
import TrashIcon from '@material-ui/icons/Delete'
import InputIcon from '@material-ui/icons/Input'
import { ValidatorForm  } from 'react-material-ui-form-validator'
import FormDictionarySelect from '../../../../components/FormDictionarySelect/FormDictionarySelect'
import { SuccessAlert, ErrorAlert } from '../../../../components/FlashMessage/FlashMessage'
import { CustomDialogTitle } from '../../../../components/Dialog/Dialog'
import { ClosePopupBtn, SubmitActionBtn, LoaderBtn } from '../../../../components/FormActionLinks/FormActionLinks'

// GraphQL
import { Mutation } from 'react-apollo'
import { ACTIONS_RESTAURANT } from '../../repositoryGraphQL'

interface Props extends WithStyles<typeof uiStyles>{
  id: string,
  name: string,
  disabled?: boolean,
  onCompleted?: any,
  type: string,
  iconButton?: boolean
}

type State = {
  open: boolean
  confirm: boolean
  headerDialogTxt: string,
  dialogTxt: string,
  btnTxt: string,
  alertTxt: string
  blockedReasonId: string
}

class ActionsUser extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      open: false,
      confirm: false,
      headerDialogTxt: '',
      dialogTxt: '',
      btnTxt: '',
      alertTxt: '',
      blockedReasonId: ''
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true, confirm: false })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirm: event.target.checked })
  }

  handleSelectChange = (value: any) => {
    this.setState({ blockedReasonId: value })
  }

  getErrorsForms = (data, type): Array<any> => {

    const errorsForms = {
      restaurantActivate: data.restaurantActivate ? data.restaurantActivate.errors : '',
      restaurantBlock: data.restaurantBlock ? data.restaurantBlock.errors : '',
      restaurantUnblock: data.restaurantUnblock ? data.restaurantUnblock.errors : '',
      restaurantDestroy: data.restaurantDestroy ? data.restaurantDestroy.errors : '',
      restaurantLoginAdmin: data.restaurantLoginAdmin ? data.restaurantLoginAdmin.errors : '',
    }
    return errorsForms[type]
  }


  render() {
    const { classes } = this.props
    const { id, name, disabled, type, iconButton } = this.props
    const { confirm, blockedReasonId } = this.state

    const actions = {
      restaurantActivate: {
        tooltip: 'Aktywuj restauracje',
        button: 'Aktywacja Restauracji',
        dialogTitle: 'Czy na pewno chcesz aktywować restaurację?',
        contentText: 'Potwierdź aktywację:',
        submitButton: 'Aktywuj'
      },
      restaurantBlock: {
        tooltip: 'Zablokuj Restauracje',
        button: 'Zablokuj',
        dialogTitle: 'Czy na pewno chcesz zablokować restaurację?',
        contentText: 'Potwierdź zablokowanie:',
        submitButton: 'Zablokuj'
      },
      restaurantUnblock: {
        tooltip: 'Odblokuj',
        button: 'Odblokuj',
        dialogTitle: 'Czy na pewno chcesz odblokować restaurację?',
        contentText: 'Potwierdź odblokowanie:',
        submitButton: 'Odblokuj'
      },
      restaurantDestroy: {
        tooltip: 'Usuń',
        button: 'Usuń',
        dialogTitle: 'Czy na pewno chcesz usunąć bezpowrotnie restauracje?',
        contentText: 'Potwierdź usunięcie:',
        submitButton: 'Usuń'
      },
      restaurantLoginAdmin: {
        tooltip: 'Zaloguj do restauracji',
        button: 'Zaloguj',
        dialogTitle: `Zaloguj do ${name}`,
        contentText: '',
        submitButton: 'Zaloguj'
      },
    }

    return (
      <>
         <Tooltip className={classes.marginLeft} title={actions[type].tooltip}>
         { iconButton ?
            <IconButton aria-label={actions[type].tooltip} size="medium" color="primary" onClick={this.handleClickOpen}>
              <InputIcon/>
            </IconButton>
            :
            <Button
              style={type === 'restaurantDestroy' ? {color: 'red'} : {}}
              aria-label={actions[type].tooltip}
              variant="outlined"
              size="small"
              onClick={this.handleClickOpen}
              disabled={disabled}
            >
              { type === 'restaurantDestroy' && <TrashIcon fontSize="small" /> }
              { actions[type].button }
            </Button>
        }
        </Tooltip>
        <Mutation
          mutation={ACTIONS_RESTAURANT[type]}
          variables={{ id, blockedReasonId }}
          onCompleted={(data: any) => this._confirm(data)}
        >
          {(submitForm: any, { loading, error, data }: any) => (
            <>
              <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                <CustomDialogTitle id="form-dialog-title" onClose={() => this.handleClose()}>
                  { actions[type].dialogTitle }
                </CustomDialogTitle>
                <ValidatorForm
                  ref="form"
                  onSubmit={submitForm}
                >
                  <DialogContent>
                    {
                      type === 'restaurantBlock' && 
                      <FormGroup row>
                        <FormControl fullWidth className={classes.formSelect}>
                          <FormDictionarySelect
                            label="Wybierz powód blokady"
                            name="blockedReasonId"
                            value={blockedReasonId}
                            dictionarySysName="closing-reasons"
                            itemId="id"
                            required
                            visibleLabel="name"
                            onChange={(value: any) => this.handleSelectChange(value)}
                            validators={['required']}
                            errorMessages={['Pole wymagane.']}
                          />
                        </FormControl>
                      </FormGroup>
                    }
                    {
                      type !== 'restaurantLoginAdmin' &&
                      <>
                         <DialogContentText className={type === 'restaurantBlock' ? classes.marginTop : ''}>
                         { actions[type].contentText }
                          <Typography color="primary" component={'span'} display="block">{name}</Typography>
                        </DialogContentText>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Switch checked={confirm} onChange={this.handleChange} value="1" />
                            }
                            label="Potwierdzam"
                          />
                        </FormGroup>
                      </>
                    }
                  </DialogContent>
                  <DialogActions>
                    {!loading && <ClosePopupBtn onClick={this.handleClose} text="Anuluj" />}
                    {!loading && <SubmitActionBtn disabled={type === 'restaurantLoginAdmin' ? false : !confirm} text={ actions[type].submitButton } />}
                    {loading && <LoaderBtn />}
                  </DialogActions>
                </ValidatorForm>
              </Dialog>
              {(!loading && data && !data.restaurantLoginAdmin) && <SuccessAlert />}
              {error && <ErrorAlert error={error} />}
              {(data && this.getErrorsForms(data, type)) && <ErrorAlert modelName="actionsRestaurant" fieldError={this.getErrorsForms(data,type)} />}
            </>
          )}
        </Mutation>
      </>
    )
  }

  _confirm = async (data: any) => {

    if (!data) return

    this.setState({
      open: false,
      confirm: false
    })

    if(data.restaurantLoginAdmin){
      const url = `${process.env.REACT_APP_RESTAURANT_URL}/adminLogin/${this.props.id}/${data.restaurantLoginAdmin.token}`
      window.open(url);
    }

    if(this.props.onCompleted) this.props.onCompleted()
  }
}

export default withStyles(uiStyles)(ActionsUser)