/**
 * App Constants
 */

export const AUTH_TOKEN: string = 'auth-token'
export const AUTH_ID: string = 'auth-id'

/**
 * 
 * App restaurant status
 * 
 */

 export const RESTAURANT_STATUS = {
  new: { text:'Nowa', color: '#87a2c7'},
  need_activation: { text: 'Oczekuje na aktywację', color: '#3865a3'},
  rejected: { text: 'Odrzucona', color: '#e23425'},
  active: { text:'Aktywna', color: '#64a338'},
  blocked: { text:'Zablokowana', color: '#e23425'}
}

/**
 * Order Status
 */

export const ORDER_STATUS = {
  waiting: { text:'Do opłacenia',color: '#000' },
  paid: {text: 'Do realizacji', color: '#87a2c7'},
  accepted: { text: 'W realizacji', color: '#3865a3' },
  ready: { text: 'Do odbioru', color: '#ffcc00' },
  received: { text:'Odebrane', color: '#64a338' },
  refunded: { text: 'Zwrot', color:'#CF2929'}
}
