import React from 'react'

// UI
import uiStyles from './uiStyles'
import { Typography } from '@material-ui/core'

type Props = {
  message?: string
}

const DataGridEmpty: React.FC<Props> = ({ message }) => {
  const classes = uiStyles()

  return <Typography align="center" variant="body2" className={classes.text}>{message || 'Brak pozycji'}</Typography>
}

export default DataGridEmpty