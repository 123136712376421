// GraphQL
import gql from "graphql-tag";

export const INDEX_PROMOTIONS_QUERY = gql`
  query {
    promotions {
      id
      name
      allUsesCount
      code
      value
      usesPerUser
      valueType
      availableUntil
      availableCouponsCount
      daysForUse
      description
      menuItem {
        id
        name
      }
      restaurant {
        id
        name
      }
      createdAt
    }
  }
`;

export const INDEX_RESTAURANTS_QUERY = gql`
  query {
    restaurants {
      id
      name
    }
  }
`;

export const INDEX_MENU_QUERY = gql`
  query restaurant($id: ID!) {
    restaurant(id: $id) {
      id
      name
      menuCategories {
        id
        name
        description
        public
        lockVersion
        imageSignedBlobId
        imageUrl
        menuItems {
          id
          name
          public
        }
      }
    }
  }
`;

export const CREATE_PROMOTION_MUTATION = gql`
  mutation(
    $restaurantId: ID
    $menuItemId: ID
    $name: String!
    $code: String
    $description: String
    $value: Int!
    $valueType: String!
    $usesPerUser: Int
    $availableCouponsCount: Int
    $availableUntil: ISO8601Date
    $daysForUse: Int
  ) {
    promotionCreate(
      input: {
        restaurantId: $restaurantId
        menuItemId: $menuItemId
        name: $name
        code: $code
        description: $description
        value: $value
        valueType: $valueType
        usesPerUser: $usesPerUser
        availableCouponsCount: $availableCouponsCount
        availableUntil: $availableUntil
        daysForUse: $daysForUse
      }
    ) {
      errors {
        field
        messages
      }
      promotion {
        id
      }
    }
  }
`;

export const UPDATE_PROMOTION_MUTATION = gql`
  mutation(
    $id: ID!
    $name: String
    $description: String
    $value: Int
    $valueType: String
    $usesPerUser: Int
    $availableCouponsCount: Int
    $availableUntil: ISO8601Date
    $daysForUse: Int
  ) {
    promotionUpdate(
      input: {
        id: $id
        name: $name
        description: $description
        value: $value
        valueType: $valueType
        usesPerUser: $usesPerUser
        availableCouponsCount: $availableCouponsCount
        availableUntil: $availableUntil
        daysForUse: $daysForUse
      }
    ) {
      errors {
        field
        messages
      }
      promotion {
        id
        name
        description
        value
        valueType
        usesPerUser
        availableCouponsCount
        availableUntil
        daysForUse
      }
    }
  }
`;

export const DESTROY_PROMOTION_MUTATION = gql`
  mutation($id: ID!) {
    promotionDestroy(input: { id: $id }) {
      errors {
        field
        messages
      }
    }
  }
`;
