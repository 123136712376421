import React from 'react'

// UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  Typography,
  Tooltip,
  Button
} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Link as LinkRouter } from 'react-router-dom'

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnBack: {
      minWidth: theme.spacing(4),
      maxWidth: theme.spacing(4),
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    leftSpace: {
      marginLeft: theme.spacing(4)
    }
  })
)

type Props = {
  url: string
  title?: string
  subtitle?: string
}

const TopBackButton: React.FC<Props> = (props) => {
  const classes = uiStyles()

  return (
    <>
      <Typography variant="h5" component="h3">
        <Tooltip title="Powrót">
          <Button disableRipple className={classes.btnBack} disableFocusRipple size="small" component={LinkRouter} to={props.url}>
            <ArrowBackIosIcon />
          </Button>
        </Tooltip>
        {props.title}
      </Typography>
      <Typography className={classes.leftSpace} variant="caption" component="p">
        {props.subtitle}
      </Typography>
    </>
  )
}

export default TopBackButton