import React from 'react'


// UI

import { Link as LinkRouter } from 'react-router-dom'
import { IconButton, TableRow, Link } from '@material-ui/core'
import { ActiveToggleChip } from '../../CustomChips/CustomChips'
import { prepareRouteUrl } from '../../../utils/utils';
import ROUTES from '../../../routes';
import { FormattedDate } from '../../FormattedData/FormattedData';


export const DataGridTipsGroupRow: React.FC<any> = (props) => {
  let colSpan = props.columns.filter(columnDef => !columnDef.hidden).length;
  props.options.selection && colSpan++;
  props.detailPanel && colSpan++;
  props.actions && props.actions.length > 0 && colSpan++;
  const column = props.groups[props.level];

  let detail;
  if (props.groupData.isExpanded) {
    if (props.groups.length > (props.level + 1)) { // Is there another group
      detail = props.groupData.groups.map((groupData, index) => (
        <props.components.GroupRow
          actions={props.actions}
          key={groupData.value || ("" + index)}
          columns={props.columns}
          components={props.components}
          detailPanel={props.detailPanel}
          getFieldValue={props.getFieldValue}
          groupData={groupData}
          groups={props.groups}
          icons={props.icons}
          level={props.level + 1}
          path={[...props.path, index]}
          onGroupExpandChanged={props.onGroupExpandChanged}
          onRowSelected={props.onRowSelected}
          onRowClick={props.onRowClick}
          onToggleDetailPanel={props.onToggleDetailPanel}
          onTreeExpandChanged={props.onTreeExpandChanged}
          onEditingCanceled={props.onEditingCanceled}
          onEditingApproved={props.onEditingApproved}
          options={props.options}
          hasAnyEditingRow={props.hasAnyEditingRow}
          isTreeData={props.isTreeData}
        />
      ));
    }
    else {
      detail = props.groupData.data.map((rowData, index) => {
        if (rowData.isGroupRow) {
          return true;
        } else {
          return (
            <props.components.Row
              actions={props.actions}
              key={index}
              columns={props.columns}
              components={props.components}
              data={rowData}
              detailPanel={props.detailPanel}
              getFieldValue={props.getFieldValue}
              icons={props.icons}
              path={[...props.path, index]}
              onRowSelected={props.onRowSelected}
              onRowClick={props.onRowClick}
              onToggleDetailPanel={props.onToggleDetailPanel}
              options={props.options}
              isTreeData={props.isTreeData}
              onTreeExpandChanged={props.onTreeExpandChanged}
              onEditingCanceled={props.onEditingCanceled}
              onEditingApproved={props.onEditingApproved}
              hasAnyEditingRow={props.hasAnyEditingRow}
            />
          );
        }
      });
    }
  }

  let value = props.groupData.value;
  if (column.lookup) {
    value = column.lookup[value];
  }

  let title = column.title;
  if (typeof title !== "string") {
    title = React.cloneElement(title);
  }

  let separator = props.options.groupRowSeparator || ": ";

  const rotateIconStyle = isOpen => ({
    transform: isOpen ? 'rotate(90deg)' : 'none'
  });

  const ShowStatus = (row) => (
    row.category && row.category.length > 0
      ?
      <ActiveToggleChip noMargin txtActive='Rozliczone' txtDeactive='Nierozliczone' status={row.categorySettled} />
      : null
  )

  return (
    <>
      <TableRow style={{ cursor: 'pointer' }}>
        <props.components.Cell
          colSpan={colSpan - 2}
          padding="none"
          columnDef={column}
          icons={props.icons}
        >
          <div
            onClickCapture={(event) => {
              event.stopPropagation();
              props.onGroupExpandChanged(props.path);
            }}
          >
            <IconButton
              style={{ transition: 'all ease 200ms', ...rotateIconStyle(props.groupData.isExpanded) }}
            >
              <props.icons.DetailPanel />
            </IconButton>
            {value ?
              <>
                <b>Rozliczenie{separator}</b>
                {props.groupData.data[0] && props.groupData.data[0].transfer && props.groupData.data[0].transfer.settlement && <Link component={LinkRouter} to={prepareRouteUrl(ROUTES.settlements.show.path, { id: props.groupData.data[0].transfer.settlement.id })}><FormattedDate format="DD.MM.YYYY HH:mm:ss" value={props.groupData.data[0].transfer.settlement.createdAt} /></Link>}</> : "Nierozliczone"}

          </div>
        </props.components.Cell>
        <props.components.Cell
          padding="none"
          columnDef={column}
          colSpan={2}
        >
          {ShowStatus(props.groupData.data[0])}
        </props.components.Cell>
        {props.groupRowActions && <props.components.Cell style={{ textAlign: 'right' }}>
          {props.groupRowActions(props.groupData.data[0])}
        </props.components.Cell>}
      </TableRow>
      {detail}
    </>
  )
}