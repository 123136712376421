import React from 'react'

// UI
import uiStyles from './uiStyles'
import {
  withStyles, WithStyles,
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { SuccessAlert, ErrorAlert } from '../FlashMessage/FlashMessage'
import { CustomDialogTitle } from '../Dialog/Dialog'

// GraphQL
import { Mutation } from 'react-apollo'

export interface DestroyProps {
  id: string
  name: string
  btnText?: string
  btnSmall?: boolean
  redirectUrl?: string
  type?: string
  onCompleted?: any
  additionalVariables?: any
}

interface Props extends DestroyProps, WithStyles<typeof uiStyles> {
  mutation: any
}

type State = {
  open: boolean
  confirm: boolean
}

class DestroyObject extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      open: false,
      confirm: false
    }
  }

  open = () => {
    this.setState({ open: true, confirm: false })
  }

  close = () => {
    this.setState({ open: false })
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirm: e.target.checked })
  }

  render() {
    const { id, name, btnText, btnSmall, mutation, additionalVariables, classes } = this.props
    const { confirm } = this.state

    const variables = { id, ...additionalVariables }

    return (
      <>
        {
          btnSmall === true &&
          <Tooltip title={btnText || 'Usuń'}>
            <IconButton aria-label="Delete" size="medium" onClick={this.open}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        }
        {
          btnSmall ||
          <Button className={classes.btn_delete} aria-label="Delete" variant="outlined" color="secondary" size="small" onClick={this.open}>
            <DeleteIcon fontSize="small" /> {btnText || 'Usuń'}
          </Button>
        }
        <Mutation
          mutation={mutation}
          variables={variables}
          onCompleted={(data: any) => this._confirm(data)}
        >
          {(submitForm: any, { loading, error, data }: any) => (
            <>
              <Dialog open={this.state.open} onClose={this.close} aria-labelledby="form-dialog-title">
                <CustomDialogTitle id="form-dialog-title" onClose={() => this.close()}>
                  Czy na pewno chcesz usunąć?
              </CustomDialogTitle>
                <form noValidate autoComplete="off" onSubmit={e => { e.preventDefault(); submitForm() }}>
                  <DialogContent>
                    <DialogContentText>
                      Wybrany element do usunięcia:
                    <Typography color="primary" component={'span'} display="block">{name}</Typography>
                    </DialogContentText>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch checked={confirm} onChange={this.handleChange} value="1" />
                        }
                        label="Potwierdzam usunięcie"
                      />
                    </FormGroup>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.close} size="small">
                      Anuluj
                  </Button>
                    {
                      !loading &&
                      <Button type="submit" variant="contained" color="secondary" disabled={!confirm}>
                        Usuń
                    </Button>
                    }
                    {
                      loading && <Button
                        type="button"
                        variant="contained"
                        disabled
                      >
                        <CircularProgress size={24} color="secondary" />
                      </Button>
                    }
                  </DialogActions>
                </form>
              </Dialog>
              {(!loading && data) && <SuccessAlert message="Usunięto pomyślnie." />}
              {error && <ErrorAlert error={error} />}
            </>
          )}
        </Mutation>
      </>
    )
  }

  _confirm = async (data: any) => {
    if (!data) return

    this.setState({
      open: false,
      confirm: false
    })

    if (this.props.redirectUrl && this.props.redirectUrl.length > 0)
      window.location.href = this.props.redirectUrl
    else
      this.props.onCompleted()
  }
}

export default withStyles(uiStyles)(DestroyObject)