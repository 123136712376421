import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

interface Props extends RouteComponentProps {
  match: any
}

const DevTool: React.FC<Props> = (props) => {
  const { action, value } = props.match.params

  if (action === 'endpointGraphql') {
    localStorage.setItem('_DEV_' + action, value)
  }

  return <>Done!</>
}

export default DevTool