import gql from "graphql-tag";

export const INDEX_USERS_QUERY = gql`
  query {
    users {
      id
      email
      firstName
      lastName
      admin
      activationState
      lastLoginAt
    }
  }
`;

export const SHOW_USER_QUERY = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      email
      activationState
      admin
      lastActivityAt
      lastLoginAt
      lastLoginFromIpAddress
      createdAt
      updatedAt
      firstName
      lastName
      phone
      phoneVerified
      recommendingUser {
        id
        firstName
        lastName
      }
      recommendedUsers {
        id
      }
      waiterProfile {
        id
        phone
        street
        postcode
        city
        birthDate
      }
      clientProfile {
        id
        street
        postcode
        city
        birthDate
      }
      restaurants {
        id
        name
        activated
        active
        blocked
        blockedReason {
          name
        }
        status
      }
    }
  }
`;

export const DESTROY_USER_MUTATION = gql`
  mutation($id: ID!) {
    userDestroy(input: { id: $id }) {
      errors {
        field
        messages
      }
    }
  }
`;

export const MARK_PHONE_VERIFIED_MUTATION = gql`
  mutation($id: ID!) {
    userMarkVerified(input: { id: $id }) {
      errors {
        field
        messages
      }
    }
  }
`;

export const USER_UPDATE_MUTATION = gql`
  mutation(
    $id: ID!
    $email: String!
    $admin: Boolean
    $firstName: String
    $lastName: String
  ) {
    userUpdate(
      input: {
        id: $id
        email: $email
        admin: $admin
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      errors {
        field
        messages
      }
    }
  }
`;
