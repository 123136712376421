import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { updateCurrentView, updateNavi } from '../../store/interface/actions'

interface Props {
  updateCurrentView: typeof updateCurrentView
  updateNavi: typeof updateNavi
}

const AppLocation = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return class AppLocationBase extends React.Component<P & Props, {}> {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}

const AppLocationConnected = compose(
  connect(null, { updateCurrentView, updateNavi }),
  AppLocation
)

export default AppLocationConnected