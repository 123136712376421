import React from 'react'

// UI
import uiStyles from '../../assets/styles/styles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Paper,
  Typography
} from '@material-ui/core'
import Loading from '../../components/Loading/Loading'
import { ErrorAlert } from '../../components/FlashMessage/FlashMessage'
import DataGridDictionaries from './DataGridDictionaries/DataGridDictionaries'
import DataGridEmpty from '../../components/DataGridEmpty/DataGridEmpty'

// GraphQL
import { Query } from 'react-apollo'
import { INDEX_DICTIONARIES_QUERY} from './repositoryGraphQL'

interface Props extends WithStyles<typeof uiStyles> {}

class IndexDictionaries extends React.Component<Props, {}> {

  render() {

    const { classes } = this.props

    return (
      <Query
        query={INDEX_DICTIONARIES_QUERY}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <ErrorAlert error={error} />

          const { dictionaries } = data

          return (
            <Paper className={classes.paper}>
              <Typography variant="h5" component="h3">
                Słowniki systemowe
              </Typography>
              <Typography variant="caption" component="p">
                {dictionaries.name}
              </Typography>
              {
                dictionaries.length > 0 ? <DataGridDictionaries rows={dictionaries} /> : <DataGridEmpty />
              }
            </Paper>
          )
        }}
      </Query>
    )
  }
}

export default withStyles(uiStyles)(IndexDictionaries)