// GraphQL
import gql from 'graphql-tag'


export const RESET_PASSWORD_MUTATION = gql`
  mutation($newPassword: String!, $token: String!){
    resetPasswordUpdate(input: { newPassword: $newPassword, token: $token }){
      errors {
        field
        messages
      }
    }
  }
`

export const RESET_PASSWORD_REQUEST_MUTATION = gql`
  mutation($email: String!){
    resetPasswordRequest(input: { email: $email }){
      message
      errors {
        field
        messages
      }
    }
  }
`