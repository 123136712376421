import React from "react";

// UI
import FormPromotion from "../FormPromotion/FormPromotion";

interface Props {
  row: any;
  onCompleted?: any;
  iconButton?: boolean;
}

const CreatePromotion: React.FC<Props> = (props) => {
  return (
    <FormPromotion
      iconButton={props.iconButton}
      row={props.row}
      onCompleted={props.onCompleted}
    />
  );
};

export default CreatePromotion;
