import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      padding: theme.spacing(3),
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.grey[700]
    }
  })
)

export default uiStyles