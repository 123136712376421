import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ROUTES from '../../../routes'
import { prepareRouteUrl } from '../../../utils/utils'

// UI
import uiStyles from '../../../assets/styles/styles'
import clsx from 'clsx'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Paper,
  Grid,
  Divider,
  Typography,
  Tooltip,
  Fab,
  Box,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import TopBackButton from '../../../components/TopBackButton/TopBackButton'
import { Link as LinkRouter } from 'react-router-dom'
import Loading from '../../../components/Loading/Loading'
import { ErrorAlert } from '../../../components/FlashMessage/FlashMessage'
import { EmailLink, WwwLink } from '../../../components/AppLinks/AppLinks'
import ActionsRestaurant from './ActionsRestaurant/ActionsRestaurant'
import { RestaurantStatusChip, CustomChip, ActiveToggleChip } from '../../../components/CustomChips/CustomChips'
import DataGridMenuItems from './DataGridMenuItems/DataGridMenuItems'
import DataGridEmpty from '../../../components/DataGridEmpty/DataGridEmpty'
import WeekSchedulerShow from '../../../components/WeekSchedulerShow/WeekSchedulerShow'
import { DisplayLabelValue } from '../../../components/DisplayInHtml/DisplayInHtml'


// GraphQL
import { Query } from 'react-apollo'
import { SHOW_RESTAURANT_QUERY } from '../repositoryGraphQL'
import { FormattedBankAccountNumber } from '../../../components/FormattedData/FormattedData'


interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  match: any
}

type State = {
  tabIndex: number
  anchorE: null | HTMLElement
}

type MenuItem = {
  id: string
  name: string
  categoryId: string
  categoryPublic: boolean
  category: string
  description: string
  allergens: Array<{}>
  calories: number
  price: string
  public?: boolean,
  imageUrl?: string
}

class ShowRestaurant extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      tabIndex: 0,
      anchorE: null
    }
  }

  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue
    })
  }

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorE: event.currentTarget
    })
  }

  render() {
    const { classes } = this.props
    const { id } = this.props.match.params
    const { tabIndex } = this.state

    return (
      <Query
        query={SHOW_RESTAURANT_QUERY}
        variables={{ id }}
        fetchPolicy="network-only">
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loading />
          if (error) return <ErrorAlert error={error} />

          const { restaurant } = data
          const editUrl = prepareRouteUrl(ROUTES.restaurants.edit.path, { id: restaurant.id })
          const backUrl = ROUTES.restaurants.index.path

          let itemsMenu = [] as any

          return (
            <>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Grid item xs={6}>
                      <TopBackButton
                        url={backUrl}
                        title={`${restaurant.name}`}
                        subtitle="Zarządzanie restauracją"
                      />
                    </Grid>
                    {!restaurant.blocked &&
                      <Tooltip title="Edycja">
                        <Fab aria-label="Edit" size="medium" className={classes.fab_edit} color="secondary" component={LinkRouter} to={editUrl}>
                          <EditIcon />
                        </Fab>
                      </Tooltip>
                    }
                  </Grid>
                  <Grid item sm={4}>
                    <DisplayLabelValue label="" value={<img alt="" src={restaurant.mainImageUrl} className={classes.imgLogo} />} />
                  </Grid>
                  <Grid item sm={4}>
                  <DisplayLabelValue label="Adres" value={
                    <span>
                    {  restaurant.street &&
                        `ul. ${restaurant.street}`
                    }
                    <br />
                     {
                        restaurant.postcode && restaurant.city &&
                        `${restaurant.postcode} ${restaurant.city}`
                      }
                    </span>
                    } />
                  </Grid>
                  <Grid item sm={4}>
                  <DisplayLabelValue label="Status" value={
                    <>
                      {
                        <RestaurantStatusChip noMargin restaurantStatus={restaurant.status} />
                      }
                      {
                        restaurant.status === 'need_activation' &&
                        <CustomChip txtActive={"Wymaga aktywacji"} />
                      }
                      {
                        restaurant.blocked &&
                        <CustomChip txtActive={`Zablokowana: ${restaurant.blockedReason.name}`} />
                      }
                    </>
                  } />
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    <DisplayLabelValue label="Osoba kontaktowa" value={restaurant.contactFullName || "Brak"}/>
                  </Grid>
                  <Grid item sm={4}>
                    <DisplayLabelValue label="Telefon kontaktowy" value={restaurant.contactPhone || "Brak"}/>
                  </Grid>
                  <Grid item sm={4}>
                    <DisplayLabelValue label="E-mail kontaktowy" value={restaurant.contactEmail ? (<EmailLink value={restaurant.contactEmail} />) : "Brak"} /> 
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Box className={classes.actions_links}>
                      {
                        !restaurant.activated &&
                        <ActionsRestaurant
                          id={restaurant.id}
                          name={restaurant.name}
                          onCompleted={refetch}
                          type="restaurantActivate"
                        />
                      }
                      {
                        !restaurant.blocked ?
                          <ActionsRestaurant
                            id={restaurant.id}
                            name={restaurant.name}
                            onCompleted={refetch}
                            type="restaurantBlock"
                          />
                          :
                          <ActionsRestaurant
                            id={restaurant.id}
                            name={restaurant.name}
                            onCompleted={refetch}
                            type="restaurantUnblock"
                          />
                      }
                      <ActionsRestaurant
                        id={restaurant.id}
                        name={restaurant.name}
                        onCompleted={()=>this.props.history.push(backUrl)}
                        type="restaurantDestroy"
                      />
                      <ActionsRestaurant
                        id={restaurant.id}
                        name={restaurant.name}
                        onCompleted={refetch}
                        type="restaurantLoginAdmin"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
              <AppBar position="static" color="default" className={classes.menuTabs}>
                <Tabs
                  value={tabIndex}
                  onChange={this.handleTabChange}
                  variant="fullWidth"
                  indicatorColor="secondary"
                >
                  <Tab label="Dane firmowe" />
                  <Tab label="Menu" />
                  <Tab label="Godziny otwarcia" />
                </Tabs>
              </AppBar>
              <Paper className={classes.tabs}>
                {/* Dane firmowe */}
                <Box component="div" className={clsx(classes.tab, tabIndex === 0 && classes.tabShow)} padding={4}>
                  <Grid container>
                    <Grid item sm={6}>
                      <DisplayLabelValue label="Telefon" value={restaurant.phone || "Brak"} /> 
                    </Grid>
                    <Grid item sm={6}>
                      <DisplayLabelValue label="E-mail (oficjalny, publiczny email)" value={restaurant.email ? <EmailLink value={restaurant.email} /> : "Brak"} /> 
                    </Grid>
                    <Grid item sm={6}>
                      <DisplayLabelValue label=" E-mail do rozliczeń i faktur" value={restaurant.fvEmail ? <EmailLink value={restaurant.fvEmail} /> : "Brak"} /> 
                    </Grid>
                    <Grid item sm={6}>
                      <DisplayLabelValue label="NIP" value={restaurant.nip || "Brak"} /> 
                    </Grid>
                    <Grid item sm={6}>
                      <DisplayLabelValue label="WWW" value={restaurant.www ? <WwwLink value={restaurant.www} /> : "Brak"} /> 
                    </Grid>
                    <Grid item sm={6}>
                    <DisplayLabelValue label="Numer rachunku bankowego" value={<FormattedBankAccountNumber value={restaurant.accountNumber} countryCode={restaurant.accountCountryCode} />} /> 
                    </Grid>
                    <Grid item sm={6}>
                    <DisplayLabelValue label="Umożliwia wybór stolika?" value={<ActiveToggleChip noMargin txtActive="Tak" txtDeactive="Nie" status={restaurant.tableDeliveryPossible} />} /> 
                    </Grid>
                  </Grid>
                </Box>
                {/* Dane bankowe */}
                <Box component="div" className={clsx(classes.tab, tabIndex === 1 && classes.tabShow)} padding={4}>
                  <Grid container>
                    <Typography variant="h5" component="h3">
                      Menu
                      </Typography>
                    <Grid item sm={12}>
                      {
                        restaurant.menuCategories.filter(item => item.public).forEach((itemCategory) => {

                          if (itemCategory && itemCategory.menuItems.length > 0) {
                            itemCategory.menuItems.filter(item => item.public).forEach((itemMenu, indexItem) => {
                              let menuItem: MenuItem = {
                                id: itemMenu.id,
                                name: itemMenu.name,
                                categoryId: itemCategory.id,
                                categoryPublic: itemCategory.public,
                                category: itemCategory.name,
                                description: itemMenu.description,
                                allergens: itemMenu.allergens,
                                calories: itemMenu.calories,
                                price: itemMenu.price,
                                public: itemMenu.public,
                                imageUrl: itemMenu.imageUrl
                              }

                              itemsMenu.push(menuItem)

                            })
                          }
                        })
                      }
                      {
                        itemsMenu.length > 0 ? <DataGridMenuItems rows={itemsMenu} /> : <DataGridEmpty />
                      }
                    </Grid>
                  </Grid>
                </Box>
                <Box component="div" className={clsx(classes.tab, tabIndex === 2 && classes.tabShow)} padding={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      {
                        restaurant.schedule ? <WeekSchedulerShow times={restaurant.schedule} /> : <DataGridEmpty message="Brak podanych godzin otwarcia" />
                      }
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </>
          )
        }}
      </Query>
    )
  }
}

export default withStyles(uiStyles)(ShowRestaurant)
