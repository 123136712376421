/**
 * Formatters
 */

export const DateFormatter = (value: string | null): string => value ? value.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1') : '-'

export const PostCodeFormatter = (value: string | null): string => value ? value.replace(/([0-9])(\d)(\d)(\d)(\d)/, "$1$2-$3$4$5") : ''

export const amountFormat = (number: number) => new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(number)

export const BankAccountNumberFormatter = (value: number): string => {
  let result = ""

  if (value) {
    Array.from(value.toString()).forEach((number, index) => {
      result += number;
      if ((index - 3) % 4 === 0) result += " ";
    });  
  }
  
  return result;
}