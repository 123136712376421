import React from "react";
import { Link as LinkRouter } from "react-router-dom";
import ROUTES from "../../../routes";
import { prepareRouteUrl } from "../../../utils/utils";

import { Link, Chip } from "@material-ui/core";
import { DataGridTable } from "../../../components/DataGridTable/DataGridTable";
import { FormattedDate } from "../../../components/FormattedData/FormattedData";
import DeletePromotion from "../DeletePromotion/DeletePromotion";
import EditPromotion from "../EditPromotion/EditPromotion";

interface Props {
  updateDataGrid?: any;
  rows: Array<{}>;
}

interface State {
  rows: any;
  columns: Array<{
    field: string;
    title: string;
    render?: any;
    cellStyle?: any;
    headerStyle?: any;
    customSort?: any;
  }>;
}

class DataGridPromotions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      columns: [
        { field: "name", title: "Nazwa" },
        { field: "code", title: "Kod" },
        { field: "allUsesCount", title: "Liczba użyć" },
        { field: "value", title: "Wartość" },
        {
          field: "valueType",
          title: "Typ",
          render: (row) => (
            <Chip
              label={row.valueType === "constant" ? "Stała" : "Procentowa"}
            />
          ),
        },
        {
          field: "restaurant.name",
          title: "Restauracja",
          headerStyle: { minWidth: "20%" },
          render: (row) => (row.restaurant ? this.toLink(row) : <>Brak</>),
          customSort: (a, b) => a.restaurant.name > b.restaurant.name,
        },
        {
          field: "createdAt",
          title: "Data utworzenia",
          headerStyle: { minWidth: "20%" },
          render: (row) => (
            <FormattedDate format="DD.MM.YYYY HH:mm:ss" value={row.createdAt} />
          ),
        },
        {
          field: "actions",
          title: "Akcje",
          headerStyle: { minWidth: "25%", textAlign: "right" },
          cellStyle: { textAlign: "right" },
          render: (row) => this.ActionsLinks(row),
        },
      ],
      rows: this.props.rows,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return props.rows !== state.rows ? { rows: props.rows } : null;
  }

  toLink = (row) => {
    const showUrl: string = prepareRouteUrl(ROUTES.reports.show.path, {
      id: row.id,
    });

    return (
      <Link aria-label="Show" component={LinkRouter} to={showUrl}>
        {row.restaurant.name}
      </Link>
    );
  };

  ActionsLinks = (row) => {
    let actions: Array<any> = [];

    actions.push(
      <EditPromotion
        key={`EditId-${row.id}`}
        row={row}
        edit
        onCompleted={this.props.updateDataGrid}
      />
    );

    actions.push(
      <DeletePromotion
        key={`DeleteId-${row.id}`}
        promotionId={row.id}
        name={row.name}
        onCompleted={this.props.updateDataGrid}
      />
    );

    return actions;
  };

  render() {
    const { rows, columns } = this.state;

    return <DataGridTable columns={columns} rows={rows} />;
  }
}

export default DataGridPromotions;
