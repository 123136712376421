import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Auth from '../../../utils/Auth/Auth'
import ROUTES from '../../../routes'

// UI
import uiStyles from '../../../assets/styles/styles'
import {
  Paper,
  Grid,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  withStyles,
  WithStyles
} from '@material-ui/core'
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { ErrorAlert, SuccessAlert } from '../../../components/FlashMessage/FlashMessage'
import AppLocation from '../../../components/AppLocation/AppLocation'
import { passwordRegexp, passwordErrorMsg } from '../../../utils/validators'
import { SubmitActionBtn, LoaderBtn } from '../../../components/FormActionLinks/FormActionLinks'

// GraphQL
import { Mutation } from 'react-apollo'
import { USER_PASSWORD_UPDATE_MUTATION } from '../repositoryGraphQL'



interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  updateCurrentView: any
}

type State = {
  id: string | null,
  oldPassword: string,
  newPassword: string,
  confirmPassword: string,
  showPassword: boolean
}

class ChangePassword extends React.Component<Props, State>{
  constructor(props: Props) {
    super(props)

    this.state = {
      id: '',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      showPassword: false
    }
  }

  componentDidMount() {
    this.props.updateCurrentView({
      name: 'Moje konto'
    })

    this.setState({
      id: Auth.id()
    })

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.newPassword) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isPasswordMatch');
  }

  handleClickShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  render() {
    const { classes } = this.props
    const { id, oldPassword, newPassword, confirmPassword, showPassword } = this.state

    return (
      <Mutation
        mutation={USER_PASSWORD_UPDATE_MUTATION}
        variables={{ id, oldPassword, newPassword, confirmPassword }}
        onCompleted={(data: any) => this._confirm(data)}
      >
        {(submitForm: any, { loading, data, error }: any) => {
          return (
            <>
              <ValidatorForm
                ref="form"
                onSubmit={submitForm}
              >
                <Paper className={classes.paper}>
                  <Container component="main">
                    <Typography component="h1" variant="h6">
                      Zmiana hasła
                    </Typography>
                    <Grid className={classes.bottomSpace} container spacing={2}>
                      <Grid item sm={6}>
                        <TextValidator
                          id="field-password"
                          className={classes.textField}
                          type={showPassword ? 'text' : 'password'}
                          label="Hasło"
                          value={this.state.oldPassword}
                          onChange={(e: { target: { value: string; }; }) => this.setState({ oldPassword: e.target.value })}
                          required
                          validators={['required', passwordRegexp]}
                          errorMessages={['Pole wymagane.', passwordErrorMsg]}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item sm={6}></Grid>
                      <Grid item sm={6}>
                        <TextValidator
                          id="field-new-password"
                          className={classes.textField}
                          type={showPassword ? 'text' : 'password'}
                          label="Nowe hasło"
                          value={this.state.newPassword}
                          onChange={(e: { target: { value: string; }; }) => this.setState({ newPassword: e.target.value })}
                          required
                          validators={['required', passwordRegexp]}
                          errorMessages={['Pole wymagane.', passwordErrorMsg]}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item sm={6}></Grid>
                      <Grid item sm={6}>
                        <TextValidator
                          id="field-confirm-new-password"
                          className={classes.textField}
                          type={showPassword ? 'text' : 'password'}
                          label="Potwierdź nowe hasło"
                          value={this.state.confirmPassword}
                          onChange={(e: { target: { value: string; }; }) => this.setState({ confirmPassword: e.target.value })}
                          required
                          validators={['isPasswordMatch', 'required']}
                          errorMessages={['Niezgodność haseł', 'Pole wymagane.']}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                    {!loading && <SubmitActionBtn text="Zapisz" />}
                    {loading && <LoaderBtn />}
                  </Container>
                </Paper>
              </ValidatorForm>
              {(!loading && data) && <SuccessAlert />}
              {error && <ErrorAlert error={error} />}
              {(data && data.userPasswordUpdate.errors) && <ErrorAlert modelName="changePassword" fieldError={data.userPasswordUpdate.errors} />}
            </>
          )
        }
        }
      </Mutation>
    )
  }

  _confirm = async (data: any) => {
    if (data) {
      this.setState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      })
    }
    this.props.history.push(ROUTES.home.path)
  }
}

export default AppLocation(withStyles(uiStyles)(ChangePassword))