import React from 'react'

import { DataGridTable } from '../../../../components/DataGridTable/DataGridTable'
import { FormattedDate, FormattedPrice } from '../../../../components/FormattedData/FormattedData'
import { OrderStatusChip } from '../../../../components/CustomChips/CustomChips'

interface Props {
  rows: Array<{}>
  search?: boolean
  edit?: boolean
  onChanged?: any
  status?: any
}

interface State {
  rows: any
  columns: Array<{
    field: string
    title: string
    render?: any,
    cellStyle?: any,
    headerStyle?: any
  }>
}

class DataGridOrders extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      columns: [
        { field: 'uniqueNumber', title: 'Zamówienie nr', headerStyle: { minWidth: '20%' } },
        { field: 'status', title: 'Status', headerStyle: { minWidth: '20%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: row => this.OrderStatus(row.status) },
        { field: 'total', title: 'Kwota', headerStyle: { minWidth: '35%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: (row) => <FormattedPrice value={row.total} />},
        { field: 'createdAt', title: 'Data utworzenia', headerStyle: { minWidth: '35%' }, render: row => this.FormatData(row.createdAt) }
      ],
      rows: this.props.rows
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return (props.rows !== state.rows) ? { rows: props.rows } : null
  }

  OrderStatus = (status) => <OrderStatusChip noMargin orderStatus={status} />

  FormatData = (row) => <FormattedDate format="DD.MM.YYYY HH:MM" value={row} />

  render() {

    const {
      rows, columns
    } = this.state

    return (

      <DataGridTable search={this.props.search} columns={columns} rows={rows} />

    )
  }
}

export default DataGridOrders