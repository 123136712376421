import React from 'react'

// UI
import uiStyles from './uiStyles'
import clsx from 'clsx'
import {
  CssBaseline,
  Container
} from '@material-ui/core'

import Sidebar from '../../components/Sidebar/Sidebar'
import Header from '../../components/Header/Header'

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

const AppLayout: React.FC<Props> = ({ children }) => {
  const classes = uiStyles()
  const [open, setOpen] = React.useState(localStorage.getItem('sidebarOpen') === 'false' ? false : true)

  function handleSideBar() {
    const newValue = !open
    setOpen(newValue)

    localStorage.setItem('sidebarOpen', newValue.toString())
  }

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <Header handleSideBar={handleSideBar} stateSidebar={open} />
        <Sidebar stateSidebar={open} />
        <main className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth={false} className={classes.container}>
            {children}
          </Container>
        </main>
      </div>
    </>
  )
}

export default AppLayout