// GraphQL
import gql from 'graphql-tag'

/**
 * Viewer
 */

export const VIEWER_QUERY = gql`
  query{
    viewer{
      id
      email
      createdAt
      firstName
      lastName
      restaurant {
        name
      }
    }
  }
`

/**
 * Dictionaries
 */

export const DICTIONARIES_QUERY = gql`
  query{
    dictionaries{
      id
      name
      sysName
      values {
        id
        name
      }
    }
  }
`