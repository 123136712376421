import React, { useState } from 'react'

import {
  Paper,
  MenuItem
} from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'

interface Props {
  addressDetails: any 
  address: any
  lat: any
  lng: any
  current: string
}

export const GooglePlaceAutocomplite: React.FC<Props> = (props) => {

  const [address, setAddress] = useState(props.current)

  const handlePlaceChange = address => {
    setAddress(address)
  }

  const handlePlaceSelect = address => {
    setAddress(address)
    geocodeByAddress(address)
      .then(results => { 
        props.addressDetails(results[0].address_components)
        return getLatLng(results[0])}
        )
      .then(latLng => {
        props.lat(latLng.lat)
        props.lng(latLng.lng)
        return
      })
      props.address(address)
  }

  const searchOptions = {
    componentRestrictions: { country: 'pl' }
  }

  return (
    <PlacesAutocomplete
    value={address}
    onChange={handlePlaceChange}
    onSelect={handlePlaceSelect}
    searchOptions={searchOptions}
  >
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <div>
        <TextValidator
          required
          fullWidth
          tab={0}
          values={address}
          label="Lokalizacja"
          validators={['required']}
          errorMessages={['Pole wymagane.']}
          {...getInputProps({
            placeholder: 'Wyszukaj lokalizację ...',
            className: 'location-search-input',
          })}

        />
        <div className="autocomplete-dropdown-container">
          {loading && <div>Loading...</div>}
          {suggestions.map(suggestion => {
            return (
              <Paper square key={suggestion.index}>
              <MenuItem
              {...getSuggestionItemProps(suggestion)}
              component="div"
            >
                <span>{suggestion.description}</span>
                
            </MenuItem>
            </Paper>
            )
          })}
        </div>
      </div>
    )}
  </PlacesAutocomplete>
  )
}
