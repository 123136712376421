import React from 'react'

// UI
import uiStyles from '../../../assets/styles/styles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
  Tooltip,
  Button,
  Grid
} from '@material-ui/core'
import { ValidatorForm } from 'react-material-ui-form-validator'
import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker'
import SelectCheckbox from '../../../components/SelectCheckbox/SelectCheckbox'
import Loading from '../../../components/Loading/Loading'
import { ErrorAlert, SuccessAlert } from '../../../components/FlashMessage/FlashMessage'
import { SubmitActionBtn, LoaderBtn, ClosePopupBtn } from '../../../components/FormActionLinks/FormActionLinks'
import { CustomDialogTitle } from '../../../components/Dialog/Dialog'

// GraphQL
import { Mutation, Query } from 'react-apollo'
import { CREATE_REPORT_QUERY, INDEX_RESTAURANTS_QUERY } from '../repositoryGraphQL'


interface Props extends WithStyles<typeof uiStyles> {
  onCompleted?: any
}

type State = {
  fromDate: Date | null
  toDate: Date | null
  restaurantIds: Array<any>
  open: boolean
}

class CreateReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      fromDate: null,
      toDate: new Date(),
      restaurantIds: [],
      open: false,
    }
  }

  handleChange = (value): void => this.setState({ restaurantIds: value })

  open = (): void => this.setState({ open: true })

  close = (): void => this.setState({ open: false })

  render() {
    const { classes } = this.props
    const { fromDate, toDate, restaurantIds, open } = this.state

    return (
      <>
        <Tooltip title="Utwórz raport">
          <Button aria-label="Settlement" variant="outlined" size="small" onClick={this.open}> Wygeneruj raport </Button>
        </Tooltip>
        <Query
          query={INDEX_RESTAURANTS_QUERY}
          fetchPolicy="network-only"
        >
          {({ loading, error, data: dataRestaurants }) => {
            if (loading) return <Loading />
            if (error) return <ErrorAlert error={error} />

            return (
              <Mutation
                mutation={CREATE_REPORT_QUERY}
                variables={{ fromDate, toDate, restaurantIds }}
                onCompleted={(data: any) => this._confirm(data)}
              >
                {(submitForm: any, { loading, error, data }: any) => {

                  return (
                    <>
                      {
                        open &&
                        <Dialog fullWidth maxWidth="md" open={this.state.open} onClose={this.close} aria-labelledby="form-dialog-title">
                          <CustomDialogTitle id="form-dialog-title" onClose={this.close}>
                            Wygeneruj raport
                          </CustomDialogTitle>
                          <ValidatorForm
                            ref="form"
                            onSubmit={submitForm}
                          >
                            <DialogContent style={{minHeight: 120}}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <SelectCheckbox required onChange={(value) => this.handleChange(value)} data={dataRestaurants.restaurants} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <FormGroup row>
                                    <FormDatePicker
                                      label="Od"
                                      name="fromDate"
                                      value={fromDate}
                                      className={classes.formControl}
                                      onChange={(date: any) => this.setState({ fromDate: date })}
                                    />
                                  </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  <FormGroup row>
                                    <FormDatePicker
                                      required
                                      label="Do"
                                      name="toDate"
                                      maxDate={new Date()}
                                      value={toDate}
                                      className={classes.formControl}
                                      onChange={(date: any) => this.setState({ toDate: date })}
                                    />
                                  </FormGroup>
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              {!loading && <ClosePopupBtn onClick={this.close} text="Anuluj" />}
                              {!loading && <SubmitActionBtn disabled={restaurantIds.length > 0 ? false : true} text="Generuj" />}
                              {loading && <LoaderBtn />}
                            </DialogActions>
                          </ValidatorForm>
                        </Dialog>
                      }

                      {!loading && data &&
                        (
                          data.reportsCreate.reports.length > 0 ?
                            <SuccessAlert message="Utworzono pomyślnie." />
                            :
                            <ErrorAlert message="Dla wybranych parametrów nie został wygenerowany żaden raport." />
                        )
                      }
                      {error && <ErrorAlert error={error} />}
                      {(data && data.reportsCreate) && <ErrorAlert modelName="report" fieldError={data.reportsCreate.errors} />}
                    </>
                  )
                }}
              </Mutation>
            )
          }}
        </Query>
      </>
    )
  }


  _confirm = async (data: any) => {
    if (!data) return

    this.setState({ open: false })

    this.props.onCompleted()
  }
}

export default withStyles(uiStyles)(CreateReport)