import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import ROUTES from '../../routes'
import Auth from '../../utils/Auth/Auth'

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        Auth.check() ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: ROUTES.auth.login.path,
                state: { from: props.location }
              }}
            />
          )
      }
    />
  )
}

export default PrivateRoute