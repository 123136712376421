// GraphQL
import gql from 'graphql-tag'

export const INDEX_CLIENTS_QUERY = gql`
  query{
    clients{
      id
      email
      activationState
      lastActivityAt
      firstName
      lastName
      clientProfile {
        id
      }
    }
  }
`

export const SHOW_CLIENT_QUERY = gql`
  query client($id : ID!){
    client(id: $id){
      id
      email
      phone
      phoneVerified
      activationState
      lastActivityAt
      lastLoginFromIpAddress
      lastName
      firstName
      discountCodes {
        id
        name
        description
        expirationDate
        used
        value
      }
      clientProfile {
        id
        birthDate
        city
        id
        postcode
        street
        createdAt
        ofAge
      }
    }
  }
`
export const CLIENT_UPDATE_MUTATION = gql`
  mutation($client_id: ID!, $street: String, $city: String, $postcode: String, $birthDate: ISO8601Date){
    clientUpdate(input:{id: $client_id, street: $street, city: $city, postcode: $postcode, birthDate: $birthDate }){
      errors {
        field
        messages
      }
    }
  }
`