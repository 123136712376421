import { AuthUserState, AuthUserActionTypes, SET_AUTH_USER } from './types'

const initialState: AuthUserState = {
  clientProfile: {
    id: '',
    createdAt: ''
  },
  firstName: '',
  lastName: '',
  email: ''
}

export function authUserReducer(
  state = initialState,
  action: AuthUserActionTypes
): AuthUserState {
  switch (action.type) {
    case SET_AUTH_USER: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state
  }
}