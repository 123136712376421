import React from "react";

// UI
import uiStyles from "../../assets/styles/styles";
import { withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Paper, Typography, Badge } from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
import { ErrorAlert } from "../../components/FlashMessage/FlashMessage";
import AppLocation from "../../components/AppLocation/AppLocation";
import DataGridEmpty from "../../components/DataGridEmpty/DataGridEmpty";

// GraphQL
import { Query } from "react-apollo";
import { INDEX_FEEDBACK_QUERY } from "./repositoryGraphQL";
import DataGridFeedback from "./DataGridFeedback/DataGridFeedback";

const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    top: theme.spacing(2),
    right: theme.spacing(-2),
  },
}))(Badge);

interface Props extends WithStyles<typeof uiStyles> {
  updateCurrentView: any;
}

class IndexFeedback extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.updateCurrentView({
      name: "Feedback",
    });
  }

  render() {
    const { classes } = this.props;
    const activeOnly = false;

    return (
      <Query
        query={INDEX_FEEDBACK_QUERY}
        variables={{ activeOnly }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }: any) => {
          if (loading) return <Loading />;
          if (error) return <ErrorAlert error={error} />;

          const { feedbacks } = data || {};

          return (
            <Paper className={classes.paper}>
              <StyledBadge
                badgeContent={feedbacks ? Object.keys(feedbacks).length : 0}
                color="primary"
              >
                <Typography variant="h5" component="h3">
                  Feedback
                </Typography>
              </StyledBadge>
              <Typography variant="caption" component="p">
                Recenzje użytkowników
              </Typography>
              <Typography
                className={classes.marginTop}
                variant="body2"
                component="div"
              >
                {feedbacks && feedbacks.length > 0 ? (
                  <DataGridFeedback rows={feedbacks} />
                ) : (
                  <DataGridEmpty />
                )}
              </Typography>
            </Paper>
          );
        }}
      </Query>
    );
  }
}

export default AppLocation(withStyles(uiStyles)(IndexFeedback));
