import React from "react";
import { Route, Switch } from "react-router-dom";
import ROUTES from "../../routes";
import { objectToArray } from "../../utils/utils";

// UI
import "../../assets/styles/styles.scss";
import AppDataFetch from "../../components/AppDataFetch/AppDataFetch";
import AppLayout from "../../components/AppLayout/AppLayout";
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute";
import FlashMessage from "../../components/FlashMessage/FlashMessage";

import DevTool from "../DevTool/DevTool";

const App: React.FC = () => {
  return (
    <>
      <Switch>
        {/* <Route exact path={ROUTES.auth.register.path} component={ROUTES.auth.register.component} /> */}
        <Route
          exact
          path={ROUTES.auth.login.path}
          component={ROUTES.auth.login.component}
        />

        <Route
          exact
          path={ROUTES.resetPassword.request.path}
          component={ROUTES.resetPassword.request.component}
        />
        <Route
          exact
          path={ROUTES.resetPassword.index.path}
          component={ROUTES.resetPassword.index.component}
        />

        <Route
          exact
          path={ROUTES.user.activate.path}
          component={ROUTES.user.activate.component}
        />

        <Route exact path={"/DevTool/:action/:value"} component={DevTool} />

        <AppDataFetch>
          <AppLayout>
            <Switch>
              <PrivateRoute
                exact={ROUTES.home.exact}
                path={ROUTES.home.path}
                component={ROUTES.home.component}
              />

              {/* Profile */}
              {objectToArray(ROUTES.profile).map(
                (item: any, index: number): JSX.Element => (
                  <PrivateRoute key={index} {...item} />
                )
              )}

              {/* Users*/}
              {objectToArray(ROUTES.users).map(
                (item: any, index: number): JSX.Element => (
                  <PrivateRoute key={index} {...item} />
                )
              )}

              {/* Restaurants */}
              {objectToArray(ROUTES.restaurants).map(
                (item: any, index: number): JSX.Element => (
                  <PrivateRoute key={index} {...item} />
                )
              )}

              {/* Waiters*/}
              {objectToArray(ROUTES.waiters).map(
                (item: any, index: number): JSX.Element => (
                  <PrivateRoute key={index} {...item} />
                )
              )}

              {/* Clients */}
              {objectToArray(ROUTES.clients).map(
                (item: any, index: number): JSX.Element => (
                  <PrivateRoute key={index} {...item} />
                )
              )}

              {/* Dictionaries */}
              {objectToArray(ROUTES.dictionaries).map(
                (item: any, index: number): JSX.Element => (
                  <PrivateRoute key={index} {...item} />
                )
              )}

              {/* Reports */}
              {objectToArray(ROUTES.reports).map(
                (item: any, index: number): JSX.Element => (
                  <PrivateRoute key={index} {...item} />
                )
              )}

              {/* Tips */}
              {objectToArray(ROUTES.tips).map(
                (item: any, index: number): JSX.Element => (
                  <PrivateRoute key={index} {...item} />
                )
              )}

              {/* Promotions */}
              {objectToArray(ROUTES.promotions).map(
                (item: any, index: number): JSX.Element => (
                  <PrivateRoute key={index} {...item} />
                )
              )}

              {/* Feedback */}
              {objectToArray(ROUTES.feedback).map(
                (item: any, index: number): JSX.Element => (
                  <PrivateRoute key={index} {...item} />
                )
              )}
            </Switch>

            {/* Settlements  */}
            {objectToArray(ROUTES.settlements).map(
              (item: any, index: number): JSX.Element => (
                <PrivateRoute key={index} {...item} />
              )
            )}
          </AppLayout>
        </AppDataFetch>
      </Switch>

      <FlashMessage />
    </>
  );
};

export default App;
