// GraphQL
import gql from 'graphql-tag'

/**
 * Index
 */

export const INDEX_DICTIONARIES_QUERY = gql`
  query{
    dictionaries{
      id
      name
      sysName
      type
    }
  }
`
/**
 *  Index Values 
 */

export const INDEX_VALUES_QUERY = gql`
  query dictionary($sysName: String!) {
    dictionary(sysName: $sysName) {
      id
      name
      sysName
      type
      values {
        id
        name
        lockVersion
        editable
        destroyable 
      }
    }
  }
`

/**
 * Create 
 */

export const CREATE_VALUE_MUTATION = gql`
mutation($id: ID!, $name: String! ) {
  dictionaryValueCreate(input: { dictionaryId : $id, name: $name }) {
    errors {
      field
      messages
    }
  }
}
`

 /**
  * Edit
  */

export const EDIT_VALUE_MUTATION = gql`
  mutation($id: ID!, $name: String!, $lockVersion: Int!) {
    dictionaryValueUpdate(input: { id: $id, name: $name, lockVersion: $lockVersion }) {
      errors {
        field
        messages
      }
      value {
        lockVersion
      }
    }
  }
`

/**
 * Destroy 
 */

export const DESTROY_VALUE_MUTATION = gql`
  mutation($id: ID!) {
    dictionaryValueDestroy(input: { id: $id }) {
      errors {
        field
        messages
      }
    }
  }
`
