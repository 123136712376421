import React from 'react'
import ROUTES from '../../../routes'
import { Link as LinkRouter } from 'react-router-dom'
import { prepareRouteUrl } from '../../../utils/utils'

import { Tooltip, IconButton, Chip, Link } from '@material-ui/core'
import { RemoveRedEye, Edit as EditIcon } from '@material-ui/icons'
import { DataGridTable } from '../../../components/DataGridTable/DataGridTable'
import { CustomChip, ActiveToggleChip } from '../../../components/CustomChips/CustomChips'
import { FormattedDate } from '../../../components/FormattedData/FormattedData'


interface Props {
  rows: Array<{}>
}

interface State {
  rows: any
  columns: Array<{
    field: string
    title: string
    render?: any
    sorting?: boolean
    cellStyle?: any
    headerStyle?: any
    customSort?: any
  }>
}

class DataGridClients extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      columns: [
        { field: 'fullName', title: 'Nazwa', headerStyle: { minWidth: '40%' }, render: row => this.FullName(row), customSort: (a, b) => a.email > b.email },
        { field: 'status', title: 'Status', headerStyle: { minWidth: '20%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: row => this.UserStatus(row), customSort: (a,b) => this.StatusSort(a,b) },
        { field: 'lastActivityAt', title: 'Ostatnia aktywność', headerStyle: { minWidth: '25%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: row => <FormattedDate value={row.lastActivityAt} />},
        { field: 'actions', title: 'Akcje', headerStyle: { minWidth: '20%', textAlign: 'right' }, cellStyle: { textAlign: 'right' }, sorting: false, render: row => this.ActionsLinks(row) }
      ],
      rows: this.props.rows
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return (props.rows !== state.rows) ? { rows: props.rows } : null
  }

  FullName = (row) => {
    const showUrl: string = prepareRouteUrl(ROUTES.clients.show.path, { id: row.id })

    if(!row) return '-'
    const { firstName, lastName, email, pseudonym } = row
    const fullName = firstName && lastName ? `${firstName} ${lastName}` : pseudonym
    
    return (<Link aria-label="Show" component={LinkRouter} to={showUrl}>{fullName}<br />{email}</Link>) 
  }

  UserStatus = (row) =>  <ActiveToggleChip noMargin status={Object.is(row.activationState, 'active') ? true : false } />

  StatusSort = (a, b) => a.activationStatet > b.activationState

  UserRole = (row) => (
    row.admin ?
      <Chip style={{ backgroundColor: '#e23425' }} size="small" label="Administrator" color="primary" />
      :
      ' '
  )

  ActionsLinks = (row) => {

    let actions: Array<any> = []

    const showUrl: string = prepareRouteUrl(ROUTES.clients.show.path, { id: row.id })
    const editUrl: string = prepareRouteUrl(ROUTES.clients.edit.path, { id: row.id })

    actions.push(
      <Tooltip key={`ShowId-${row.id}`} title="Podgląd">
        <IconButton aria-label="Show" size="medium" color="primary" component={LinkRouter} to={showUrl}>
          <RemoveRedEye fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )

    actions.push(
      <Tooltip key={`EditId-${row.id}`} title="Edycja">
      <IconButton aria-label="Edit" size="medium" component={LinkRouter} to={editUrl}>
        <EditIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
    )

    return actions
  }


  Status = (row) => {
    const status: Array<any> = []

    if(row.blocked) status.push(<CustomChip key={`Blocked${row.id}`} noMargin txtActive={"Zablokowana"} />)
    if(row.status === 'need_activation') status.push(<CustomChip  key={`Status-${row.id}`}noMargin txtActive={"Wymaga aktywacji"} />)

    return status
  }

  render() {

    const {
      rows, columns
    } = this.state

    return (

      <DataGridTable columns={columns} rows={rows} />

    )
  }
}

export default DataGridClients