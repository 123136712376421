import React from 'react'

// UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  Typography,
  IconButton
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    typography: {
      marginRight: theme.spacing(6)
    }
  }),
)

interface Props {
  id: string
  children: React.ReactNode
  onClose: () => void
}

export const CustomDialogTitle: React.FC<Props> = (props) => {
  const classes = uiStyles()

  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography className={classes.typography} variant="h6">{props.children}</Typography>
      {
        props.onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        ) : null
      }
    </MuiDialogTitle>
  )
}