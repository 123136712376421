import React from 'react'
import clsx from 'clsx'

// UI
import uiStyles from '../../assets/styles/styles'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import {
  Paper,
  Typography,
  Badge,
  AppBar,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core'
import Loading from '../../components/Loading/Loading'
import { ErrorAlert } from '../../components/FlashMessage/FlashMessage'
import DataGridEmpty from '../../components/DataGridEmpty/DataGridEmpty'
import DataGridSettlements from './DataGridSettlements/DataGridSettlements'

// GraphQL
import { Query } from 'react-apollo'
import { INDEX_SETTLEMENTS_QUERY } from './repositoryGraphQL'

const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    top: theme.spacing(2),
    right: theme.spacing(-2),
  },
}))(Badge)

interface Props extends WithStyles<typeof uiStyles> { }

type State = {
  tabIndex: number
}

class IndexSettlements extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      tabIndex: 0,
    }
  }

  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue
    })
  }

  render() {
    const { classes } = this.props
    const { tabIndex } = this.state

    const types: Array<any> = [false, true]

    return (
      <Query
        query={INDEX_SETTLEMENTS_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loading />
          if (error) return <ErrorAlert error={error} />

          const { settlements } = data
          const toSettle = settlements ? settlements.filter(settlement => !settlement.settled).length : 0
          const settled = settlements ? settlements.filter(settlement => settlement.settled).length : 0

          return (
            <>
              <Paper className={classes.paper}>
                <StyledBadge badgeContent={settlements ? Object.keys(settlements).length : 0} color="primary">
                  <Typography variant="h5" component="h3">
                    Rozliczenia
                </Typography>
                </StyledBadge>
                <Typography variant="caption" component="p">
                  Lista rozliczeń
              </Typography>
              </Paper>
              <AppBar position="static" color="default" className={classes.menuTabs}>
                <Tabs
                  value={tabIndex}
                  onChange={this.handleTabChange}
                  variant="fullWidth"
                  indicatorColor="secondary"
                >
                  <Tab label={`Do rozliczenia (${toSettle})`} />
                  <Tab label={`Rozliczone (${settled})`} />
                </Tabs>
              </AppBar>
              <Paper className={classes.tabs}>
                {
                  types.map((item, index) => (
                    <Box key={index} component="div" paddingLeft={2} paddingRight={2} className={clsx(classes.tab, tabIndex === index && classes.tabShow)}>
                      {settlements && settlements.filter(settlement => settlement.settled === item).length > 0 ? <DataGridSettlements onCompleted={refetch} rows={settlements.filter(settlement => settlement.settled === item)} /> : <DataGridEmpty />}
                    </Box>
                  ))
                }
              </Paper>
            </>
          )
        }}
      </Query>
    )
  }
}

export default withStyles(uiStyles)(IndexSettlements)