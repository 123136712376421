import React from "react";

import Login from "./containers/Login/Login";
import ResetPassword from "./containers/Password/ResetPassword/ResetPassword";
import ResetPasswordRequest from "./containers/Password/ResetPasswordRequest/ResetPasswordRequest";
import ActivateUser from "./containers/User/ActivateUser/ActivateUser";
import ChangePassword from "./containers/Profile/ChangePassword/ChangePassword";
import ShowProfile from "./containers/Profile/ShowProfile/ShowProfile";
import IndexRestaurants from "./containers/Restaurants/IndexRestaurants";
import ShowRestaurant from "./containers/Restaurants/ShowRestaurant/ShowRestaurant";
import EditRestaurant from "./containers/Restaurants/EditRestaurant/EditRestaurant";
import IndexWaiters from "./containers/Waiters/IndexWaiters";
import EditWaiter from "./containers/Waiters/EditWaiter/EditWaiter";
import ShowWaiter from "./containers/Waiters/ShowWaiter/ShowWaiter";
import IndexClients from "./containers/Clients/IndexClients";
import ShowClient from "./containers/Clients/ShowClient/ShowClient";
import EditClient from "./containers/Clients/EditClient/EditClient";
import IndexUsers from "./containers/Users/IndexUsers";
import ShowUser from "./containers/Users/ShowUser/ShowUser";
import EditUser from "./containers/Users/EditUser/EditUser";
import IndexDictionaries from "./containers/Dictionaries/IndexDictionaries";
import IndexValues from "./containers/Dictionaries/Values/IndexValues";
import IndexReports from "./containers/Reports/IndexReports";
import ShowReport from "./containers/Reports/ShowReport/ShowReport";
import IndexSettlements from "./containers/Settlements/IndexSettlements";
import ShowSettlement from "./containers/Settlements/ShowSettlement/ShowSettlement";
import IndexTips from "./containers/Tips/IndexTips";
import IndexPromotions from "./containers/Promotions/IndexPromotions";

import { Redirect } from "react-router-dom";
import IndexFeedback from "./containers/Feedback/IndexFeedback";

/**
 * App Routes
 */

const ROUTES = {
  home: {
    path: "/",
    component: () => <Redirect to="/restaurants" />,
    exact: true,
  },
  auth: {
    login: {
      path: "/login",
      component: Login,
    },
  },
  resetPassword: {
    index: {
      path: "/reset-password/:token",
      component: ResetPassword,
    },
    request: {
      path: "/reset-password",
      component: ResetPasswordRequest,
    },
  },
  users: {
    index: {
      path: "/users",
      component: IndexUsers,
      exact: true,
    },
    show: {
      path: "/user/:id/show",
      component: ShowUser,
      exact: true,
    },
    edit: {
      path: "/user/:id/edit",
      component: EditUser,
      exact: true,
    },
  },
  user: {
    activate: {
      path: "/activate/:token",
      component: ActivateUser,
    },
  },
  profile: {
    show: {
      path: "/profile",
      component: ShowProfile,
      exact: true,
    },
    passwordChange: {
      path: "/change-password",
      component: ChangePassword,
      exact: true,
    },
    edit: {
      path: "/restaurant/:id/edit",
      component: EditRestaurant,
      exact: true,
    },
  },
  restaurants: {
    index: {
      path: "/restaurants",
      component: IndexRestaurants,
      exact: true,
    },
    show: {
      path: "/restaurant/:id/show",
      component: ShowRestaurant,
      exact: true,
    },
    edit: {
      path: "/restaurant/:id/edit",
      component: EditRestaurant,
      exact: true,
    },
  },
  waiters: {
    index: {
      path: "/waiters",
      component: IndexWaiters,
      exact: true,
    },
    show: {
      path: "/waiter/:id/show",
      component: ShowWaiter,
      exact: true,
    },
    edit: {
      path: "/waiter/:id/edit",
      component: EditWaiter,
      exact: true,
    },
  },
  feedback: {
    index: {
      path: "/feedback",
      component: IndexFeedback,
      exact: true,
    },
  },
  clients: {
    index: {
      path: "/clients",
      component: IndexClients,
      exact: true,
    },
    show: {
      path: "/client/:id/show",
      component: ShowClient,
      exact: true,
    },
    edit: {
      path: "/client/:id/edit",
      component: EditClient,
      exact: true,
    },
  },
  reports: {
    index: {
      path: "/reports",
      component: IndexReports,
      exact: true,
    },
    show: {
      path: "/reports/:id/show",
      component: ShowReport,
      exact: true,
    },
  },
  tips: {
    index: {
      path: "/tips",
      component: IndexTips,
      exact: true,
    },
  },
  promotions: {
    index: {
      path: "/promotions",
      component: IndexPromotions,
      exact: true,
    },
  },
  settlements: {
    index: {
      path: "/settlements",
      component: IndexSettlements,
    },
    show: {
      path: "/settlements/:id/show",
      component: ShowSettlement,
    },
  },
  dictionaries: {
    index: {
      path: "/dictionaries",
      component: IndexDictionaries,
      exact: true,
    },
    values: {
      path: "/dictionary/:sysName/",
      component: IndexValues,
      exact: true,
    },
  },
};

export default ROUTES;
