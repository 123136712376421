import React from "react";
import { RouteComponentProps } from "react-router-dom";

// UI
import uiStyles from "../../assets/styles/styles";
import { withStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Paper, Typography, Badge, Box, AppBar, Tabs, Tab } from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
import { ErrorAlert } from "../../components/FlashMessage/FlashMessage";
import DataGridEmpty from "../../components/DataGridEmpty/DataGridEmpty";
import DataGridPromotions from "./DataGridPromotions/DataGridPromotions";
import clsx from "clsx";
import CreatePromotion from "./CreatePromotion/CreatePromotion";

// GraphQL
import { Query } from "react-apollo";
import { INDEX_PROMOTIONS_QUERY } from "./repositoryGraphQL";

const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    top: theme.spacing(2),
    right: theme.spacing(-2),
  },
}))(Badge);

interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps { }

type State = {
  tabIndex: number;
};

class IndexPromotions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabIndex: 0,
    };
  }

  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue,
    });
  };

  downloadCsv = () => {
    const url = `${process.env.REACT_APP_GRAPHQL_API
      ? process.env.REACT_APP_GRAPHQL_API.slice(0, -7)
      : null
      }downloads/tips`;
    window.open(url);
  };

  render() {
    const { classes } = this.props;
    const { tabIndex } = this.state;

    const types: Array<any> = [false, true]

    return (
      <Query
        query={INDEX_PROMOTIONS_QUERY}
        fetchPolicy="network-only"
      >
        {({
          loading,
          error,
          data,
          refetch,
        }: {
          loading: any;
          error?: any;
          data: any;
          refetch: any;
        }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorAlert error={error} />;

          const { promotions } = data;

          const active = promotions && promotions.filter(promotion => !promotion.availableUntil || new Date(promotion.availableUntil) > new Date())
          const inactive = promotions && promotions.filter(promotion => promotion.availableUntil && new Date(promotion.availableUntil) <= new Date())

          return (
            <>
              <Paper className={classes.paper}>
                <StyledBadge
                  badgeContent={promotions ? Object.keys(promotions).length : 0}
                  color="primary"
                >
                  <Typography variant="h5" component="h3">
                    Promocje
                  </Typography>
                </StyledBadge>
                <Typography variant="caption" component="p">
                  Zarządzanie promocjami
                </Typography>
              </Paper>

              <AppBar position="static" color="default" className={classes.menuTabs}>
                <Tabs
                  value={tabIndex}
                  onChange={this.handleTabChange}
                  variant="fullWidth"
                  indicatorColor="secondary"
                >
                  <Tab label={`Aktywne (${active && active.length})`} />
                  <Tab label={`Nieaktywne (${inactive && inactive.length})`} />
                </Tabs>
              </AppBar>
              <Paper className={classes.tabs}>
                <Box
                  component="div"
                  paddingLeft={2}
                  className={clsx(classes.tab, classes.tabShow)}
                  paddingRight={2}
                  paddingTop={6}
                  position={"relative"}
                >
                  <CreatePromotion row={promotions} onCompleted={refetch} />
                  {
                    types.map((item, index) => (
                      <Box key={index} component="div" paddingLeft={2} paddingRight={2} className={clsx(classes.tab, tabIndex === index && classes.tabShow)}>
                        {promotions && [...(tabIndex === 0 ? active : inactive)].length > 0 ? <DataGridPromotions
                          updateDataGrid={refetch} rows={tabIndex === 0 ? active : inactive} /> : <DataGridEmpty />}
                      </Box>
                    ))
                  }
                </Box>
              </Paper>
            </>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(uiStyles)(IndexPromotions);
