import React from 'react'
import { RouteComponentProps, Link as LinkRouter } from 'react-router-dom'
import ROUTES from '../../../routes'
import Auth from '../../../utils/Auth/Auth'

// UI
import uiStyles from '../../../assets/styles/styles'
import {
  CssBaseline,
  Avatar,
  Link,
  Grid,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  withStyles,
  WithStyles
} from '@material-ui/core'
import { Visibility, VisibilityOff, LockOutlined } from '@material-ui/icons'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { ErrorAlert, SuccessAlert } from '../../../components/FlashMessage/FlashMessage'
import { passwordRegexp, passwordErrorMsg } from '../../../utils/validators'
import { LongActionBtn, LoaderLongBtn } from '../../../components/FormActionLinks/FormActionLinks'

// GraphQL
import { Mutation } from 'react-apollo'
import { RESET_PASSWORD_MUTATION } from '../repositoryGraphQL'


interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  match: any
}

type State = {
  newPassword: string
  confirmPassword: string
  showPassword: boolean
}

class ResetPassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      newPassword: '',
      confirmPassword: '',
      showPassword: false
    }

    Auth.clear()
  }

  UNSAFE_componentWillMount() {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.newPassword) {
        return false
      }
      return true
    })
  }

  handleClickShowPassword = () => { this.setState({ showPassword: !this.state.showPassword })}

  render() {
    const { classes } = this.props
    const { token } = this.props.match.params
    const { newPassword, confirmPassword, showPassword } = this.state

    return (
      <Mutation
        mutation={RESET_PASSWORD_MUTATION}
        variables={{ newPassword, confirmPassword, token }}
        onCompleted={(data: any) => this._confirm(data)}
      >
        {(submitForm: any, { loading, data, error }: any) => (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper_auth}>
              <Avatar className={classes.avatar}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Zmiana hasła logowania
              </Typography>
              <ValidatorForm
                ref="form"
                onSubmit={submitForm}
                className={classes.form}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      required
                      fullWidth
                      name="newPassword"
                      label="Nowe hasło"
                      id="newPassword"
                      autoComplete="password"
                      type={showPassword ? 'text' : 'password'}
                      value={newPassword}
                      onChange={(e: { target: { value: string } }) => this.setState({ newPassword: e.target.value })}
                      validators={['required', passwordRegexp]}
                      errorMessages={['Pole wymagane.', passwordErrorMsg]}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={this.handleClickShowPassword}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <TextValidator
                      required
                      fullWidth
                      variant="outlined"
                      id="field-confirm-new-password"
                      type={showPassword ? 'text' : 'password'}
                      label="Potwierdź nowe hasło"
                      value={this.state.confirmPassword}
                      onChange={(e: { target: { value: string; }; }) => this.setState({ confirmPassword: e.target.value })}
                      validators={['isPasswordMatch', 'required']}
                      errorMessages={['Niezgodność haseł', 'Pole wymagane.']}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={this.handleClickShowPassword}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                {!loading && <LongActionBtn text="Resetuj hasło" />}
                {loading && <LoaderLongBtn />}
                {(!loading && data) && <SuccessAlert message="Hasło pomyślnie zmienione" />}
                {error && <ErrorAlert error={error} />}
                {(data && data.resetPasswordUpdate.errors) && <ErrorAlert modelName="resetPassword" fieldError={data.resetPasswordUpdate.errors} />}
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link variant="body2" component={LinkRouter} to={ROUTES.auth.login.path}>
                      Zaloguj się
                    </Link>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
          </Container>
        )}
      </Mutation>
    )
  }

  _confirm = async (data: any) => {
    if (data)
      this.props.history.push(ROUTES.auth.login.path)
  }
}

export default withStyles(uiStyles)(ResetPassword)