import React from "react";

// UI
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormGroup,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  ErrorAlert,
  SuccessAlert,
} from "../../../components/FlashMessage/FlashMessage";
import { CustomDialogTitle } from "../../../components/Dialog/Dialog";

// GraphQL
import { Mutation } from "react-apollo";
import {
  ClosePopupBtn,
  SubmitActionBtn,
  LoaderBtn,
} from "../../../components/FormActionLinks/FormActionLinks";
import { DESTROY_PROMOTION_MUTATION } from "../repositoryGraphQL";

interface Props {
  promotionId: string;
  name: string;
  onCompleted: any;
}

type State = {
  open: boolean;
  confirm: boolean;
};

class DeletePromotion extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      confirm: false,
    };
  }

  open = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirm: e.target.checked });
  };

  render() {
    const { promotionId, name } = this.props;
    const { confirm } = this.state;

    return (
      <>
        <Tooltip title="Usuń">
          <IconButton aria-label="Delete" size="medium" onClick={this.open}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Mutation
          mutation={DESTROY_PROMOTION_MUTATION}
          variables={{ id: promotionId }}
          onCompleted={(data: any) => this._confirm(data)}
        >
          {(submitForm: any, { loading, error, data }: any) => (
            <>
              <Dialog
                open={this.state.open}
                onClose={this.close}
                aria-labelledby="form-dialog-title"
              >
                <CustomDialogTitle
                  id="form-dialog-title"
                  onClose={() => this.close()}
                >
                  Czy na pewno chcesz usunąć?
                </CustomDialogTitle>
                <ValidatorForm ref="form" onSubmit={submitForm}>
                  <DialogContent>
                    <DialogContentText>
                      Wybrana promocja do usunięcia:
                      <Typography
                        color="primary"
                        component={"span"}
                        display="block"
                      >
                        {name}
                      </Typography>
                    </DialogContentText>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={confirm}
                            onChange={this.handleChange}
                            value="1"
                          />
                        }
                        label="Potwierdzam usunięcie"
                      />
                    </FormGroup>
                  </DialogContent>
                  <DialogActions>
                    {!loading && (
                      <ClosePopupBtn
                        onClick={() => this.close()}
                        text="Anuluj"
                      />
                    )}
                    {!loading && (
                      <SubmitActionBtn disabled={!confirm} text="Usuń" />
                    )}
                    {loading && <LoaderBtn />}
                  </DialogActions>
                </ValidatorForm>
              </Dialog>
              {!loading && data && (
                <SuccessAlert message="Usunięto pomyślnie." />
              )}
              {error && <ErrorAlert error={error} />}
              {data && data.promotionDestroy.errors && (
                <ErrorAlert
                  modelName="invitation"
                  fieldError={data.promotionDestroy.errors}
                />
              )}
            </>
          )}
        </Mutation>
      </>
    );
  }
  _confirm = async (data: any) => {
    if (!data) return;

    this.setState({
      open: false,
      confirm: false,
    });

    this.props.onCompleted();
  };
}

export default DeletePromotion;
