/**
 * Utils
 */

/**
 * Change object to array
 * 
 * @param object object
 * @return array
 */
export const objectToArray = (object: object): Array<any> => {
  return Object.values(object)
}

/**
 * Prepare route url with params
 * 
 * @param path string
 * @param params object
 * 
 * @return string
 */
export const prepareRouteUrl = (path: string, params: object): string => {
  let url: string = path

  Object.keys(params).forEach((key: any) => {
    url = url.replace(new RegExp(`:${key}`, 'g'), params[key])
  })

  return url
}