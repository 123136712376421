import React from 'react'
import Auth from '../../../utils/Auth/Auth'
import { RouteComponentProps, Link as LinkRouter } from 'react-router-dom'
import ROUTES from '../../../routes'

// UI
import uiStyles from '../../../assets/styles/styles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Avatar,
  CssBaseline,
  Link,
  Grid,
  Typography,
  Container,
} from '@material-ui/core'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { ErrorAlert, SuccessAlert } from '../../../components/FlashMessage/FlashMessage'
import { LockOutlined } from '@material-ui/icons'
import { LongActionBtn, LoaderLongBtn } from '../../../components/FormActionLinks/FormActionLinks'

// GraphQL
import { Mutation } from 'react-apollo'
import { RESET_PASSWORD_REQUEST_MUTATION } from '../repositoryGraphQL'


interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  //
}

type State = {
  email: string
}

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      email: ''
    }

    Auth.clear()
  }

  render() {
    const { classes } = this.props
    const { email } = this.state

    return (
      <Mutation
        mutation={ RESET_PASSWORD_REQUEST_MUTATION }
        variables={{ email }}
        onCompleted={(data: any) => this._confirm(data)}
      >
        {(submitForm: any, { loading, data, error }: any) => (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper_auth}>
              <Avatar className={classes.avatar}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Resetowanie hasła
              </Typography>
              <ValidatorForm
                ref="form"
                onSubmit={submitForm}
                className={classes.form}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Adres e-mail"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e: { target: { value: string } }) => this.setState({ email: e.target.value })}
                      validators={['required', 'isEmail']}
                      errorMessages={['Pole wymagane.', 'Adres e-mail jest nieprawidłowy.']}
                    />
                  </Grid>
                </Grid>
                {!loading && <LongActionBtn text="Resetuj hasło" />}
                {loading && <LoaderLongBtn />}
                {(!loading && data) && <SuccessAlert message={data.resetPasswordRequest.message} />}
                {error && <ErrorAlert error={error} />}
                {(data && data.resetPasswordRequest.errors) && <ErrorAlert modelName="resetPassword" fieldError={data.resetPasswordRequest.errors} />}
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link variant="body2" component={LinkRouter} to={ROUTES.auth.login.path}>
                      Zaloguj się
                    </Link>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
          </Container>
        )}
      </Mutation>
    )
  }

  _confirm = async (data: any) => {}
}

export default withStyles(uiStyles)(Login)