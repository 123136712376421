import React from 'react'

import DestroyObject, { DestroyProps } from '../../../../../components/DestroyObject/DestroyObject'

// GraphQL
import { DESTROY_VALUE_MUTATION } from './../../../repositoryGraphQL'

type Props = {
  onCompleted?: any
}

const DestroyValue: React.FC<Props & DestroyProps> = (props) => {

  return <DestroyObject
    {...props}
    btnText="Usuń pozycję"
    mutation={DESTROY_VALUE_MUTATION}
    onCompleted={() => props.onCompleted()}
  />
}

export default DestroyValue
