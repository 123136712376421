import React from 'react'

// UI
import uiStyles from './uiStyles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'


interface Props extends WithStyles<typeof uiStyles> {
  times: any
}

class WeekSchedulerShow extends React.Component<Props,{}> {
 

  render() {

    const daysOfWeek: any = [
      { prefix: 'mon', name: 'poniedziałek' },
      { prefix: 'tue', name: 'wtorek' },
      { prefix: 'wed', name: 'środa' },
      { prefix: 'thu', name: 'czwartek' },
      { prefix: 'fri', name: 'piątek' },
      { prefix: 'sat', name: 'sobota' },
      { prefix: 'sun', name: 'niedziela' }
    ]

    return (
      <>
        <Table style={{margin: '0 auto'}}>
        <TableHead>
          <TableRow>
            <TableCell align="left"></TableCell>
            <TableCell align="center">Od</TableCell>
            <TableCell align="center">Do</TableCell>
            <TableCell align="center">Ostatnie zamówienie</TableCell>
          </TableRow>
        </TableHead> 
          <TableBody>
            {daysOfWeek.map((item: any) => {

              const dayFrom = `${item.prefix}From`
              const dayTo = `${item.prefix}To`
              const lastOrderHour = `${item.prefix}LastOrderHour`
              const key = `${item.prefix}Id}`

              return (
                <TableRow key={key}>
                  <TableCell component="th" scope="row"> {item.name} </TableCell>
                  <TableCell style={{ minWidth: 180 }} align="center">
                    {this.props.times[dayFrom] ? this.props.times[dayFrom].slice(0, -3) : '-'}
                  </TableCell>
                  <TableCell style={{ minWidth: 180 }} align="center">
                  {this.props.times[dayTo] ? this.props.times[dayTo].slice(0, -3) : '-'}
                  </TableCell>
                  <TableCell style={{ minWidth: 300 }} align="center">
                  {this.props.times[lastOrderHour] ? this.props.times[lastOrderHour].slice(0, -3) : '-'}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </>
    )
  }
}

export default withStyles(uiStyles)(WeekSchedulerShow)