import React from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import ROUTES from '../../../routes'
import { prepareRouteUrl } from '../../../utils/utils'

import { IconButton, Tooltip, Link } from '@material-ui/core'
import { DataGridTable } from '../../../components/DataGridTable/DataGridTable'
import { FormattedDate, FormattedPrice } from '../../../components/FormattedData/FormattedData'
import { RemoveRedEye, SaveAlt } from '@material-ui/icons'


interface Props {
  rows: Array<{}>
  selectable?: boolean
  onSelected: any
}

interface State {
  rows: any
  columns: Array<{
    field: string
    title: string
    render?: any,
    cellStyle?: any,
    headerStyle?: any
    customSort?:any
  }>
}

class DataGridReports extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      columns: [
        { field: 'restaurantName', title: 'Restauracja', headerStyle: { minWidth: '20%' }, render: row => this.toLink(row),  customSort: (a, b) => a.restaurant.name > b.restaurant.name },
        { field: 'period', title: 'Za okres', headerStyle: { minWidth: '20%' }, render: row => <><FormattedDate format="DD.MM.YYYY" value={row.fromDate} /> - <FormattedDate format="DD.MM.YYYY" value={row.toDate} /> </>, customSort: (a, b) => a.fromDate > b.fromDate},
      { field: 'totalAmount', title: 'Kwota', headerStyle: { minWidth: '10%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: row => <FormattedPrice value={row.totalAmount} />},
        { field: 'createdAt', title: 'Data utworzenia', headerStyle: { minWidth: '20%' }, render: row => <FormattedDate format="DD.MM.YYYY HH:mm:ss" value={row.createdAt} />},
        // { field: 'settled', title: 'Status', headerStyle: { minWidth: '15%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: row =>  <ActiveToggleChip txtActive="Rozliczony" txtDeactive="Do rozliczenia" status={row.settled} />},
        { field: 'actions', title: 'Akcje', headerStyle: { minWidth: '25%', textAlign: 'right' }, cellStyle: { textAlign: 'right' }, render: row => this.ActionsLinks(row) }
      ],
      rows: this.props.rows
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return (props.rows !== state.rows) ? { rows: props.rows } : null
  }

  toLink = (row) => {
    const showUrl: string = prepareRouteUrl(ROUTES.reports.show.path, { id: row.id })
    
    return (<Link aria-label="Show" component={LinkRouter} to={showUrl}>{row.restaurant.name}</Link>) 
  }

  ActionsLinks = (row) => {

    let actions: Array<any> = []

    const showUrl: string = prepareRouteUrl(ROUTES.reports.show.path, { id: row.id })

    actions.push(
      <Tooltip key={`ShowId-${row.id}`} title="Podgląd">
        <IconButton aria-label="Show" size="medium" color="primary" component={LinkRouter} to={showUrl}>
          <RemoveRedEye fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )

    actions.push(
      <Tooltip key={`DowloadId-${row.id}`} title="Pobierz">
        <IconButton aria-label="Download" size="medium" href={row.linkToCsv}>
          <SaveAlt fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )

    return actions
  }

  selectionProps = (row) => ({
    disabled: row.settled 
  })

  render() {

    const {
      rows, columns
    } = this.state

    return (

      <DataGridTable selection={this.props.selectable} columns={columns} rows={rows} selectionProps={this.selectionProps} onSelectionChange={this.props.onSelected} />

    )
  }
}

export default DataGridReports