import React from 'react'
import clsx from 'clsx'
import ROUTES from '../../routes'
import { RouteComponentProps } from 'react-router-dom'

// UI
import uiStyles from '../../assets/styles/styles'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import {
  Paper,
  Typography,
  Badge,
  AppBar,
  Tabs,
  Tab,
  Box,
  Grid,
} from '@material-ui/core'
import Loading from '../../components/Loading/Loading'
import { ErrorAlert } from '../../components/FlashMessage/FlashMessage'
import DataGridEmpty from '../../components/DataGridEmpty/DataGridEmpty'
import DataGridReports from './DataGridReports/DataGridReports'
import CreateReport from './CreateReport/CreateReport'
import CreateSettlement from './CreateSettlement/CreateSettlement'


// GraphQL
import { Query } from 'react-apollo'
import { INDEX_REPORTS_QUERY } from './repositoryGraphQL'
import { FormattedPrice } from '../../components/FormattedData/FormattedData'



const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    top: theme.spacing(2),
    right: theme.spacing(-2),
  },
}))(Badge)

interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {}

type State = {
  reportIds: Array<any>
  tabIndex: number
}

class IndexReports extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      reportIds: [],
      tabIndex: 0,
    }
  }

  handleSelect = (selected): void => {
    let ids: Array<any> = []

    selected.forEach(item => ids.push(item.id))

    this.setState({ reportIds: ids })

  }

  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue
    })
  }

  goToSettlements = () => this.props.history.push(ROUTES.settlements.index.path)

  render() {
    const { classes } = this.props
    const { tabIndex } = this.state

    const types: Array<any> = [false, true]

    return (
      <Query
        query={INDEX_REPORTS_QUERY}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loading />
          if (error) return <ErrorAlert error={error} />

          const { reports } = data.reports

          const toSettle = reports ? reports.filter(report => !report.settled).length : 0
          const settled = reports ? reports.filter(report => report.settled).length : 0

          return (
            <>        
            <Paper className={classes.paper}>
              <StyledBadge badgeContent={reports ? Object.keys(reports).length : 0} color="primary">
                <Typography variant="h5" component="h3">
                  Raporty
                </Typography>
              </StyledBadge>
              <Typography variant="caption" component="p">
                Lista raportów
              </Typography>
              <Grid  className={classes.marginTop} container spacing={2}>
                <Grid item xs={12} sm={6}>
                  Do rozliczenia pozostało: <span style={{fontSize: 15, fontWeight: 500}}><FormattedPrice value={data.reports.amountToSettled} /></span>
                </Grid>
                <Grid style={{textAlign: 'right'}} item xs={12} sm={6}>
                  <CreateReport onCompleted={refetch} />
                </Grid>
              </Grid>
              </Paper>
              <AppBar position="static" color="default" className={classes.menuTabs}>
                <Tabs
                  value={tabIndex}
                  onChange={this.handleTabChange}
                  variant="fullWidth"
                  indicatorColor="secondary"
                >
                  <Tab label={`Do rozliczenia (${toSettle})`} />
                  <Tab label={`Rozliczone (${settled})`} />
                </Tabs>
              </AppBar>
              <Paper className={classes.tabs}>
                {
                  types.map((item, index) => (
                    <Box key={index} component="div" paddingLeft={2} paddingRight={2} className={clsx(classes.tab, tabIndex === index && classes.tabShow)}>
                      {reports && reports.filter(report => report.settled === item).length > 0 ? <DataGridReports selectable={!item}  onSelected={this.handleSelect} rows={reports.filter(report => report.settled === item)} /> : <DataGridEmpty />}
                    </Box>
                  ))
                }
                {
                  tabIndex === 0 && 
                  <CreateSettlement onCompleted={this.goToSettlements} reportIds={this.state.reportIds} />
                }   
              </Paper>
            </>
          )
        }}
      </Query>
    )
  }
}

export default withStyles(uiStyles)(IndexReports)