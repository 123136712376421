import React from 'react'
import ROUTES from '../../../routes'
import { prepareRouteUrl } from '../../../utils/utils'

// UI
import uiStyles from '../../../assets/styles/styles'
import clsx from 'clsx'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Paper,
  Grid,
  Divider,
  Typography,
  InputLabel,
  Tooltip,
  Fab,
  Box,
  AppBar,
  Tabs,
  Tab,
  Link
} from '@material-ui/core'
import TopBackButton from '../../../components/TopBackButton/TopBackButton'
import EditIcon from '@material-ui/icons/Edit'
import { Link as LinkRouter } from 'react-router-dom'
import Loading from '../../../components/Loading/Loading'
import { ErrorAlert } from '../../../components/FlashMessage/FlashMessage'
import { EmailLink } from '../../../components/AppLinks/AppLinks'
import { FormattedBankAccountNumber, FormattedDate, FormattedPrice } from '../../../components/FormattedData/FormattedData'
import { ActiveToggleChip } from '../../../components/CustomChips/CustomChips'



// GraphQL
import { Query } from 'react-apollo'
import { SHOW_WAITER_QUERY } from '../repositoryGraphQL'
import { DataGridTable } from '../../../components/DataGridTable/DataGridTable'

interface Props extends WithStyles<typeof uiStyles> {
  match: any
}

type State = {
  tabIndex: number
}

class ShowWaiter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      tabIndex: 0
    }
  }

  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue
    })
  }
  
  render() {
    const { classes } = this.props
    const { id } = this.props.match.params
    const { tabIndex } = this.state

    const columns = [
        { field: 'category', title: 'Kategoria', defaultGroupOrder: 0, headerStyle: { minWidth: '5%' }},
      { field: 'order.uniqueNumber', title: 'Numer zamówienia' },
      { field: 'order.restaurant.name', title: 'Restauracja', render: (row) => <Link component={LinkRouter} to={prepareRouteUrl(ROUTES.restaurants.show.path, { id: row.order.restaurant.id })}>{row.order && row.order.restaurant && row.order.restaurant.name}</Link> },
      { field: 'order.createdAt', title: "Data zamówienia", render: (row) => <FormattedDate format="DD.MM.YYYY HH:mm:ss" value={row.order.createdAt} /> },
      { field: 'order.settled', title: "Status", render: (row) => ShowStatus(row) },
      { field: 'value', title: 'Kwota napiwku', headerStyle: { minWidth: '35%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: (row) => <FormattedPrice value={row.value} />}
    ];

    
  const ShowStatus = (row) => (
      <ActiveToggleChip noMargin txtActive='Rozliczone' txtDeactive='Nierozliczone' status={row.settled} />
  )

    return (
      <Query
        query={SHOW_WAITER_QUERY}
        variables={{ id }}
        fetchPolicy="network-only"
        >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loading />
          if (error) return <ErrorAlert error={error} />

          const { waiter } = data

          const backUrl: string = ROUTES.waiters.index.path
          const editUrl: string = prepareRouteUrl(ROUTES.waiters.edit.path, { id: waiter.id })

          const showName = waiter.firstName && waiter.lastName ? waiter.firstName + ' ' + waiter.lastName : waiter.email

          return (
            <>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Grid item xs={6}>
                      <TopBackButton
                        url={backUrl}
                        title={`${showName}`}
                        subtitle="Dane kelnera"
                      />
                    </Grid>
                    <Tooltip title="Edycja">
                      <Fab aria-label="Edit" size="medium" className={classes.fab_edit} color="secondary" component={LinkRouter} to={editUrl}>
                        <EditIcon />
                      </Fab>
                    </Tooltip>
                  </Grid>
                  <Grid item sm={6}>
                    <InputLabel
                      shrink
                      className={classes.inputLabel}>
                      Imię i nazwisko
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="div"
                      className={classes.typography}
                    >
                      {
                        waiter.firstName || waiter.lastName ?
                          `${waiter.firstName} ${waiter.lastName}`
                          :
                          ' - '

                      }
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <InputLabel
                      shrink
                      className={classes.inputLabel}>
                      Adres
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.typography}
                    >
                      {
                        waiter.waiterProfile.street &&
                        `ul. ${waiter.waiterProfile.street}`
                      }<br />
                      {
                        waiter.waiterProfile.postcode && waiter.waiterProfile.city &&
                        `${waiter.waiterProfile.postcode} ${waiter.waiterProfile.city}`
                      }
                    </Typography>
                  </Grid>
                  <ActiveToggleChip status={waiter.activationState} />
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    <InputLabel
                      shrink
                      className={classes.inputLabel}>
                      E-mail
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.typography}
                    >
                      <EmailLink value={waiter.email} />
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <AppBar position="static" color="default" className={classes.menuTabs}>
                <Tabs
                  value={tabIndex}
                  onChange={this.handleTabChange}
                  variant="fullWidth"
                  indicatorColor="secondary"
                >
                  <Tab label="Dane Kelnera" />
                  <Tab label="Dane bankowe" />
                  <Tab label="Napiwki" />
                </Tabs>
              </AppBar>
              <Paper className={classes.tabs}>
                <Box component="div" className={clsx(classes.tab, tabIndex === 0 && classes.tabShow)} padding={4}>
                  <Grid container>
                    <Grid item sm={4}>
                      <InputLabel
                        shrink
                        className={classes.inputLabel}>
                        Data urodzenia
                    </InputLabel>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.typography}
                      >
                        {waiter.waiterProfile.birthDate ? <FormattedDate value={waiter.waiterProfile.birthDate} /> : "Brak"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <Grid container spacing={2}>
                    <Grid item sm={4}>
                      <InputLabel
                        shrink
                        className={classes.inputLabel}>
                        Utworzony
                    </InputLabel>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.typography}
                      >
                        {waiter.waiterProfile.createdAt && <FormattedDate value={waiter.waiterProfile.createdAt} />}
                      </Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <InputLabel
                        shrink
                        className={classes.inputLabel}>
                        Ostatnia aktywność
                    </InputLabel>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.typography}
                      >
                        {waiter.lastActivityAt && <FormattedDate value={waiter.lastActivityAt} />}
                      </Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <InputLabel
                        shrink
                        className={classes.inputLabel}>
                        Ostatnie IP
                    </InputLabel>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.typography}
                      >
                        {waiter.lastLoginFromIpAddress}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {/* Dane bankowe */}
                <Box component="div" className={clsx(classes.tab, tabIndex === 1 && classes.tabShow)} padding={4}>
                  <Grid container>
                    <Grid item sm={6}>
                      <InputLabel
                        shrink
                        className={classes.inputLabel}>
                        Numer rachunku bankowego
                    </InputLabel>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.typography}
                      >
                        <FormattedBankAccountNumber value={waiter.waiterProfile.accountNumber} countryCode={waiter.waiterProfile.accountCountryCode} />
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {/* Napiwki */}
                <Box component="div" className={clsx(classes.tab, tabIndex === 2 && classes.tabShow)} padding={4}>
                  <Grid container>
                    <Grid item sm={12}>
                      <DataGridTable waiterTips columns={columns} rows={waiter.waiterProfile && waiter.waiterProfile.tips.map(e => ({category: e.transfer && e.transfer.settlement && e.transfer.settlement.id, ...e}))} />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </>
          )
        }}
      </Query>
    )
  }
}

export default withStyles(uiStyles)(ShowWaiter)
