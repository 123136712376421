import React from 'react'

// UI
import uiStyles from './uiStyles'
import { Drawer, Typography } from '@material-ui/core'
// import SidebarTitle from './SidebarTitle/SidebarTitle'
import Navi from './Navi/Navi'

type Props = {
  stateSidebar: any
}

const Sidebar: React.FC<Props> = (props) => {
  const classes = uiStyles()
  
  return (
    <Drawer
      className={classes.drawer}
      open={props.stateSidebar}
      variant="persistent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <Typography variant="h6">
          Kellner Admin
        </Typography>
        {/* <SidebarTitle /> */}
      </div>
      <Navi />
    </Drawer>
  )
}

export default Sidebar