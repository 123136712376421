import React from 'react'

// UI
import { Snackbar, CircularProgress } from '@material-ui/core'

const Loading: React.FC = () => (
  <Snackbar
    key="bottom,center"
    open={true}
    message={<span id="message-id">
      <CircularProgress size={16} color="secondary" />
      <span>&nbsp;&nbsp;&nbsp;Trwa pobieranie danych</span>
    </span>}
  />
)

export default Loading