import { createStore, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { interfaceReducer } from './interface/reducers'
import { dictionariesReducer } from './dictionaries/reducers'
import { authUserReducer } from './auth_user/reducers';

const rootReducer = combineReducers({
    authUser: authUserReducer,
    dictionaries: dictionariesReducer,
    interface: interfaceReducer
})

export type AppState = ReturnType<typeof rootReducer>

export const store = createStore(rootReducer, composeWithDevTools(
    // applyMiddleware(...middlewares),
    // other store enhancers if any
))