import React from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import ROUTES from '../../../routes'
import { prepareRouteUrl } from '../../../utils/utils'

import { IconButton, Tooltip, Link } from '@material-ui/core'
import { DataGridTable } from '../../../components/DataGridTable/DataGridTable'
import { FormattedDate, FormattedPrice } from '../../../components/FormattedData/FormattedData'
import { RemoveRedEye, SaveAlt } from '@material-ui/icons'
import { SettlementTypeChip } from '../../../components/CustomChips/CustomChips'
import MarkSettled from '../MarkSettled/MarkSettled'


interface Props {
  rows: Array<{}>
  onCompleted?: any
}

interface State {
  rows: any
  columns: Array<{
    field: string
    title: string
    render?: any,
    cellStyle?: any,
    headerStyle?: any
    customSort?: any
  }>
}

class DataGridSettlements extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      columns: [
        { field: 'createdAt', title: 'Data utworzenia', headerStyle: { minWidth: '20%' }, render: row => this.ShowDate(row) },
        { field: 'totalAmount', title: 'Kwota', headerStyle: { minWidth: '10%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: row => <FormattedPrice value={row.totalAmount} /> },
        { field: 'generatedFor', title: 'Wygenerowane dla', headerStyle: { minWidth: '15%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: row => <SettlementTypeChip type={row.generatedFor} /> },
        { field: 'actions', title: 'Akcje', headerStyle: { minWidth: '20%', textAlign: 'right' }, cellStyle: { textAlign: 'right' }, render: row => this.ActionsLinks(row) }
      ],
      rows: this.props.rows
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return (props.rows !== state.rows) ? { rows: props.rows } : null
  }

  ShowDate = (row) => {
    return <Link component={LinkRouter} to={prepareRouteUrl(ROUTES.settlements.show.path, { id: row.id })}><FormattedDate format="DD.MM.YYYY HH:mm:ss" value={row.createdAt} /></Link>
  }

  ActionsLinks = (row) => {
    let actions: Array<any> = []
    const showUrl: string = prepareRouteUrl(ROUTES.settlements.show.path, { id: row.id })

    actions.push(
      <Tooltip key={`ShowId-${row.id}`} title="Podgląd">
        <IconButton aria-label="Show" size="medium" color="primary" component={LinkRouter} to={showUrl}>
          <RemoveRedEye fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )

    actions.push(
      <Tooltip key={`DowloadId-${row.id}`} title="Pobierz">
        <IconButton aria-label="Download" size="medium" href={row.linkToCsv}>
          <SaveAlt fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )

    if (!row.settled) {
      actions.push(
        <Tooltip key={`Settle-${row.id}`} title="Pobierz">
          <MarkSettled icon createdAt={row.createdAt} id={row.id} onCompleted={this.props.onCompleted} />
        </Tooltip>
      )
    }

    return actions
  }

  render() {
    const { rows, columns } = this.state

    return (
      <DataGridTable columns={columns} rows={rows} />
    )
  }
}

export default DataGridSettlements