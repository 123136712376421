import React from 'react'
import Auth from '../../utils/Auth/Auth'
import { Link } from 'react-router-dom'
import ROUTES from '../../routes'
import { connect } from 'react-redux'
import { AppState } from '../../store'

// UI
import uiStyles from './uiStyles'
import clsx from 'clsx'
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Divider,
  MenuItem,
  Menu,
  Tooltip
} from '@material-ui/core'
import InputIcon from '@material-ui/icons/Input'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import LockIcon from '@material-ui/icons/Lock'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
// import SectionName from './SectionName/SectionName'
import AppBreadcrumbs from '../../components/AppBreadcrumbs/AppBreadcrumbs'

type Props = {
  firstName: string,
  lastName: string,
  email: string,
  handleSideBar?: any,
  stateSidebar: boolean
}

const Header: React.FC<Props> = (props) => {
  const classes = uiStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [openAppBar, setOpenAppBar] = React.useState(true)

  const handleToggleSideMenu = (props) => {
    setOpenAppBar(!openAppBar)
    props.handleSideBar()
  }
  const open = Boolean(anchorEl)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    Auth.clear()
    window.location.href = ROUTES.auth.login.path
  }

  return (
    <AppBar position="fixed" className={clsx(classes.appBar, {
      [classes.appBarShift]: props.stateSidebar,
    })} >
      <Toolbar>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu" onClick={() => handleToggleSideMenu(props)}>
          <MenuIcon />
        </IconButton>

        <Box className={classes.title}>
          {/* <SectionName /> */}
          <AppBreadcrumbs />
        </Box>
        <div>
          <Tooltip title="Twój profil">
            <IconButton
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <List component="nav" className={classes.list}>
              <ListItem className={classes.listItem} >
                {
                  props.firstName && props.lastName &&
               
                <ListItemAvatar>
                  <Avatar>{props.firstName && props.firstName[0]}{props.lastName && props.lastName[0]}</Avatar>
                </ListItemAvatar>
                }
                <ListItemText primary={props.firstName ? props.firstName + ' ' + props.lastName : ''} secondary={<a className={classes.link} href={"mailto:" + props.email}>{props.email}</a>} />
              </ListItem>
            </List>
            <Divider />
            <MenuItem
              component={Link}
              onClick={handleClose}
              to={ROUTES.profile.show.path}
            >
              <ListItemIcon className={classes.ListItemIcon}>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Moje Konto" />
            </MenuItem>
            <MenuItem
              component={Link}
              onClick={handleClose}
              to={ROUTES.profile.passwordChange.path}
            >
              <ListItemIcon className={classes.ListItemIcon}>
                <LockIcon />
              </ListItemIcon>
              <ListItemText primary="Zmiana hasła" />
            </MenuItem>
            <Divider />
            <MenuItem
              component={Link}
              onClick={handleLogout}
              to={ROUTES.profile.passwordChange.path}
              >
              <ListItemIcon className={classes.ListItemIcon}>
                <InputIcon />
              </ListItemIcon>
              <ListItemText primary="Wyloguj" />
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state: AppState) => ({
  firstName: state.authUser ? state.authUser.firstName : '',
  lastName: state.authUser ? state.authUser.lastName : '',
  email: state.authUser.email ? state.authUser.email : ''
})

export default connect(mapStateToProps)(Header)