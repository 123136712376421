import { ValidatorForm } from 'react-material-ui-form-validator'

// eslint-disable-next-line
export const passwordRegexp = 'matchRegexp:^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})'
export const passwordErrorMsg = 'Hasło musi liczyć min. 8 znaków i zawierać małe, duże litery, cyfry oraz znak specjalny.'

// eslint-disable-next-line
export const accountNumberRegexp = 'matchRegexp:^[0-9]{2}\ [0-9]{4}\ [0-9]{4}\ [0-9]{4}\ [0-9]{4}\ [0-9]{4}\ [0-9]{4}$'
export const accountNumberErrorMsg = 'Numer rachunku bankowego jest niepoprawny.'

export const nipRegexp = 'matchRegexp:^[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}$$'
export const nipErrorMsg = 'Numer NIP jest nieprawidłowy'

export const phoneNumberErrorMsg = 'Numer telefonu jest nieprawidłowy'

ValidatorForm.addValidationRule('phoneNumberRegexp', (value) => {      
  const regex = new RegExp(/(|(\(\+[0-9]{2}\) [0-9]{3} [0-9]{3} [0-9]{3}))$/y);
  return regex.test(value);
})

export const ExtendedValidatorForm = ValidatorForm