import React from 'react'
import ROUTES from '../../../routes'
import { RouteComponentProps } from 'react-router-dom'

// UI
import uiStyles from '../../../assets/styles/styles'
import clsx from 'clsx'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Paper,
  Box,
  Grid,
  Tabs,
  Tab,
  AppBar,
  Typography,
  FormGroup
} from '@material-ui/core'
import { SuccessAlert, ErrorAlert } from '../../../components/FlashMessage/FlashMessage'
import Loading from '../../../components/Loading/Loading'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { SubmitActionBtn, LoaderBtn, BackActionBtn } from '../../../components/FormActionLinks/FormActionLinks'
import TopBackButton from '../../../components/TopBackButton/TopBackButton'
import { MaskPostcode } from '../../../components/MaskedInputs/MaskedInputs'
import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker'

// GraphQL
import { Mutation, Query } from 'react-apollo'
import { SHOW_CLIENT_QUERY, CLIENT_UPDATE_MUTATION } from '../repositoryGraphQL'


interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  match: any
}

type State = {
  client_id: string
  birthDate: Date | null
  city: string
  postcode: string
  street: string
  tabIndex: number
}

class EditClient extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      client_id: '',
      birthDate: null,
      city: '',
      postcode: '',
      street: '',
      tabIndex: 0
    }
  }

  handleQueryComplete = (data: any) => {
    const { client } = data

    this.setState({
      client_id: client.clientProfile ? client.clientProfile.id : '',
      birthDate: client.clientProfile ? client.clientProfile.birthDate : null,
      city: client.clientProfile ? client.clientProfile.city : '',
      postcode: client.clientProfile ? client.clientProfile.postcode : '',
      street: client.clientProfile ? client.clientProfile.street : '',
    })
  }

  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue
    })
  }

  render() {
    const { classes } = this.props
    const { id } = this.props.match.params
    const { tabIndex, client_id, birthDate, city, postcode, street } = this.state

    const backUrl: string = ROUTES.clients.index.path

    return (
      <>
        <Query
          query={SHOW_CLIENT_QUERY}
          variables={{ id }}
          onCompleted={query_data => this.handleQueryComplete(query_data)}
          fetchPolicy='network-only'
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return <ErrorAlert error={error} />

            const showName = data.client.firstName && data.client.lastName ? data.client.firstName + ' ' + data.client.lastName : data.client.email

            return (
              <Mutation
                mutation={CLIENT_UPDATE_MUTATION}
                variables={{ client_id, birthDate, city, postcode, street }}
                onCompleted={(data: any) => this._confirm(data)}
              >
                {(submitForm: any, { loading, error, data }: any) => (
                  <>
                    <ValidatorForm
                      ref="form"
                      onSubmit={submitForm}
                      onError={(errors: any) => console.log(errors)}
                    >
                      <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TopBackButton
                              url={backUrl}
                              title={`${showName}`}
                              subtitle="Edycja klienta"
                            />
                          </Grid>
                          <Grid item xs={6} className={classes.formAction}>
                            {!loading && <BackActionBtn text={'Anuluj'} to={backUrl} />}
                            {!loading && <SubmitActionBtn text="Zapisz" />}
                            {loading && <LoaderBtn />}
                          </Grid>
                        </Grid>
                      </Paper>
                      <AppBar position="static" color="default" className={classes.menuTabs}>
                        <Tabs
                          value={tabIndex}
                          onChange={this.handleTabChange}
                          variant="fullWidth"
                          indicatorColor="secondary"
                        >
                          <Tab label="Dane Klienta" />
                          {/* <Tab label="Bank" /> */}
                        </Tabs>
                      </AppBar>
                      <Paper className={classes.tabs}>
                        <Box component="div" className={clsx(classes.tab, tabIndex === 0 && classes.tabShow)} padding={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography>
                                Wprowadź dane klienta
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <FormGroup row>
                                <FormDatePicker
                                  label="Data urodzenia"
                                  name="birthDate"
                                  value={birthDate}
                                  className={classes.formControl}
                                  onChange={(date: any) => this.setState({ birthDate: date })}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid className={classes.marginTop} item xs={12}>
                              <Typography>
                                Wprowadź dane adresowe
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="street"
                                name="street"
                                fullWidth
                                id="street"
                                label="Ulica"
                                value={street}
                                onChange={(e: { target: { value: string } }) => this.setState({ street: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}></Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="postcode"
                                name="postcode"
                                fullWidth
                                id="postcode"
                                label="Kod pocztowy"
                                value={postcode}
                                onChange={(e: { target: { value: string } }) => this.setState({ postcode: e.target.value })}
                                InputProps={{
                                  inputComponent: MaskPostcode as any,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="city"
                                name="city"
                                fullWidth
                                id="city"
                                label="Miejscowość"
                                value={city}
                                onChange={(e: { target: { value: string } }) => this.setState({ city: e.target.value })}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    </ValidatorForm>
                    {(!loading && data) && <SuccessAlert />}
                    {error && <ErrorAlert error={error} />}
                    {(data && data.clientUpdate.errors) && <ErrorAlert modelName="client" fieldError={data.clientUpdate.errors} />}
                  </>
                )}
              </Mutation>
            )
          }}
        </Query>
      </>
    )
  }

  _confirm = async (data: any) => {
    if (!data) return

    if (!data.clientUpdate) return

    this.setState({
    })

    this.props.history.push(ROUTES.clients.index.path)
  }
}


export default withStyles(uiStyles)(EditClient)