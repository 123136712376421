// GraphQL
import gql from 'graphql-tag'

export const INDEX_SETTLEMENTS_QUERY  = gql`
  query{
    settlements{
      id
      createdAt
      linkToCsv
      totalAmount
      settled
      generatedFor
      reports {
        id
        restaurant {
          id
          name
        }
      }
    }
  }
`

export const SETTLEMENT_SHOW_QUERY = gql`
query settlement($id: ID!){
  settlement(id: $id){
    id
    createdAt
    generatedFor
    linkToCsv 
    transfers {
      id
      transferableType
      totalAmount
      waiter {
        id
        email
        fullName
        user {
          id
        }
      }
      report {
        id
        restaurant{
          id
          name
        }
      }
    }
    totalAmount
    settled
  }
}
`
export const SETTLEMENT_MARK_SETTLED = gql`
mutation( $id: ID! ){
  settlementMarkSettled(input:{ id: $id }){
    errors {
      field
      messages
    }
  }
}
`

