import React from 'react'
import ROUTES from '../../../routes'
import { RESTAURANT_STATUS } from '../../../constants'
import { Link as LinkRouter } from 'react-router-dom'
import { prepareRouteUrl } from '../../../utils/utils'

import { Tooltip, IconButton, Link } from '@material-ui/core'
import { RemoveRedEye, Edit as EditIcon } from '@material-ui/icons'
import { DataGridTable } from '../../../components/DataGridTable/DataGridTable'
import { RestaurantStatusChip, CustomChip } from '../../../components/CustomChips/CustomChips'
import ActionsRestaurant from '../ShowRestaurant/ActionsRestaurant/ActionsRestaurant'


interface Props {
  rows: Array<{}>
}

interface State {
  rows: any
  columns: Array<{
    field: string
    title: string
    render?: any
    sorting?: boolean
    cellStyle?: any
    headerStyle?: any
    customSort?: any
  }>
}

class DataGridRestaurants extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      columns: [
        { field: 'mainImageUrl', title: '', headerStyle: { maxWidth: '100', textAlign: 'center' },cellStyle: { textAlign: 'center' }, render: row => this.img(row)},
        { field: 'name', title: 'Nazwa', headerStyle: { minWidth: '25%' }, render: row => this.AnchorName(row), customSort: (a, b) => a.name > b.name },
        { field: 'status', title: 'Status', headerStyle: { minWidth: '15%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: row => this.RestaurantStatus(row), customSort: (a,b) => this.StatusSort(a,b) },
        { field: 'information', title: 'Informacje', headerStyle: { minWidth: '20%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: row => this.Status(row), customSort: (a, b) => a.status > b.status },
        { field: 'actions', title: 'Akcje', headerStyle: { minWidth: '20%', textAlign: 'right' }, cellStyle: { textAlign: 'right' }, sorting: false, render: row => this.ActionsLinks(row) }
      ],
      rows: this.props.rows
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return (props.rows !== state.rows) ? { rows: props.rows } : null
  }

  img = row =>  (
    row.mainImageUrl &&  <img style={{maxWidth: 100, maxHeight: 60}} alt={row.name} src={row.mainImageUrl} />
  )

  AnchorName = row => {

    const showUrl: string = prepareRouteUrl(ROUTES.restaurants.show.path, { id: row.id })

    return <Link aria-label="Show" component={LinkRouter} to={showUrl}>{row.name}</Link>
  }

  RestaurantStatus = row => <RestaurantStatusChip noMargin restaurantStatus={row.status} />

  StatusSort = (a, b) => RESTAURANT_STATUS[a.status].text > RESTAURANT_STATUS[b.status].text

  ActionsLinks = row => {

    let actions: Array<any> = []

    const showUrl: string = prepareRouteUrl(ROUTES.restaurants.show.path, { id: row.id })
    const editUrl: string = prepareRouteUrl(ROUTES.restaurants.edit.path, { id: row.id })

    actions.push(
      <Tooltip key={`ShowId-${row.id}`} title="Podgląd">
        <IconButton aria-label="Show" size="medium" color="primary" component={LinkRouter} to={showUrl}>
          <RemoveRedEye fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )
    actions.push(
      <ActionsRestaurant
        iconButton
        key={`LoginId-${row.id}`}
        id={row.id}
        name={row.name}
        type="restaurantLoginAdmin"
      />
    )

    actions.push(
      <Tooltip key={`EditId-${row.id}`} title="Edycja">
      <IconButton aria-label="Edit" size="medium" component={LinkRouter} to={editUrl}>
        <EditIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
    )

    return actions
  }


  Status = (row) => {
    const status: Array<any> = []

    if(row.blocked) status.push(<CustomChip key={`Blocked${row.id}`} noMargin txtActive={row.blockedReason.name} />)
    if(row.status === 'need_activation') status.push(<CustomChip  key={`Status-${row.id}`} noMargin txtActive={"Wymaga aktywacji"} />)

    return status
  }

  render() {

    const {
      rows, columns
    } = this.state

    return (

      <DataGridTable columns={columns} rows={rows} />

    )
  }
}

export default DataGridRestaurants