import React from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import ROUTES from '../../../../routes'
import { prepareRouteUrl } from '../../../../utils/utils'

import { IconButton, Tooltip, Link } from '@material-ui/core'
import { RemoveRedEye } from '@material-ui/icons'
import { DataGridTable } from '../../../../components/DataGridTable/DataGridTable'
import { FormattedDate, FormattedPrice } from '../../../../components/FormattedData/FormattedData'
import { OrderStatusChip } from '../../../../components/CustomChips/CustomChips'

interface Props {
  rows: Array<{}>
  search?: boolean
  edit?: boolean
  onChanged?: any
  status?: any
}

interface State {
  rows: any
  columns: Array<{
    field: string
    title: string
    render?: any,
    cellStyle?: any,
    headerStyle?: any
    customSort?:any
  }>
}

class DataGridTransfers extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      columns: [
        { field: 'name', title: 'Nazwa', headerStyle: { minWidth: '20%' }, render: row => this.toLink(row)},
        { field: 'totalAmount', title: 'Kwota', headerStyle: { minWidth: '10%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: row => <FormattedPrice value={row.totalAmount} />},
        { field: 'actions', title: 'Akcje', headerStyle: { minWidth: '20%', textAlign: 'right' }, cellStyle: { textAlign: 'right' }, render: row => this.ActionsLinks(row) }
      ],
      rows: this.props.rows
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return (props.rows !== state.rows) ? { rows: props.rows } : null
  }

  toLink = (row) => {
    let showUrl: string;

    if(row.transferableType === "Waiter")
      showUrl = prepareRouteUrl(ROUTES.waiters.show.path, { id: row.waiter.user.id })
    else
      showUrl = prepareRouteUrl(ROUTES.reports.show.path, { id: row.report.id })

    return (<Link target="_blank" aria-label="Show" component={LinkRouter} to={showUrl}>{row.transferableType === "Waiter" ? row.waiter.fullName ? row.waiter.fullName : row.waiter.email : row.report.restaurant.name}</Link>) 
  }

  OrderStatus = (status) => <OrderStatusChip noMargin orderStatus={status} />

  FormatData = (row) => <FormattedDate format="DD.MM.YYYY HH:MM" value={row} />

  ActionsLinks = (row) => {

    let actions: Array<any> = []
    let showUrl: string;

    if(row.transferableType === "Waiter")
      showUrl = prepareRouteUrl(ROUTES.waiters.show.path, { id: row.waiter.id })
    else
      showUrl = prepareRouteUrl(ROUTES.reports.show.path, { id: row.report.id })

    actions.push(
      <Tooltip key={`ShowId-${row.id}`} title="Podgląd">
        <IconButton aria-label="Show" size="medium" color="primary" component={LinkRouter} to={showUrl}>
          <RemoveRedEye fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )

    return actions
  }

  render() {

    const {
      rows, columns
    } = this.state

    return (

      <DataGridTable search={this.props.search} columns={columns} rows={rows} />

    )
  }
}

export default DataGridTransfers