import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'flex',
      padding: 0,
      height: 'auto',
    },
    valueContainer: {
      display: 'flex',
      // flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    option: {
      fontSize: '1em',
    },
    singleValue: {
      fontSize: '1em',
      whiteSpace: 'nowrap',
      color: theme.palette.primary.main
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      bottom: 6,
      fontSize: '1em',
    },
    paper: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      width: '30em'
    }
  })
)

export default uiStyles