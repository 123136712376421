import React from "react";
import ROUTES from "../../../routes";
import { prepareRouteUrl } from "../../../utils/utils";

// UI
import uiStyles from "../../../assets/styles/styles";
import clsx from "clsx";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Divider,
  Typography,
  InputLabel,
  Tooltip,
  Fab,
  Box,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import TopBackButton from "../../../components/TopBackButton/TopBackButton";
import EditIcon from "@material-ui/icons/Edit";
import { Link as LinkRouter } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import {
  ErrorAlert,
  SuccessAlert,
} from "../../../components/FlashMessage/FlashMessage";
import { EmailLink } from "../../../components/AppLinks/AppLinks";
import {
  FormattedDate,
  FormattedPrice,
} from "../../../components/FormattedData/FormattedData";
import { ActiveToggleChip } from "../../../components/CustomChips/CustomChips";

// GraphQL
import { Mutation, Query } from "react-apollo";
import { SHOW_CLIENT_QUERY } from "../repositoryGraphQL";
import { DataGridTable } from "../../../components/DataGridTable/DataGridTable";
import { SubmitBtn } from "../../../components/FormActionLinks/FormActionLinks";
import { MARK_PHONE_VERIFIED_MUTATION } from "../../Users/repositoryGraphQL";

interface Props extends WithStyles<typeof uiStyles> {
  match: any;
}

type State = {
  tabIndex: number;
};

class ShowWaiter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabIndex: 0,
    };
  }

  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue,
    });
  };

  render() {
    const { classes } = this.props;
    const { id } = this.props.match.params;
    const { tabIndex } = this.state;

    const columns = [
      { field: "name", title: "Nazwa" },
      { field: "description", title: "Opis" },
      {
        field: "expirationDate",
        title: "Data wygaśnięcia",
        render: (row) => (
          <FormattedDate
            format="DD.MM.YYYY HH:mm:ss"
            value={row.expirationDate}
          />
        ),
      },
      { field: "used", title: "Status", render: (row) => ShowStatus(row) },
      {
        field: "value",
        title: "Rabat",
        headerStyle: { minWidth: "35%", textAlign: "center" },
        cellStyle: { textAlign: "center" },
        render: (row) => <FormattedPrice value={row.value} />,
      },
    ];

    const ShowStatus = (row) => (
      <ActiveToggleChip
        noMargin
        txtActive="Aktywny"
        txtDeactive="Wykorzystany"
        status={!row.used}
      />
    );

    return (
      <Query
        query={SHOW_CLIENT_QUERY}
        variables={{ id }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorAlert error={error} />;

          const { client } = data;

          const backUrl: string = ROUTES.clients.index.path;
          const editUrl: string = prepareRouteUrl(ROUTES.clients.edit.path, {
            id: client.id,
          });

          const showName =
            client.firstName && client.clientProfile.lastName
              ? client.firstName + " " + client.clientProfile.lastName
              : client.email;

          return (
            <>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Grid item xs={6}>
                      <TopBackButton
                        url={backUrl}
                        title={`${showName}`}
                        subtitle="Dane klienta"
                      />
                    </Grid>
                    <Tooltip title="Edycja">
                      <Fab
                        aria-label="Edit"
                        size="medium"
                        className={classes.fab_edit}
                        color="secondary"
                        component={LinkRouter}
                        to={editUrl}
                      >
                        <EditIcon />
                      </Fab>
                    </Tooltip>
                  </Grid>
                  <Grid item sm={6}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Kryptonim
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="div"
                      className={classes.typography}
                    >
                      {client.pseudonym || " - "}
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Adres
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.typography}
                    >
                      {client.clientProfile.street &&
                        `ul. ${client.clientProfile.street}`}
                      <br />
                      {client.clientProfile.postcode &&
                        client.clientProfile.city &&
                        `${client.clientProfile.postcode} ${client.clientProfile.city}`}
                    </Typography>
                  </Grid>
                  <ActiveToggleChip status={client.activationState} />
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Imię i nazwisko
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="div"
                      className={classes.typography}
                    >
                      {client.firstName || client.lastName
                        ? `${client.firstName} ${client.lastName}`
                        : " - "}
                    </Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <InputLabel shrink className={classes.inputLabel}>
                      E-mail
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.typography}
                    >
                      <EmailLink value={client.email} />
                    </Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Numer telefonu
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="div"
                      className={classes.typography}
                    >
                      {client.phone ? client.phone : " - "}
                      <ActiveToggleChip
                        status={client.phoneVerified}
                        txtActive="Zweryfikowany"
                        txtDeactive="Niezweryfikowany"
                      />
                      {!client.phoneVerified && (
                        <Mutation
                          mutation={MARK_PHONE_VERIFIED_MUTATION}
                          variables={{ id: client.id }}
                          onCompleted={refetch}
                        >
                          {(submitForm: any, { loading, error, data }: any) => (
                            <Typography
                              style={{
                                marginLeft: 10,
                                display: "inline-block",
                              }}
                            >
                              <SubmitBtn
                                onClick={submitForm}
                                text="Zweryfikuj"
                                disabled={loading}
                              />

                              {!loading && data && (
                                <SuccessAlert message="Operacja zakończona powodzeniem." />
                              )}
                              {error && <ErrorAlert error={error} />}
                            </Typography>
                          )}
                        </Mutation>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <AppBar
                position="static"
                color="default"
                className={classes.menuTabs}
              >
                <Tabs
                  value={tabIndex}
                  onChange={this.handleTabChange}
                  variant="fullWidth"
                  indicatorColor="secondary"
                >
                  <Tab label="Dane klienta" />
                  <Tab label="Kody rabatowe" />
                </Tabs>
              </AppBar>
              <Paper className={classes.tabs}>
                <Box
                  component="div"
                  className={clsx(
                    classes.tab,
                    tabIndex === 0 && classes.tabShow
                  )}
                  padding={4}
                >
                  <Grid container>
                    <Grid item sm={4}>
                      <InputLabel shrink className={classes.inputLabel}>
                        Data urodzenia
                      </InputLabel>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.typography}
                      >
                        {client.clientProfile.birthDate && (
                          <FormattedDate
                            value={client.clientProfile.birthDate}
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <Grid container spacing={2}>
                    <Grid item sm={4}>
                      <InputLabel shrink className={classes.inputLabel}>
                        Utworzony
                      </InputLabel>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.typography}
                      >
                        {client.clientProfile.createdAt && (
                          <FormattedDate
                            value={client.clientProfile.createdAt}
                          />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <InputLabel shrink className={classes.inputLabel}>
                        Ostatnia aktywność
                      </InputLabel>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.typography}
                      >
                        {client.lastActivityAt && (
                          <FormattedDate value={client.lastActivityAt} />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item sm={4}>
                      <InputLabel shrink className={classes.inputLabel}>
                        Ostatnie IP
                      </InputLabel>
                      <Typography
                        variant="body2"
                        component="p"
                        className={classes.typography}
                      >
                        {client.lastLoginFromIpAddress}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {/* Kody rabatowe */}
                <Box
                  component="div"
                  className={clsx(
                    classes.tab,
                    tabIndex === 1 && classes.tabShow
                  )}
                  padding={4}
                >
                  <Grid container>
                    <Grid item sm={12}>
                      {client.discountCodes &&
                      client.discountCodes.length > 0 ? (
                        <DataGridTable
                          columns={columns}
                          rows={client.discountCodes}
                        />
                      ) : (
                        "Brak kodów rabatowych"
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(uiStyles)(ShowWaiter);
