import React from 'react'

// UI
import {
  Grid,
} from '@material-ui/core'

// GraphQL
import { Mutation } from 'react-apollo'
import { CREATE_SETTLEMENT_QUERY } from '../repositoryGraphQL'
import { SubmitActionBtn, LoaderBtn } from '../../../components/FormActionLinks/FormActionLinks'
import { SuccessAlert, ErrorAlert } from '../../../components/FlashMessage/FlashMessage'


type Props = {
  reportIds: Array<any>
  onCompleted: any
}

const CreateSettlement: React.FC<Props> = ({ reportIds, onCompleted }) => {

  return (
    <Mutation
      mutation={CREATE_SETTLEMENT_QUERY}
      variables={{reportIds}}
      onCompleted={onCompleted}
    >
      {(submitForm: any, { loading, error, data }: any) => {

        return (
          <form noValidate autoComplete="off" onSubmit={e => { e.preventDefault(); submitForm() }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                {!loading && <SubmitActionBtn disabled={reportIds.length > 0 ? false : true} text="Generuj Rozliczenie" />}
                {loading && <LoaderBtn />}
              </Grid>
            </Grid>
            {(!loading && data) && <SuccessAlert message="Utworzono pomyślnie." />}
            {error && <ErrorAlert error={error} />}
            {(data && data.reportsCreate) && <ErrorAlert modelName="report" fieldError={data.settlementCreate.errors} />}
          </form>
        )
      }}
    </Mutation>
  )
}

export default CreateSettlement