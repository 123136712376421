import React from 'react'

import { DataGridTable } from '../../../../components/DataGridTable/DataGridTable'
import ShowMenuItem from '../ShowMenuItem/ShowMenuItem'
import { ActiveToggleChip } from '../../../../components/CustomChips/CustomChips'
import { FormattedPrice } from '../../../../components/FormattedData/FormattedData'

interface Props  {
  rows: any
}

interface State {
  rows: any
  columns: Array<{
    field: string
    title: string
    render?: any,
    cellStyle?: any,
    headerStyle?: any
    defaultGroupOrder?: any
    customSort?: any
  }>
}

class DataGridMenuItems extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      columns: [
        { field: 'category', title: 'Kategoria', defaultGroupOrder: 0, headerStyle: { minWidth: '20%' }},
        { field: 'imageUrl', title: '', headerStyle: { maxWidth: '100', textAlign: 'center' },cellStyle: { textAlign: 'center' }, render: row => this.img(row)},
        { field: 'name', title: 'Nazwa', headerStyle: { minWidth: '20%' }},
        { field: 'price', title: 'Cena', headerStyle: { minWidth: '15%' }, render: (row) => this.ShowPrice(row)},
        { field: 'public', title: 'Status', headerStyle: { minWidth: '20%' }, customSort: (row) => row.public,  render: (row) => this.ShowStatus(row)},
        { field: 'actions', title: 'Akcje', headerStyle: { minWidth: '25%', textAlign: 'right' }, cellStyle: { textAlign: 'right' }, render: row => this.ActionsLinks(row) }
      ],
      rows: this.props.rows
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return (props.rows !== state.rows) ? { rows: props.rows } : null
  }

  img = row =>  (
    row.imageUrl  &&  <img style={{maxWidth: 100, maxHeight: 60}} alt={row.name} src={row.imageUrl} />
  )
    
  ShowStatus = (row) => (
    <ActiveToggleChip noMargin txtActive='Aktywna' txtDeactive='Nieaktywna' status={row.public} />
  )

  ShowPrice = (row) => row.price === 0 ? 'Brak' : <FormattedPrice value={row.price} />


  ActionsLinks = (row) => {

    let actions: Array<any> = []

    actions.push(<ShowMenuItem key={`ShowId-${row.id}`} row={row} />)

    return actions
  }

  render() {

    const {
      rows, columns
    } = this.state

    return (

      <DataGridTable columns={columns} rows={rows} />

    )
  }
}

export default DataGridMenuItems