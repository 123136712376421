import React from 'react'
import { RESTAURANT_STATUS, ORDER_STATUS } from '../../constants'

// UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginLeft: theme.spacing(2)
    },
  }),
)

interface Props {
  status?: boolean
  restaurantStatus?: string
  type?: string
  txtActive?: string | null 
  txtDeactive?: string | null
  noMargin?: boolean | null
  orderStatus?: string
}


export const CustomChip: React.FC<Props> = (props) => {

  const classes = uiStyles()

  return(
      <Chip style={{ backgroundColor: '#e23425', color: '#fff' }} size="small" label={props.txtActive ? props.txtActive : 'Aktywny'} color="primary" className={!props.noMargin ? classes.chip : ''} />
  )
}

export const ActiveToggleChip: React.FC<Props> = (props) => {

  const classes = uiStyles()

  return(
    props.status ?
      <Chip size="small" label={props.txtActive ? props.txtActive : 'Aktywny'} color="primary" className={!props.noMargin ? classes.chip : ''} />
      :
      <Chip style={{ backgroundColor: '#e23425', color: '#fff' }} size="small" label={props.txtDeactive ? props.txtDeactive : 'Nieaktywny' } className={!props.noMargin ? classes.chip : ''} />
  )
}

export const RestaurantStatusChip: React.FC<Props> = (props) => {

  const classes = uiStyles()

  return(
    props.restaurantStatus ? 
      <Chip style={{ backgroundColor: RESTAURANT_STATUS[props.restaurantStatus].color, color: '#fff' }} size="small" label={RESTAURANT_STATUS[props.restaurantStatus].text} className={!props.noMargin ? classes.chip : ''} />
      :
      <>Błąd</>
  )
}

export const OrderStatusChip: React.FC<Props> = (props) => {

  const classes = uiStyles()

  return(
    props.orderStatus ?
      <Chip size="small" label={ORDER_STATUS[props.orderStatus].text} style={{backgroundColor: ORDER_STATUS[props.orderStatus].color, color: '#fff'}} className={!props.noMargin ? classes.chip : ''} />
    : <> - </>
  )
}


export const SettlementTypeChip: React.FC<Props> = (props) => {

  const classes = uiStyles()

  return(
    props.type === "waiters" ?
      <Chip size="small" label={"Kelnerzy"} style={{ backgroundColor: '#f5d442', color: '#000' }} className={!props.noMargin ? classes.chip : ''} />
      :
      <Chip style={{ backgroundColor: '#42b0f5', color: '#000' }} size="small" label={"Restauracje"} className={!props.noMargin ? classes.chip : ''} />
  )
}