// GraphQL
import gql from 'graphql-tag'

export const SHOW_USER_QUERY = gql`
  query{
    viewer{
      id
      email
      createdAt
      firstName
      lastName
      restaurant {
        name
      }
    }
  }
`
export const UPDATE_USER_MUTATION = gql`
  mutation($id: ID!, $email: String!, $firstName: String, $lastName: String){
    userUpdate(input:{id: $id, email: $email, firstName: $firstName, lastName: $lastName }){
      errors {
        field
        messages
      }
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`

export const USER_PASSWORD_UPDATE_MUTATION = gql`
  mutation($id: ID!, $oldPassword: String!, $newPassword: String!){
    userPasswordUpdate(input:{userId: $id, oldPassword: $oldPassword, newPassword: $newPassword}){
      errors {
        field
        messages
      }
      user {
        id
      }
    }
  }
`