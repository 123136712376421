import React from "react";
import ROUTES from "../../../routes";
import { prepareRouteUrl } from "../../../utils/utils";

// UI
import uiStyles from "../../../assets/styles/styles";
import clsx from "clsx";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Divider,
  Typography,
  InputLabel,
  Tooltip,
  Fab,
  Box,
  Chip,
  AppBar,
  Tabs,
  Tab,
  Link,
} from "@material-ui/core";
import TopBackButton from "../../../components/TopBackButton/TopBackButton";
import EditIcon from "@material-ui/icons/Edit";
import { Link as LinkRouter } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import {
  ErrorAlert,
  SuccessAlert,
} from "../../../components/FlashMessage/FlashMessage";
import { FormattedDate } from "../../../components/FormattedData/FormattedData";
import { ActiveToggleChip } from "../../../components/CustomChips/CustomChips";
import DataGridRestaurants from "../../Restaurants/DataGridRestaurants/DataGridRestaurants";
import DataGridEmpty from "../../../components/DataGridEmpty/DataGridEmpty";

// GraphQL
import { Mutation, Query } from "react-apollo";
import {
  MARK_PHONE_VERIFIED_MUTATION,
  SHOW_USER_QUERY,
} from "../repositoryGraphQL";
import { SubmitBtn } from "../../../components/FormActionLinks/FormActionLinks";

interface Props extends WithStyles<typeof uiStyles> {
  match: any;
}

type State = {
  tabIndex: number;
  anchorE: null | HTMLElement;
};

class ShowUser extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tabIndex: 0,
      anchorE: null,
    };
  }

  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue,
    });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorE: event.currentTarget,
    });
  };

  render() {
    const { classes } = this.props;
    const { id } = this.props.match.params;
    const { tabIndex } = this.state;

    return (
      <Query
        query={SHOW_USER_QUERY}
        variables={{ id }}
        onCompleted={(data) => console.log(data)}
        fetchPolicy="network-only"
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loading />;
          if (error) return <ErrorAlert error={error} />;

          const { user } = data;

          const backUrl: string = ROUTES.users.index.path;
          const editUrl: string = prepareRouteUrl(ROUTES.users.edit.path, {
            id: user.id,
          });

          const showName =
            user.firstName && user.lastName
              ? user.firstName + " " + user.lastName
              : user.email;

          const FullName = (row) => {
            const showUrl: string = prepareRouteUrl(ROUTES.users.show.path, {
              id: row.id,
            });

            if (!row) return "-";
            const { firstName, lastName, email, pseudonym } = row;
            const fullName =
              firstName && lastName ? `${firstName} ${lastName}` : pseudonym;

            return (
              <Link aria-label="Show" component={LinkRouter} to={showUrl}>
                {fullName}
                <br />
                {email}
              </Link>
            );
          };

          return (
            <>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Grid item xs={6}>
                      <TopBackButton
                        url={backUrl}
                        title={`${showName}`}
                        subtitle="Dane użytkownika"
                      />
                    </Grid>
                    <Tooltip title="Edycja">
                      <Fab
                        aria-label="Edit"
                        size="medium"
                        className={classes.fab_edit}
                        color="secondary"
                        component={LinkRouter}
                        to={editUrl}
                      >
                        <EditIcon />
                      </Fab>
                    </Tooltip>
                  </Grid>
                  <Grid item sm={4}>
                    <InputLabel shrink className={classes.inputLabel}>
                      E-mail
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="div"
                      className={classes.typography}
                    >
                      {user.email}
                    </Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Imię i nazwisko
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="div"
                      className={classes.typography}
                    >
                      {user.firstName || user.lastName
                        ? `${user.firstName} ${user.lastName}`
                        : " - "}
                    </Typography>
                  </Grid>
                  <Grid item sm={4}></Grid>
                  <ActiveToggleChip status={user.activationState} />
                  {user.admin && (
                    <Chip
                      style={{ backgroundColor: "#e23425" }}
                      size="small"
                      label="Administrator"
                      color="primary"
                      className={classes.chip}
                    />
                  )}
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Utworzony
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.typography}
                    >
                      {user.createdAt && (
                        <FormattedDate value={user.createdAt} />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Aktulizowany
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.typography}
                    >
                      {user.updatedAt && (
                        <FormattedDate value={user.updatedAt} />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Ostatnie logowanie
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.typography}
                    >
                      {user.lastLoginAt && (
                        <FormattedDate value={user.lastLoginAt} />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Ostatnia aktywność
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.typography}
                    >
                      {user.lastActivityAt && (
                        <FormattedDate value={user.lastActivityAt} />
                      )}
                    </Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Ostatni adres IP
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.typography}
                    >
                      {user.lastLoginFromIpAddress}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Z polecenia
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.typography}
                    >
                      <ActiveToggleChip
                        noMargin
                        txtActive="Tak"
                        txtDeactive="Nie"
                        status={user.recommendingUser}
                      />
                      &nbsp;
                      {user.recommendingUser && FullName(user.recommendingUser)}
                    </Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <InputLabel shrink className={classes.inputLabel}>
                      Liczba poleconych
                    </InputLabel>
                    <Typography
                      variant="body2"
                      component="p"
                      className={classes.typography}
                    >
                      {user.recommendedUsers && user.recommendedUsers.length}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <AppBar
                position="static"
                color="default"
                className={classes.menuTabs}
              >
                <Tabs
                  value={tabIndex}
                  onChange={this.handleTabChange}
                  variant="fullWidth"
                  indicatorColor="secondary"
                >
                  {user.restaurants && <Tab label="Restauracje" />}
                  {user.waiterProfile && <Tab label="Profil Kelnera" />}
                  {user.clientProfile && <Tab label="Profil Klienta" />}
                </Tabs>
              </AppBar>
              <Paper className={classes.tabs}>
                {user.restaurants && (
                  <Box
                    component="div"
                    className={clsx(
                      classes.tab,
                      tabIndex === 0 && classes.tabShow
                    )}
                    padding={4}
                  >
                    <Grid container>
                      <Grid item sm={12}>
                        <Typography
                          className={classes.marginTop}
                          variant="body2"
                          component="div"
                        >
                          {user.restaurants.length > 0 ? (
                            <DataGridRestaurants rows={user.restaurants} />
                          ) : (
                            <DataGridEmpty />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {user.waiterProfile && (
                  <Box
                    component="div"
                    className={clsx(
                      classes.tab,
                      tabIndex === (user.restaurants ? 1 : 0) && classes.tabShow
                    )}
                    padding={4}
                  >
                    <Grid container>
                      <Grid item sm={6}>
                        <InputLabel shrink className={classes.inputLabel}>
                          Numer telefonu
                        </InputLabel>
                        <Typography
                          variant="body2"
                          component="div"
                          className={classes.typography}
                        >
                          {user.waiterProfile.phone
                            ? user.waiterProfile.phone
                            : " - "}
                        </Typography>
                      </Grid>

                      <Grid item sm={6}>
                        <InputLabel shrink className={classes.inputLabel}>
                          Data urodzenia
                        </InputLabel>
                        <Typography
                          variant="body2"
                          component="div"
                          className={classes.typography}
                        >
                          {user.waiterProfile.birthDate && (
                            <FormattedDate
                              value={user.waiterProfile.birthDate}
                            />
                          )}
                          {!user.waiterProfile.birthDate && "-"}
                        </Typography>
                      </Grid>
                      <Grid item sm={12}>
                        <InputLabel shrink className={classes.inputLabel}>
                          Adres
                        </InputLabel>
                        <Typography
                          variant="body2"
                          component="p"
                          className={classes.typography}
                        >
                          {user.waiterProfile.street &&
                            `ul. ${user.waiterProfile.street}`}
                          <br />
                          {user.waiterProfile.postcode &&
                            user.waiterProfile.city &&
                            `${user.waiterProfile.postcode} ${user.waiterProfile.city}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {user.clientProfile && (
                  <Box
                    component="div"
                    className={clsx(
                      classes.tab,
                      tabIndex ===
                        (user.restaurants
                          ? user.waiterProfile
                            ? 2
                            : 1
                          : user.waiterProfile
                          ? 1
                          : 0) && classes.tabShow
                    )}
                    padding={4}
                  >
                    <Grid container>
                      <Grid item sm={6}>
                        <InputLabel shrink className={classes.inputLabel}>
                          Numer telefonu
                        </InputLabel>
                        <Typography
                          variant="body2"
                          component="div"
                          className={classes.typography}
                        >
                          {user.phone ? user.phone : " - "}
                          <ActiveToggleChip
                            status={user.phoneVerified}
                            txtActive="Zweryfikowany"
                            txtDeactive="Niezweryfikowany"
                          />
                          {!user.phoneVerified && (
                            <Mutation
                              mutation={MARK_PHONE_VERIFIED_MUTATION}
                              variables={{ id: user.id }}
                              onCompleted={refetch}
                            >
                              {(
                                submitForm: any,
                                { loading, error, data }: any
                              ) => (
                                <Typography
                                  style={{
                                    marginLeft: 10,
                                    display: "inline-block",
                                  }}
                                >
                                  <SubmitBtn
                                    onClick={submitForm}
                                    text="Zweryfikuj"
                                    disabled={loading}
                                  />

                                  {!loading && data && (
                                    <SuccessAlert message="Operacja zakończona powodzeniem." />
                                  )}
                                  {error && <ErrorAlert error={error} />}
                                </Typography>
                              )}
                            </Mutation>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <InputLabel shrink className={classes.inputLabel}>
                          Data urodzenia
                        </InputLabel>
                        <Typography
                          variant="body2"
                          component="div"
                          className={classes.typography}
                        >
                          {user.clientProfile.birthDate && (
                            <FormattedDate
                              value={user.clientProfile.birthDate}
                            />
                          )}
                          {!user.clientProfile.birthDate && "-"}
                        </Typography>
                      </Grid>
                      <Grid item sm={6}>
                        <InputLabel shrink className={classes.inputLabel}>
                          Adres
                        </InputLabel>
                        <Typography
                          variant="body2"
                          component="p"
                          className={classes.typography}
                        >
                          {user.clientProfile.street &&
                            `ul. ${user.clientProfile.street}`}
                          <br />
                          {user.clientProfile.postcode &&
                            user.clientProfile.city &&
                            `${user.clientProfile.postcode} ${user.clientProfile.city}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Paper>
            </>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(uiStyles)(ShowUser);
