import React from 'react'
import ROUTES from '../../../routes'
import { RouteComponentProps } from 'react-router-dom'

// UI
import uiStyles from '../../../assets/styles/styles'
import clsx from 'clsx'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Paper,
  Box,
  Grid,
  Tabs,
  Tab,
  AppBar,
  Typography,
  Divider,
  FormHelperText, 
  FormControlLabel,
  Switch
} from '@material-ui/core'
import { SuccessAlert, ErrorAlert } from '../../../components/FlashMessage/FlashMessage'
import Loading from '../../../components/Loading/Loading'
import { TextValidator } from 'react-material-ui-form-validator'
import {
  nipRegexp,
  nipErrorMsg,
  ExtendedValidatorForm as ValidatorForm
} from '../../../utils/validators'
import { SubmitActionBtn, LoaderBtn, BackActionBtn } from '../../../components/FormActionLinks/FormActionLinks'
import TopBackButton from '../../../components/TopBackButton/TopBackButton'
import { MaskPostcode, MaskNIP, MaskAccountNumber } from '../../../components/MaskedInputs/MaskedInputs'
import ActionsRestaurant from '../ShowRestaurant/ActionsRestaurant/ActionsRestaurant'
import { GooglePlaceAutocomplite } from '../../../components/GooglePlaceAutocomplite/GooglePlaceAutocomplite'

// GraphQL
import { Mutation, Query } from 'react-apollo'
import { SHOW_RESTAURANT_QUERY, RESTAURANT_UPDATE_MUTATION } from '../repositoryGraphQL'


interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  match: any
}

type State = {
  // id: string
  accountNumber: string
  accountCountryCode: string
  city: string
  contactEmail: string
  contactFullName: string
  contactPhone: string
  email: string
  fvEmail: string
  name: string
  address: any
  nip: string
  phone: string
  postcode: string
  street: string
  lat: number
  lng: number
  www: string
  tableDeliveryPossible: boolean
  tabIndex: number
  errorMessage: string
}

class EditRestaurant extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      // id: '',
      accountNumber: '',
      accountCountryCode: '',
      city: '',
      contactEmail: '',
      contactFullName: '',
      contactPhone: '',
      email: '',
      fvEmail: '',
      name: '',
      nip: '',
      phone: '',
      postcode: '',
      www: '',
      street: '',
      address: '',
      lat: 0,
      lng: 0,
      tabIndex: 0,
      errorMessage: '',
      tableDeliveryPossible: false
    }
  }

  handleQueryComplete = (data: any) => {
    const { restaurant } = data

    this.setState({
      // id: restaurant.id,
      accountNumber: restaurant.accountNumber ? restaurant.accountNumber : '',
      accountCountryCode: restaurant.accountCountryCode ? restaurant.accountCountryCode : '',
      city: restaurant.city ? restaurant.city : '',
      contactEmail: restaurant.contactEmail ? restaurant.contactEmail : '',
      contactFullName: restaurant.contactFullName ? restaurant.contactFullName : '',
      contactPhone: restaurant.contactPhone ? restaurant.contactPhone : '',
      email: restaurant.email ? restaurant.email : '',
      fvEmail: restaurant.fvEmail ? restaurant.fvEmail : '',
      name: restaurant.name ? restaurant.name : '',
      nip: restaurant.nip ? restaurant.nip : '',
      phone: restaurant.phone ? restaurant.phone : '',
      postcode: restaurant.postcode ? restaurant.postcode : '',
      street: restaurant.street ? restaurant.street : '',
      address: restaurant.street && restaurant.city ? `${restaurant.street}, ${restaurant.city}, Polska` : '',
      lat: restaurant.lat ? restaurant.lat : 0,
      lng: restaurant.lng ? restaurant.lng : 0,
      www: restaurant.www ? restaurant.www : '',
      tableDeliveryPossible: restaurant.tableDeliveryPossible ? restaurant.tableDeliveryPossible : false
    })
  }

  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue
    })
  }

  getAdressDetails(address) {

    let postcode: string = '',
      street: string = '',
      street_number: string = '',
      city: string = ''

    address.forEach(item => {
      if (item.types[0] === 'postal_code') postcode = item.long_name
      if (item.types[0] === 'route') street = item.long_name
      if (item.types[0] === 'street_number') street_number = item.long_name
      if (item.types[0] === 'locality') city = item.long_name
    })

    if (postcode) this.setState({ postcode })
    if (street) this.setState({ street: `${street} ${street_number}` })
    if (city) this.setState({ city })
  }

  render() {
    const { classes } = this.props
    const { id } = this.props.match.params
    const { accountNumber, accountCountryCode, city, contactEmail, contactFullName, contactPhone, email, fvEmail, name, nip, phone, postcode, street, address, lat, lng, www, tabIndex, errorMessage, tableDeliveryPossible } = this.state

    const backUrl: string = ROUTES.restaurants.index.path

    return (
      <>
        <Query
          query={SHOW_RESTAURANT_QUERY}
          variables={{ id }}
          onCompleted={query_data => this.handleQueryComplete(query_data)}
          fetchPolicy='network-only'
        >
          {({ loading, error, data: Qdata, refetch }) => {
            if (loading) return <Loading />
            if (error) return <ErrorAlert error={error} />
            return (
              <Mutation
                mutation={RESTAURANT_UPDATE_MUTATION}
                variables={{ id, accountNumber, accountCountryCode, city, contactEmail, contactFullName, contactPhone, email, fvEmail, name, nip, phone, postcode, street, lat, lng, www, tableDeliveryPossible }}
                onCompleted={(data: any) => this._confirm(data)}
              >
                {(submitForm: any, { loading, error, data }: any) => (
                  <>
                    <ValidatorForm
                      ref="form"
                      onSubmit={submitForm}
                      onError={(errors: any) => this.setState({ errorMessage: errors[0].props.errorMessages[0] })}
                    >
                      {
                        errorMessage && <ErrorAlert message={errorMessage} />
                      }
                      <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TopBackButton
                              url={backUrl}
                              title={`${name}`}
                              subtitle="Edycja restauracji"
                            />
                          </Grid>
                          <Grid item xs={6} className={classes.formAction}>
                            {!loading && <BackActionBtn text={'Anuluj'} to={backUrl} />}
                            {!loading && <SubmitActionBtn text="Zapisz" />}
                            {loading && <LoaderBtn />}
                          </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                          <Grid item sm={6}>
                            <Box className={classes.actions_links}>
                              {
                                !Qdata.restaurant.activated &&
                                <ActionsRestaurant
                                  id={id}
                                  name={name}
                                  onCompleted={refetch}
                                  type="restaurantActivate"
                                />
                              }
                              {
                                !Qdata.restaurant.blocked ?
                                  <ActionsRestaurant
                                    id={id}
                                    name={name}
                                    onCompleted={refetch}
                                    type="restaurantBlock"
                                  />
                                  :
                                  <ActionsRestaurant
                                    id={id}
                                    name={name}
                                    onCompleted={refetch}
                                    type="restaurantUnblock"
                                  />
                              }
                              <ActionsRestaurant
                                id={id}
                                name={name}
                                onCompleted={() => this.props.history.push(backUrl)}
                                type="restaurantDestroy"
                              />
                              <ActionsRestaurant
                                id={id}
                                name={name}
                                onCompleted={refetch}
                                type="restaurantLoginAdmin"
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                      <AppBar position="static" color="default" className={classes.menuTabs}>
                        <Tabs
                          value={tabIndex}
                          onChange={this.handleTabChange}
                          variant="fullWidth"
                          indicatorColor="secondary"
                        >
                          <Tab label="Dane Kontaktowe" />
                          <Tab label="Dane Firmowe" />
                          <Tab label="Dane Bankowe" />
                        </Tabs>
                      </AppBar>
                      <Paper className={classes.tabs}>
                        <Box component="div" className={clsx(classes.tab, tabIndex === 0 && classes.tabShow)} padding={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography>
                                Wprowadź dane kontaktowe
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="contactFullName"
                                name="contactFullName"
                                fullWidth
                                id="contactFullName"
                                label="Imię i nazwisko"
                                value={contactFullName}
                                onChange={(e: { target: { value: string } }) => this.setState({ contactFullName: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="contactEmail"
                                name="contactEmail"
                                fullWidth
                                id="contactEmail"
                                label="E-mail kontaktowy"
                                value={contactEmail}
                                onChange={(e: { target: { value: string } }) => this.setState({ contactEmail: e.target.value })}
                                validators={['isEmail']}
                                errorMessages={['Adres e-mail jest nieprawidłowy.']}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="contactPhone "
                                name="contactPhone "
                                fullWidth
                                id="contactPhone "
                                label="Telefon kontaktowy"
                                value={contactPhone}
                                onChange={(e: { target: { value: string } }) => this.setState({ contactPhone: e.target.value })}
                                validators={['isNumber']}
                                errorMessages={['Numer telefonu jest nieprawidłowy.']}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box component="div" className={clsx(classes.tab, tabIndex === 1 && classes.tabShow)} padding={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography>
                                Wprowadź dane firmowe
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoFocus
                                autoComplete="name"
                                name="name"
                                required
                                fullWidth
                                id="name"
                                label="Nazwa"
                                value={name}
                                onChange={(e: { target: { value: string } }) => this.setState({ name: e.target.value })}
                                validators={['required']}
                                errorMessages={['Pole wymagane.']}
                              />
                            </Grid>
                            <Grid item xs={12} sm={7}></Grid>
                            <Grid item xs={12} sm={5}>
                              <GooglePlaceAutocomplite
                                addressDetails={(address: any) => this.getAdressDetails(address)}
                                lat={(lat: number) => this.setState({ lat })}
                                lng={(lng: number) => this.setState({ lng })}
                                address={(addres: string) => this.setState({ address })}
                                current={address}
                              />
                            </Grid>
                            <Grid item xs={12} sm={7}></Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="street"
                                name="street"
                                fullWidth
                                id="street"
                                label="Ulica"
                                value={street}
                                onChange={(e: { target: { value: string } }) => this.setState({ street: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="city"
                                name="city"
                                fullWidth
                                id="city"
                                label="Miejscowość"
                                value={city}
                                onChange={(e: { target: { value: string } }) => this.setState({ city: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="postcode"
                                name="postcode"
                                fullWidth
                                id="postcode"
                                label="Kod pocztowy"
                                value={postcode}
                                onChange={(e: { target: { value: string } }) => this.setState({ postcode: e.target.value })}
                                InputProps={{
                                  inputComponent: MaskPostcode as any,
                                }}
                              />
                            </Grid>

                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="phone"
                                name="phone"
                                fullWidth
                                id="phone"
                                label="Telefon"
                                value={phone}
                                onChange={(e: { target: { value: string } }) => this.setState({ phone: e.target.value })}
                                validators={['isNumber']}
                                errorMessages={['Numer telefonu jest nieprawidłowy.']}
                              />
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                required
                                autoComplete="email"
                                name="fvEmail"
                                fullWidth
                                id="fvEmail"
                                label="E-mail do rozliczeń i faktur"
                                value={fvEmail}
                                onChange={(e: { target: { value: string } }) => this.setState({ fvEmail: e.target.value })}
                                validators={['required', 'isEmail']}
                                errorMessages={['Pole wymagane.', 'Adres e-mail jest nieprawidłowy.']}
                                tab={0}
                              />
                            </Grid>

                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="email"
                                name="email"
                                fullWidth
                                id="email"
                                label="E-mail"
                                value={email}
                                onChange={(e: { target: { value: string } }) => this.setState({ email: e.target.value })}
                                validators={['isEmail']}
                                errorMessages={['Adres e-mail jest nieprawidłowy.']}
                              />
                              <FormHelperText>Oficjalny, publiczny email</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="www"
                                name="www"
                                fullWidth
                                id="www"
                                label="Strona WWW"
                                value={www}
                                onChange={(e: { target: { value: string } }) => this.setState({ www: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="nip"
                                name="nip"
                                fullWidth
                                id="nip"
                                label="NIP"
                                value={nip}
                                onChange={(e: { target: { value: string } }) => this.setState({ nip: e.target.value })}
                                validators={[nipRegexp]}
                                errorMessages={[nipErrorMsg]}
                                InputProps={{
                                  inputComponent: MaskNIP as any
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <FormControlLabel
                                style={{ marginLeft: 0 }}
                                value="start"
                                control={<Switch color="primary" checked={tableDeliveryPossible} value={tableDeliveryPossible} onChange={(event) => this.setState({ tableDeliveryPossible: event.target.checked })} />}
                                label={`Umożliwia wybór stolika?`}
                                labelPlacement="start"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box component="div" className={clsx(classes.tab, tabIndex === 2 && classes.tabShow)} padding={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography>
                                Wprowadź dane do płatności
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextValidator
                                required
                                fullWidth
                                autoComplete="accountCountryCode"
                                name="accountCountryCode"
                                id="accountCountryCode"
                                label="Kod kraju"
                                value={accountCountryCode}
                                onChange={(e: { target: { value: string } }) =>
                                  this.setState({
                                    accountCountryCode: e.target.value,
                                  })
                                }
                                validators={["required"]}
                                errorMessages={["Pole wymagane."]}
                              />
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <TextValidator
                                required
                                fullWidth
                                autoComplete="accountNumber"
                                name="accountNumber"
                                id="accountNumber"
                                label="Numer rachunku bankowego"
                                value={accountNumber}
                                onChange={(e: { target: { value: string } }) =>
                                  this.setState({
                                    accountNumber: e.target.value,
                                  })
                                }
                                validators={["required"]}
                                errorMessages={["Pole wymagane."]}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    </ValidatorForm>
                    {(!loading && data) && <SuccessAlert />}
                    {error && <ErrorAlert error={error} />}
                    {(data && data.restaurantUpdate.errors) && <ErrorAlert modelName="user" fieldError={data.restaurantUpdate.errors} />}
                  </>
                )}
              </Mutation>
            )
          }}
        </Query>
      </>
    )
  }

  _confirm = async (data: any) => {
    if (!data) return
    if (!data.restaurantUpdate) return

    this.props.history.push(ROUTES.restaurants.index.path)
  }
}


export default withStyles(uiStyles)(EditRestaurant)