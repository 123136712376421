import { AUTH_TOKEN, AUTH_ID } from '../../constants'

const Auth = {
  /**
   * Check if user authenticated
   * 
   * @params
   * @return boolean
   */
  check: (): boolean => {
    const token = localStorage.getItem(AUTH_TOKEN)

    return (token !== null && token.length > 0)
  },

  /**
   * Get auth token
   * 
   * @params
   * @return token
   */
  getToken: (): string | null => localStorage.getItem(AUTH_TOKEN),

  /**
   * Set auth token
   * 
   * @params token
   * @return void
   */
  setToken: (token: string): void => {
    localStorage.setItem(AUTH_TOKEN, token)
  },

  /**
   * Get auth id
   * 
   * @params
   * @return id
   */
  id: (): string | null => localStorage.getItem(AUTH_ID),

  /**
   * Set auth token
   * 
   * @params token
   * @return void
   */
  setId: (id: string): void => {
    localStorage.setItem(AUTH_ID, id)
  },

  /**
   * Clear auth
   * 
   * @params
   * @return void
   */

  clear: (): void => {
    localStorage.removeItem(AUTH_TOKEN)
    localStorage.removeItem(AUTH_ID)
  }
}

export default Auth