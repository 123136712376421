// GraphQL
import gql from 'graphql-tag'


export const LOGIN_MUTATION = gql`
  mutation($email: String!, $password: String!){
    login(input: { email: $email, password: $password }){
      token
      user {
        id
        email
        admin
      }
    }
  }
`