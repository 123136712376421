import React from "react";

// UI
import uiStyles from "../../../assets/styles/styles";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
  Tooltip,
  Button,
  Grid,
  MenuItem,
  ListSubheader,
  Fab,
  IconButton,
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  ErrorAlert,
  SuccessAlert,
} from "../../../components/FlashMessage/FlashMessage";
import {
  SubmitActionBtn,
  LoaderBtn,
  ClosePopupBtn,
} from "../../../components/FormActionLinks/FormActionLinks";
import { CustomDialogTitle } from "../../../components/Dialog/Dialog";
import {
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";

// GraphQL
import { Mutation, Query } from "react-apollo";
import {
  CREATE_PROMOTION_MUTATION,
  INDEX_MENU_QUERY,
  INDEX_RESTAURANTS_QUERY,
  UPDATE_PROMOTION_MUTATION,
} from "../repositoryGraphQL";
import FormDatePicker from "../../../components/FormDatePicker/FormDatePicker";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

interface Props extends WithStyles<typeof uiStyles> {
  onCompleted?: any;
  edit?: boolean;
  row: any;
  iconButton?: boolean;
}

type State = {
  restaurantId: string | null;
  menuItemId: string | null;
  name: string | null;
  code: string | null;
  description: string | null;
  value: number | null;
  valueType: string | null;
  usesPerUser: number | null;
  availableCouponsCount: number | null;
  availableUntil: Date | null;
  daysForUse: number | null;
  open: boolean;
};

class FormPromotion extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      restaurantId: this.props.edit
        ? this.props.row.restaurant && this.props.row.restaurant.id
        : null,
      menuItemId: this.props.edit
        ? this.props.row.menuItem && this.props.row.menuItem.id
        : null,
      name: this.props.edit ? this.props.row.name : null,
      code: this.props.edit ? this.props.row.code : null,
      description: this.props.edit ? this.props.row.description : null,
      value: this.props.edit ? this.props.row.value : null,
      valueType: this.props.edit ? this.props.row.valueType : null,
      usesPerUser: this.props.edit ? this.props.row.usesPerUser : null,
      availableCouponsCount: this.props.edit
        ? this.props.row.availableCouponsCount
        : null,
      availableUntil: this.props.edit ? this.props.row.availableUntil : null,
      daysForUse: this.props.edit ? this.props.row.daysForUse : null,
      open: false,
    };
  }

  open = (): void => this.setState({ open: true });

  close = (): void => this.setState({ open: false });

  render() {
    const { classes, edit } = this.props;
    const {
      restaurantId,
      menuItemId,
      name,
      code,
      description,
      value,
      valueType,
      usesPerUser,
      availableCouponsCount,
      availableUntil,
      daysForUse,
      open,
    } = this.state;

    const id = this.props.row ? this.props.row.id : null;

    const editAction = edit ? edit : false;
    const iconButton = this.props.iconButton ? true : false;

    return (
      <>
        {editAction || iconButton ? (
          <Tooltip title={editAction ? "Edycja" : "Dodaj"}>
            {iconButton ? (
              <Fab
                onClick={this.open}
                aria-label="Add"
                size="medium"
                color="secondary"
              >
                {editAction ? <EditIcon /> : <AddIcon />}
              </Fab>
            ) : (
              <IconButton aria-label="Edit" size="medium" onClick={this.open}>
                <EditIcon />
              </IconButton>
            )}
          </Tooltip>
        ) : (
          <Button
            aria-label="Add"
            variant="outlined"
            color="primary"
            size="small"
            className={classes.btn_add_value}
            onClick={this.open}
          >
            <AddIcon /> Nowa promocja
          </Button>
        )}

        <Mutation
          mutation={
            editAction ? UPDATE_PROMOTION_MUTATION : CREATE_PROMOTION_MUTATION
          }
          variables={{
            id,
            restaurantId,
            menuItemId,
            name,
            code,
            description,
            value,
            valueType,
            usesPerUser,
            availableCouponsCount,
            availableUntil,
            daysForUse,
          }}
          onCompleted={(data: any) => this._confirm(data)}
        >
          {(submitForm: any, { loading, error, data }: any) => {
            return (
              <>
                {open && (
                  <Dialog
                    fullWidth
                    maxWidth="md"
                    open={this.state.open}
                    onClose={this.close}
                    aria-labelledby="form-dialog-title"
                  >
                    <CustomDialogTitle
                      id="form-dialog-title"
                      onClose={this.close}
                    >
                      {editAction ? "Edycja promocji" : "Nowa promocja"}
                    </CustomDialogTitle>
                    <ValidatorForm ref="form" onSubmit={submitForm}>
                      <DialogContent style={{ minHeight: 120 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextValidator
                              autoFocus
                              autoComplete="name"
                              name="name"
                              fullWidth
                              id="name"
                              label="Nazwa*"
                              value={name}
                              onChange={(e: { target: { value: string } }) =>
                                this.setState({ name: e.target.value })
                              }
                              validators={["required"]}
                              errorMessages={["Pole wymagane."]}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Query query={INDEX_RESTAURANTS_QUERY}>
                              {({
                                loading,
                                error,
                                data: dataRestaurants,
                              }: {
                                loading: any;
                                error?: any;
                                data: any;
                                refetch: any;
                              }) => {
                                if (loading) return null;
                                if (error) return <ErrorAlert error={error} />;

                                return (
                                  <SelectValidator
                                    label="Restauracja"
                                    disabled={editAction}
                                    value={restaurantId}
                                    onChange={(
                                      event: React.ChangeEvent<unknown>
                                    ) => {
                                      this.setState({
                                        restaurantId: (event.target as HTMLInputElement)
                                          .value,
                                      });
                                    }}
                                    fullWidth
                                    clearable
                                  >
                                    {dataRestaurants &&
                                    dataRestaurants.restaurants &&
                                    dataRestaurants.restaurants.length > 0 ? (
                                      dataRestaurants.restaurants.map(
                                        (item: any): JSX.Element => (
                                          <MenuItem
                                            key={item.id}
                                            value={item.id}
                                          >
                                            {item.name}
                                          </MenuItem>
                                        )
                                      )
                                    ) : (
                                      <MenuItem value="">
                                        Brak restauracji
                                      </MenuItem>
                                    )}
                                  </SelectValidator>
                                );
                              }}
                            </Query>
                          </Grid>
                          {restaurantId && (
                            <Grid item xs={12} sm={12}>
                              {editAction ? (
                                <SelectValidator
                                  label="Produkt"
                                  disabled={editAction}
                                  value={menuItemId}
                                  onChange={(
                                    event: React.ChangeEvent<unknown>
                                  ) => {
                                    this.setState({
                                      menuItemId: (event.target as HTMLInputElement)
                                        .value,
                                    });
                                  }}
                                  fullWidth
                                  clearable
                                >
                                  <MenuItem
                                    key={menuItemId}
                                    value={menuItemId as string}
                                  >
                                    {this.props.row.menuItem
                                      ? this.props.row.menuItem.name
                                      : ""}
                                  </MenuItem>
                                </SelectValidator>
                              ) : (
                                <Query
                                  variables={{ id: restaurantId }}
                                  query={INDEX_MENU_QUERY}
                                >
                                  {({ data }: any) => {
                                    const { restaurant } = data;

                                    return (
                                      <SelectValidator
                                        label="Produkt"
                                        disabled={editAction}
                                        value={menuItemId}
                                        onChange={(
                                          event: React.ChangeEvent<unknown>
                                        ) => {
                                          this.setState({
                                            menuItemId: (event.target as HTMLInputElement)
                                              .value,
                                          });
                                        }}
                                        fullWidth
                                        clearable
                                      >
                                        {restaurant &&
                                          restaurant.menuCategories &&
                                          restaurant.menuCategories.map(
                                            (itemCategory) => {
                                              if (
                                                itemCategory &&
                                                itemCategory.menuItems.length >
                                                  0
                                              ) {
                                                return [
                                                  <ListSubheader>
                                                    {itemCategory.name}
                                                  </ListSubheader>,
                                                  itemCategory.menuItems
                                                    .length > 0
                                                    ? itemCategory.menuItems.map(
                                                        (item) => (
                                                          <MenuItem
                                                            key={item.id}
                                                            value={item.id}
                                                          >
                                                            {item.name}
                                                          </MenuItem>
                                                        )
                                                      )
                                                    : null,
                                                ];
                                              } else return null;
                                            }
                                          )}
                                      </SelectValidator>
                                    );
                                  }}
                                </Query>
                              )}
                            </Grid>
                          )}
                          <Grid item sm={12}>
                            <TextValidator
                              autoComplete="description"
                              name="description"
                              fullWidth
                              id="description"
                              multiline
                              rows={2}
                              label="Opis"
                              value={description}
                              onChange={(e: { target: { value: string } }) =>
                                this.setState({
                                  description: e.target.value,
                                })
                              }
                            />
                          </Grid>
                          <Grid item sm={12}>
                            <TextValidator
                              autoComplete="code"
                              name="code"
                              id="code"
                              label="Kod"
                              fullWidth
                              disabled={editAction}
                              value={code}
                              validators={["matchRegexp:\\w{4,16}"]}
                              errorMessages={[
                                "Kod musi składać się z minimum 4 liter lub cyfr.",
                              ]}
                              onChange={(e: { target: { value: string } }) =>
                                this.setState({ code: e.target.value })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextValidator
                              autoComplete="value"
                              name="value"
                              fullWidth
                              id="value"
                              label="Wartość*"
                              value={value}
                              min={1}
                              type="number"
                              onChange={(e: { target: { value: string } }) =>
                                this.setState({
                                  value: parseInt(e.target.value),
                                })
                              }
                              validators={["required", "minNumber:1"]}
                              errorMessages={[
                                "Pole wymagane.",
                                "Błędna wartość.",
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <SelectValidator
                              label="Typ zniżki*"
                              value={valueType}
                              onChange={(event: React.ChangeEvent<unknown>) => {
                                this.setState({
                                  valueType: (event.target as HTMLInputElement)
                                    .value,
                                });
                              }}
                              fullWidth
                              clearable
                              validators={["required"]}
                              errorMessages={["Pole wymagane."]}
                            >
                              <MenuItem value="constant">Stała (PLN)</MenuItem>
                              <MenuItem value="percent">Procentowa (%)</MenuItem>
                            </SelectValidator>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextValidator
                              autoComplete="usesPerUser"
                              name="usesPerUser"
                              fullWidth
                              id="usesPerUser"
                              label="Liczba użyć na jednego klienta"
                              value={usesPerUser}
                              min={1}
                              type="number"
                              onChange={(e: { target: { value: string } }) =>
                                this.setState({
                                  usesPerUser: parseInt(e.target.value),
                                })
                              }
                              validators={["minNumber:1"]}
                              errorMessages={["Błędna wartość."]}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextValidator
                              autoComplete="availableCouponsCount"
                              name="availableCouponsCount"
                              fullWidth
                              id="availableCouponsCount"
                              label="Dozwolona liczba użyć"
                              value={availableCouponsCount}
                              min={1}
                              type="number"
                              onChange={(e: { target: { value: string } }) =>
                                this.setState({
                                  availableCouponsCount: parseInt(
                                    e.target.value
                                  ),
                                })
                              }
                              validators={["minNumber:1"]}
                              errorMessages={["Błędna wartość."]}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormGroup row>
                              <FormDatePicker
                                label="Data zakończenia promocji"
                                name="toDate"
                                value={availableUntil}
                                className={classes.formControl}
                                onChange={(date: any) =>
                                  this.setState({ availableUntil: date })
                                }
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextValidator
                              autoComplete="daysForUse"
                              name="daysForUse"
                              fullWidth
                              id="daysForUse"
                              label="Liczba dni do wykorzystania promocji"
                              value={daysForUse}
                              min={1}
                              type="number"
                              onChange={(e: { target: { value: string } }) =>
                                this.setState({
                                  daysForUse: parseInt(e.target.value),
                                })
                              }
                              validators={["minNumber:1"]}
                              errorMessages={["Błędna wartość."]}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        {!loading && (
                          <ClosePopupBtn onClick={this.close} text="Anuluj" />
                        )}
                        {!loading && <SubmitActionBtn text="Zapisz" />}
                        {loading && <LoaderBtn />}
                      </DialogActions>
                    </ValidatorForm>
                  </Dialog>
                )}

                {!loading && data && (
                  <SuccessAlert message="Operacja zakończona powodzeniem." />
                )}
                {error && <ErrorAlert error={error} />}
                {data && data.promotionCreate && (
                  <ErrorAlert
                    modelName="promotion"
                    fieldError={data.promotionCreate.errors}
                  />
                )}
                {data && data.promotionUpdate && (
                  <ErrorAlert
                    modelName="promotion"
                    fieldError={data.promotionUpdate.errors}
                  />
                )}
              </>
            );
          }}
        </Mutation>
      </>
    );
  }

  _confirm = async (data: any) => {
    if (!data) return;

    if (!this.props.edit) {
      this.setState({
        open: false,
        restaurantId: null,
        menuItemId: null,
        name: null,
        code: null,
        description: null,
        value: null,
        valueType: null,
        usesPerUser: null,
        availableCouponsCount: null,
        availableUntil: null,
        daysForUse: null,
      });
    } else {
      this.setState({
        open: false,
      });
    }

    this.props.onCompleted();
  };
}

export default withStyles(uiStyles)(FormPromotion);
