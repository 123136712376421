import React, { HTMLAttributes } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../store'
// import { dictionaryChildren } from '../../utils/utils'

// UI
import uiStyles from './uiStyles'
import {
  Typography,
  NoSsr,
  Paper,
  MenuItem
} from '@material-ui/core'
import { BaseTextFieldProps } from '@material-ui/core/TextField'
import { ValueContainerProps } from 'react-select/src/components/containers'
import { ControlProps } from 'react-select/src/components/Control'
import { MenuProps, NoticeProps } from 'react-select/src/components/Menu'
import { OptionProps } from 'react-select/src/components/Option'
import { PlaceholderProps } from 'react-select/src/components/Placeholder'
import { SingleValueProps } from 'react-select/src/components/SingleValue'
import { ValueType } from 'react-select/src/types'
import { Omit } from '@material-ui/types'
import AsyncSelect from 'react-select/async'
import { TextValidator } from 'react-material-ui-form-validator'

interface OptionType {
  label: string
  value: any
  prefix?: string
  visibleLabel?: string
  prefixVisibleLabel?: string
}

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      Brak wyników
    </Typography>
  )
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />
}

function Control(props: ControlProps<OptionType>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props

  return (
    <TextValidator
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  )
}

function Option(props: OptionProps<OptionType>) {
  const { selectProps } = props

  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={selectProps.classes.option}
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.data.prefix && <Typography color="secondary" className={selectProps.classes.option}>{props.data.prefix}&nbsp;&nbsp;&nbsp;&nbsp;</Typography>}
      {props.data.label}
    </MenuItem>
  )
}

type MuiPlaceholderProps = Omit<PlaceholderProps<OptionType>, 'innerProps'> &
  Partial<Pick<PlaceholderProps<OptionType>, 'innerProps'>>
function Placeholder(props: MuiPlaceholderProps) {
  const { selectProps, innerProps = {}, children } = props
  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  )
}

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.data.visibleLabel ? props.data.prefixVisibleLabel ? props.data.prefixVisibleLabel + ' - ' + props.data.visibleLabel : props.data.visibleLabel : props.children}
    </Typography>
  )
}

function ValueContainer(props: ValueContainerProps<OptionType>) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

function Menu(props: MenuProps<OptionType>) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

type Props = {
  id?: string | number
  itemId?: string
  label?: string
  required?: boolean
  visibleLabel?: string
  prefixVisibleLabel?: string
  name: string
  value: any
  validators?: Array<{}>
  errorMessages?: Array<{}>
  prefixAttr?: string
  dictionarySysName: string
  dictionaries: any
  onChange: any
  isDisabled?: boolean
}

const FormDictionarySelect: React.FC<Props> = (props) => {
  const classes = uiStyles()
  const { id, name, label, visibleLabel, prefixAttr, dictionarySysName, onChange, isDisabled, validators, errorMessages, required, prefixVisibleLabel } = props
  const itemId = props.itemId ? props.itemId : 'id'
  const resultSize: number = 10

  const dictionary = props.dictionaries[dictionarySysName]
  let values = dictionary ? dictionary['values'] : []

  // Mapping values to final items
  const mappedValues: OptionType[] = values.map((item: any) => {
    let parentId = null

    return {
      label: item.name,
      value: item[itemId],
      prefix: prefixAttr ? item[prefixAttr] : null,
      visibleLabel: visibleLabel ? item[visibleLabel] : null,
      prefixVisibleLabel: prefixVisibleLabel ? item[prefixVisibleLabel] : null,
      parentId
    }
  })

  const valueItem = props.value ? mappedValues.find(({ value }) => value === props.value) : null

  const handleChange = (item: ValueType<OptionType>) => {
    const input = { ...item }
    return onChange(input['value'], input['parentId'])
  }

  const search = (where: string | undefined, text: string) => {
    if (!where) return false
    return where.toLowerCase().includes(text.toLowerCase())
  }

  const searchValue = (inputValue: string) => {
    return mappedValues.filter(({ label, prefix }) =>
      search(label, inputValue) || search(prefix, inputValue)
    ).slice(0, resultSize)
  }

  const loadOptions = (inputValue: string) => {
    return new Promise(resolve => resolve(searchValue(inputValue)))
  }

  const defaultOptions = mappedValues.slice(0, resultSize)

  return (
    <NoSsr>
      <AsyncSelect
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.querySelector('body')}
        cacheOptions
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        isClearable
        isDisabled={isDisabled}
        classes={classes}
        inputId={`select-${name}-${id}`}
        TextFieldProps={{
          required: required ? true : false,
          label: label || '',
          validators: required ? validators : [],
          errorMessages: required ? errorMessages : [],
          value: valueItem,
          InputLabelProps: {
            htmlFor: `select-${name}-${id}`,
            shrink: true
          }
        }}
        placeholder="Wyszukaj"
        components={components}
        value={valueItem}
        onChange={handleChange}
      />
    </NoSsr>
  )
}

const mapStateToProps = (state: AppState) => ({
  dictionaries: state.dictionaries
})

export default connect(mapStateToProps)(FormDictionarySelect)