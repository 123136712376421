import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: '100%',
    },
    chipWithMargin: {
      margin: theme.spacing(1, 1, 1, 0)
    },
    noWrap: {
      whiteSpace: 'normal'
    }
  })
)

export default uiStyles