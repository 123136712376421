// GraphQL
import gql from 'graphql-tag'

export const INDEX_TIPS_QUERY = gql`
  query{
    tips {
      amountToSettled
    tips{
      id
      settled
      order {
        id
        createdAt
        uniqueNumber
        restaurant {
          id
          name
        }
      }
      status
      value
    }
  }
  }
`


export const INDEX_RESTAURANTS_QUERY = gql`
  query{
    restaurants{
      id
      name
    }
  }
`

export const CREATE_SETTLEMENT_QUERY = gql`
  mutation($fromDate: ISO8601Date, $toDate: ISO8601Date!){
    settlementCreateForWaiters(input:{fromDate: $fromDate, toDate: $toDate}){
      errors {
        field
        messages
      }
      settlement {
        id
      }
    }
  }
`

export const REPORT_SHOW_QUERY = gql`
query report($id: ID!){
  report(id: $id){
    id
    createdAt
    fromDate
    toDate
    restaurant{
      id
      name
    }
    orders{
      id
      uniqueNumber
      status
      total 
      createdAt
    }
    totalAmount
    settled
    linkToCsv 
  }
}
`


