import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const drawerWidth = 260

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    ListItemIcon: {
      minWidth: '40px',
    },
    list: {
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(0),
    },
    listItem: {
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(0),
    },
    link: {
      color: theme.palette.text.secondary
    },
  }),
)

export default uiStyles