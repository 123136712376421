import React from "react"
import withBreadcrumbs, { BreadcrumbsRoute } from "react-router-breadcrumbs-hoc"
import BREADCRUMBS from "../../breadcrumbs"
import { Link as RouteLink } from 'react-router-dom'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Breadcrumbs, Link } from '@material-ui/core'

const useStyles = makeStyles(() =>
  createStyles({
    breadcrumbs: {
      color: '#fff'
    }
  })
)

const AppBreadcrumbs = ({ breadcrumbs }) => {
  const classes = useStyles()

  return (
    <Breadcrumbs className={classes.breadcrumbs} aria-label="Breadcrumb">
      {breadcrumbs.map(({ breadcrumb, match }, index) => (
        <Link color="inherit" key={index} component={RouteLink} to={match.url || ""}>{breadcrumb}</Link>
      ))}
    </Breadcrumbs>
  )
}

export default withBreadcrumbs(BREADCRUMBS as BreadcrumbsRoute[])(AppBreadcrumbs)