const BREADCRUMBS = [
  {
    path: '/',
    breadcrumb: 'Home'
  },
  {
    path: '/profile',
    breadcrumb: 'Moje Konto',
  },
  {
    path: "/change-password",
    breadcrumb: 'Zmiana Hasła'
  },
  {
    path: "/restaurants",
    breadcrumb: 'Restauracje',
  },
  {
    path: "/promotions",
    breadcrumb: 'Promocje',
  },
  {
    path: "/restaurant",
    breadcrumb: 'Restauracja',
    routes: [
      {
        path: '/restaurant/:id',
        breadcrumb: null
      },
      {
        path: '/restaurant/:id/show',
        breadcrumb: null
      },
      {
        path: '/restaurant/edit',
        breadcrumb: null
      },
      {
        path: '/restaurant/:id/edit',
        breadcrumb: null
      },
    ]
  },
  {
    path: "/waiters",
    breadcrumb: 'Kelnerzy',
  },
  {
    path: "/waiter",
    breadcrumb: 'Kelner',
    routes: [
      {
        path: '/waiter/:id',
        breadcrumb: null
      },
      {
        path: '/waiter/:id/show',
        breadcrumb: null
      },
      {
        path: '/waiter/edit',
        breadcrumb: null
      },
      {
        path: '/waiter/:id/edit',
        breadcrumb: null
      },
    ]
  },
  {
    path: "/clients",
    breadcrumb: 'Klienci',
  },
  {
    path: "/client",
    breadcrumb: 'Klient',
    routes: [
      {
        path: '/client/:id',
        breadcrumb: null
      },
      {
        path: '/client/:id/show',
        breadcrumb: null
      },
      {
        path: '/client/edit',
        breadcrumb: null
      },
      {
        path: '/client/:id/edit',
        breadcrumb: null
      },
    ]
  },
  {
    path: "/users",
    breadcrumb: 'Użytkownicy',
  },
  {
    path: "/user",
    breadcrumb: 'Użytkownik',
    routes: [
      {
        path: '/user/:id',
        breadcrumb: null
      },
      {
        path: '/user/:id/show',
        breadcrumb: null
      },
      {
        path: '/user/edit',
        breadcrumb: null
      },
      {
        path: '/user/:id/edit',
        breadcrumb: null
      },
    ]
  },
  {
    path: "/reports",
    breadcrumb: 'Raporty',
    routes: [
      {
        path: '/reports/:id',
        breadcrumb: null
      },
      {
        path: '/reports/:id/show',
        breadcrumb: null
      },
    ]
  },
  {
    path: "/tips",
    breadcrumb: 'Napiwki',
    routes: [
      {
        path: '/tips/:id',
        breadcrumb: null
      },
    ]
  },
  {
    path: "/settlements",
    breadcrumb: 'Rozliczenia',
    routes: [
      {
        path: '/settlements/:id',
        breadcrumb: null
      },
      {
        path: '/settlements/:id/show',
        breadcrumb: null
      },
    ]
  },
  {
    path: "/dictionaries",
    breadcrumb: 'Słowniki',
  },
  {
    path: "/dictionary",
    breadcrumb: 'Słownik',
    routes: [
      {
        path: '/dictionary/:sysName',
        breadcrumb: null
      },
    ]
  },
]

export default BREADCRUMBS