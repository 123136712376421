import React from 'react'

import DestroyObject, { DestroyProps } from '../../../components/DestroyObject/DestroyObject'

// GraphQL
import { DESTROY_USER_MUTATION } from '../repositoryGraphQL'

type Props = {
  onCompleted: any
}

const DestroyUser: React.FC<Props & DestroyProps> = (props) => {

  return <DestroyObject
    {...props}
    btnText="Usuń"
    mutation={DESTROY_USER_MUTATION}
    onCompleted={props.onCompleted}
  />
}

export default DestroyUser