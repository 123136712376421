import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ROUTES from '../../../routes'

// UI
import uiStyles from '../../../assets/styles/styles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  Paper,
  Box,
  Typography,
} from '@material-ui/core'
import Loading from '../../../components/Loading/Loading'
import { ErrorAlert } from '../../../components/FlashMessage/FlashMessage'
import { ActiveToggleChip } from '../../../components/CustomChips/CustomChips'
import { DisplayLabelValue } from '../../../components/DisplayInHtml/DisplayInHtml'
import { FormattedDate, FormattedPrice } from '../../../components/FormattedData/FormattedData'
import DataGridOrders from '../ShowReport/DataGridOrders/DataGridOrders'
import DataGridEmpty from '../../../components/DataGridEmpty/DataGridEmpty'

// GraphQL
import { Query } from 'react-apollo'
import { REPORT_SHOW_QUERY } from '../repositoryGraphQL'
import TopBackButton from '../../../components/TopBackButton/TopBackButton'



interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  match: any
}


class ShowReport extends React.Component<Props, {}> {


  render() {
    const { classes } = this.props
    const { id } = this.props.match.params

    const backUrl: string = ROUTES.reports.index.path


    return (
      <>
        {
          <Query query={REPORT_SHOW_QUERY}
            variables={{ id }}
            fetchPolicy="network-only">
            {({ loading, error, data }) => {
              if (loading) return <Loading />
              if (error) return <ErrorAlert error={error} />

              const { report } = data

              return (
                <>
                  <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                      <Grid item sm={12}>
                        <Grid item xs={6}>
                          <TopBackButton
                            url={backUrl}
                            title={`${report.restaurant && report.restaurant.name}`}
                            subtitle="Szczegóły raportu"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Button className={classes.fab_edit} aria-label="Download" variant="outlined" size="small" href={report.linkToCsv}>
                      Pobierz Raport
                    </Button>
                    <Grid container spacing={2}>
                      <Grid item sm={4}>
                        <DisplayLabelValue label="Restauracja" value={report.restaurant && report.restaurant.name} />
                      </Grid>
                      <Grid item sm={4}>
                        <DisplayLabelValue label="Za okres" value={<><FormattedDate format="DD.MM.YYYY" value={report.fromDate} /> - <FormattedDate format="DD.MM.YYYY" value={report.toDate} /> </>} />
                      </Grid>
                      <Grid item sm={4}>
                        <DisplayLabelValue label="Data utworzenia" value={<FormattedDate format="DD.MM.YYYY" value={report.createdAt} />} />
                      </Grid>
                      <Grid item sm={4}>
                        <DisplayLabelValue label="Kwota" value={<FormattedPrice value={report.totalAmount} />} />
                      </Grid>
                      <Grid item sm={4}>
                        <DisplayLabelValue label="Status" value={<ActiveToggleChip noMargin txtActive="Rozliczony" txtDeactive="Do rozliczenia" status={report.settled} />} />
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper className={classes.tabs}>
                    <Box component="div" className={classes.tabShow} padding={4}>
                      <Grid container spacing={2}>
                        <Grid item sm={12}>
                          <Typography variant="h6" gutterBottom>Zamówienia</Typography>
                          {
                            report.orders.length > 0 ? <DataGridOrders rows={report.orders} /> : <DataGridEmpty />
                          }
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </>
              )
            }}
          </Query>
        }
      </>

    )
  }
}

export default withStyles(uiStyles)(ShowReport)