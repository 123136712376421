import React from 'react'
import ROUTES from '../../../routes'

// UI
import uiStyles from '../../../assets/styles/styles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Paper,
  Grid
} from '@material-ui/core'
import Loading from '../../../components/Loading/Loading'
import { ErrorAlert } from '../../../components/FlashMessage/FlashMessage'
import DataGridValues from './DataGridValues/DataGridValues'
import DataGridEmpty from '../../../components/DataGridEmpty/DataGridEmpty'
import CreateValue from './Value/CreateValue/CreateValue'
import TopBackButton from '../../../components/TopBackButton/TopBackButton'

// GraphQL
import { Query } from 'react-apollo'
import { INDEX_VALUES_QUERY } from '../repositoryGraphQL'


interface Props extends WithStyles<typeof uiStyles> { 
  match: any
}

type State = {
  dictionary: {
    name: string,
    values: Array<{}>
  }
}

class IndexValues extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      dictionary: {
        name: '',
        values: []
      }
    }
  }

  render() {
    const { classes } = this.props
    const { sysName } = this.props.match.params

    return (
      <Query
        query={INDEX_VALUES_QUERY}
        variables={{ sysName }}
        fetchPolicy="no-cache"
        onCompleted={(data) => this.setState({ dictionary: data.dictionary })}
      >
        {({ loading, error, data, refetch}) => {
          if (loading) return <Loading />
          if (error) return <ErrorAlert error={error} />

          const { dictionary } = data || {}

          const backUrl: string = ROUTES.dictionaries.index.path

          return (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                    <TopBackButton
                      url={backUrl}
                      title={dictionary.name}
                    subtitle=" Słowniki systemowe"
                    />
                  </Grid>
                </Grid>
              <CreateValue sysName={dictionary.sysName} row={dictionary} onCompleted={() => refetch()} />
              {
                dictionary.values.length > 0 ? <DataGridValues sysName={dictionary.sysName} updateDataGrid={() => refetch()} rows={dictionary.values} /> : <DataGridEmpty />
              }
            </Paper>
          )
        }}
      </Query>
    )
  }
}

export default withStyles(uiStyles)(IndexValues)