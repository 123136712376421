import React from 'react'

// UI
import FormValue from '../FormValue/FormValue'

interface Props {
  row: any
  sysName?: string
  edit?: boolean
  onCompleted?: any
}

const EditValue: React.FC<Props> = (props) => {

  return (
    <FormValue sysName={ props.sysName || undefined } edit={props.edit} row={props.row} onCompleted={() => props.onCompleted()}/>
  )
}

export default EditValue