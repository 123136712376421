import React from 'react'

// UI
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'

import 'moment/locale/pl'
moment.locale('pl')

type Props = {
  name: string
  label: string
  id?: string
  value: Date | string | null
  required?: boolean
  disabled?: boolean
  format?: string
  minDate?: Date
  maxDate?: Date
  className?: string
  InputLabelProps?: any
  onChange: any
  disableFuture?: boolean
}

const FormDatePicker: React.FC<Props> = (props) => {
  const { name, label, id, value, required, disabled, format, minDate, maxDate, className, InputLabelProps, onChange, disableFuture } = props

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        autoOk
        disableToolbar
        clearable
        id={id || name}
        name={name}
        required={required || false}
        disabled={disabled || false}
        fullWidth
        label={label}
        format={format || 'DD.MM.YYYY'}
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        disableFuture={disableFuture || false}
        InputLabelProps={InputLabelProps}
        className={className}
        onChange={(date: any) => onChange(date)}
        cancelLabel="Anuluj"
        clearLabel="Wyczyść"
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        invalidDateMessage="Błędny format daty"
        minDateMessage="Nieprawidłowa data"
        maxDateMessage="Nieprawidłowa data"
      />
    </MuiPickersUtilsProvider>
  )
}

export default FormDatePicker