import React from 'react'

// UI
import FormValue from '../FormValue/FormValue'

interface Props {
  row: any
  onCompleted?: any
  sysName?: string
}

const CreateValue: React.FC<Props> = (props) => {

  return (
    <FormValue sysName={ props.sysName || undefined } row={props.row} onCompleted={props.onCompleted} />
  )
}

export default CreateValue