import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ROUTES from '../../../routes'

// UI
import uiStyles from '../../../assets/styles/styles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Divider,
  Button,
  Typography,
} from '@material-ui/core'
import { CustomDialogTitle } from '../../../components/Dialog/Dialog'
import Loading from '../../../components/Loading/Loading'
import { ErrorAlert } from '../../../components/FlashMessage/FlashMessage'

// GraphQL
import { Query } from 'react-apollo'
import { SETTLEMENT_SHOW_QUERY } from '../repositoryGraphQL'
import { DisplayLabelValue } from '../../../components/DisplayInHtml/DisplayInHtml'
import { FormattedDate, FormattedPrice } from '../../../components/FormattedData/FormattedData'
import DataGridEmpty from '../../../components/DataGridEmpty/DataGridEmpty'
import DataGridTransfers from './DataGridTransfers/DataGridTransfers'
import MarkSettled from '../MarkSettled/MarkSettled'
import { ActiveToggleChip, SettlementTypeChip } from '../../../components/CustomChips/CustomChips'


interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  match: any
}

type State = {
  open: boolean
}

class ShowSettlement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      open: true
    }
  }

  open = (): void => this.setState({ open: true })

  close = (): void => this.props.history.push(ROUTES.settlements.index.path)

  render() {
    const { open } = this.state
    const { classes } = this.props
    const { id } = this.props.match.params

    return (
      <>
        {
          open && <Query query={SETTLEMENT_SHOW_QUERY}
            variables={{ id }}
            fetchPolicy="network-only"
            >
            {({ loading, error, data, refetch }) => {
              if (loading) return <Loading />
              if (error) return <ErrorAlert error={error} />

              const { settlement } = data

              return (
                <Dialog fullWidth open={open} maxWidth="md" onClose={this.close} aria-labelledby="form-dialog-title">
                  <CustomDialogTitle id="form-dialog-title" onClose={this.close}>Szczegóły rozliczenia
                </CustomDialogTitle>
                  <DialogContent dividers>
                    <Grid container spacing={2}>
                      <Grid item sm={4}>
                        <DisplayLabelValue label="Data utworzenia" value={<FormattedDate format="DD.MM.YYYY" value={settlement.createdAt} />} />
                      </Grid>
                      <Grid item sm={4}>
                        <DisplayLabelValue label="Kwota" value={<FormattedPrice value={settlement.totalAmount} />} />
                      </Grid>
                      <Grid item sm={4}>
                        <DisplayLabelValue label="Status" value={<ActiveToggleChip noMargin txtActive="Rozliczony" txtDeactive="Do rozliczenia" status={settlement.settled} />} />
                      </Grid>
                      <Grid item sm={4}>
                        <DisplayLabelValue label="Typ" value={<SettlementTypeChip noMargin type={settlement.generatedFor} />} />
                      </Grid>
                      <Grid item sm={12}>
                        <Button style={{ marginTop: '30px'}}  aria-label="Download" variant="outlined" size="small" href={settlement.linkToCsv}>
                          Pobierz
                        </Button>
                        {
                          !settlement.settled &&   
                          <MarkSettled createdAt={settlement.createdAt} id={settlement.id} onCompleted={refetch} />
                        }
                      
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                      <Grid item sm={12}>
                        <Typography style={{marginTop: 10}} variant="h6" gutterBottom>Pozycje</Typography>
                        {
                          settlement.transfers.length > 0 ? <DataGridTransfers rows={settlement.transfers} /> : <DataGridEmpty />
                        }
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions></DialogActions>
                </Dialog>
              )
            }}
          </Query>
        }
      </>

    )
  }
}

export default withStyles(uiStyles)(ShowSettlement)