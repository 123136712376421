import React from 'react'
import ROUTES from '../../../routes'
import { RouteComponentProps } from 'react-router-dom'

// UI
import uiStyles from '../../../assets/styles/styles'
import clsx from 'clsx'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Paper,
  FormGroup,
  Box,
  Grid,
  Tabs,
  Tab,
  AppBar,
  Typography
} from '@material-ui/core'
import { SuccessAlert, ErrorAlert } from '../../../components/FlashMessage/FlashMessage'
import Loading from '../../../components/Loading/Loading'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
// import { accountNumberRegexp, accountNumberErrorMsg } from '../../../utils/validators'
import { SubmitActionBtn, LoaderBtn, BackActionBtn } from '../../../components/FormActionLinks/FormActionLinks'
import TopBackButton from '../../../components/TopBackButton/TopBackButton'
import { MaskPostcode, MaskAccountNumber } from '../../../components/MaskedInputs/MaskedInputs'
import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker'

// GraphQL
import { Mutation, Query } from 'react-apollo'
import { SHOW_WAITER_QUERY, WAITER_UPDATE_MUTATION} from '../repositoryGraphQL'



interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  match: any
}

type State = {
  waiter_id: string
  accountNumber: string
  accountCountryCode: string
  birthDate: Date
  city: string
  postcode: string
  street: string
  
  tabIndex: number
}

class EditWaiter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      waiter_id: '',
      accountNumber: '',
      accountCountryCode: '',
      birthDate: new Date(),
      city: '',
      postcode: '',
      street: '',
      tabIndex: 0
    }
  }

  handleQueryComplete = (data: any) => {
    const { waiter } = data

    this.setState({
      waiter_id: waiter.waiterProfile ? waiter.waiterProfile.id : '',
      accountNumber: waiter.waiterProfile.accountNumber ? waiter.waiterProfile.accountNumber : '',
      accountCountryCode: waiter.waiterProfile.accountCountryCode ? waiter.waiterProfile.accountCountryCode : '',
      birthDate: waiter.waiterProfile.birthDate ? waiter.waiterProfile.birthDate : null,
      city: waiter.waiterProfile.city ? waiter.waiterProfile.city : '',
      postcode: waiter.waiterProfile.postcode ? waiter.waiterProfile.postcode : '',
      street: waiter.waiterProfile.street ? waiter.waiterProfile.street : '',
    })
  }

  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue
    })
  }

  render() {
    const { classes } = this.props;
    const { id } = this.props.match.params;
    const { tabIndex, waiter_id, accountNumber, accountCountryCode, birthDate, city, postcode, street } = this.state;

    const backUrl = ROUTES.waiters.index.path;

    return (
      <>
        <Query
          query={SHOW_WAITER_QUERY}
          variables={{ id }}
          onCompleted={query_data => this.handleQueryComplete(query_data)}
          fetchPolicy='network-only'
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return <ErrorAlert error={error} />

            const showName = data.waiter.email

            return (
              <Mutation
                mutation={WAITER_UPDATE_MUTATION}
                variables={{ waiter_id, birthDate, accountNumber, accountCountryCode, city, postcode, street }}
                onCompleted={(data: any) => this._confirm(data)}
              >
                {(submitForm: any, { loading, error, data }: any) => (
                  <>
                    <ValidatorForm
                      ref="form"
                      onSubmit={submitForm}
                      onError={(errors: any) => console.log(errors)}
                    >
                      <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TopBackButton
                              url={backUrl}
                              title={`${showName}`}
                              subtitle="Edycja kelnera"
                            />
                          </Grid>
                          <Grid item xs={6} className={classes.formAction}>
                            {!loading && <BackActionBtn text={'Anuluj'} to={backUrl} />}
                            {!loading && <SubmitActionBtn text="Zapisz" />}
                            {loading && <LoaderBtn />}
                          </Grid>
                        </Grid>
                      </Paper>
                      <AppBar position="static" color="default" className={classes.menuTabs}>
                        <Tabs
                          value={tabIndex}
                          onChange={this.handleTabChange}
                          variant="fullWidth"
                          indicatorColor="secondary"
                        >
                          <Tab label="Dane kelnera" />
                          <Tab label="Dane bankowe" />
                        </Tabs>
                      </AppBar>
                      <Paper className={classes.tabs}>
                        <Box component="div" className={clsx(classes.tab, tabIndex === 0 && classes.tabShow)} padding={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography>
                                Wprowadź dane kelnera
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <FormGroup row>
                                <FormDatePicker
                                  label="Data urodzenia"
                                  name="birthDate"
                                  value={birthDate}
                                  className={classes.formControl}
                                  onChange={(date: any) => this.setState({ birthDate: date })}
                                />
                              </FormGroup>
                            </Grid>
                            <Grid className={classes.marginTop} item xs={12}>
                              <Typography>
                                Wprowadź dane adresowe
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="street"
                                name="street"
                                fullWidth
                                id="street"
                                label="Ulica"
                                value={street}
                                onChange={(e: { target: { value: string } }) => this.setState({ street: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}></Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="postcode"
                                name="postcode"
                                fullWidth
                                id="postcode"
                                label="Kod pocztowy"
                                value={postcode}
                                onChange={(e: { target: { value: string } }) => this.setState({ postcode: e.target.value })}
                                InputProps={{
                                  inputComponent: MaskPostcode as any,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} sm={1}></Grid>
                            <Grid item xs={12} sm={5}>
                              <TextValidator
                                autoComplete="city"
                                name="city"
                                fullWidth
                                id="city"
                                label="Miejscowość"
                                value={city}
                                onChange={(e: { target: { value: string } }) => this.setState({ city: e.target.value })}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box component="div" className={clsx(classes.tab, tabIndex === 1 && classes.tabShow)} padding={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography>
                                Wprowadź dane do płatności
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextValidator
                                required
                                fullWidth
                                autoComplete="accountCountryCode"
                                name="accountCountryCode"
                                id="accountCountryCode"
                                label="Kod kraju"
                                value={accountCountryCode}
                                onChange={(e: { target: { value: string } }) =>
                                  this.setState({
                                    accountCountryCode: e.target.value,
                                  })
                                }
                                validators={["required"]}
                                errorMessages={["Pole wymagane."]}
                              />
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <TextValidator
                                required
                                fullWidth
                                autoComplete="accountNumber"
                                name="accountNumber"
                                id="accountNumber"
                                label="Numer rachunku bankowego"
                                value={accountNumber}
                                onChange={(e: { target: { value: string } }) =>
                                  this.setState({
                                    accountNumber: e.target.value,
                                  })
                                }
                                validators={["required"]}
                                errorMessages={["Pole wymagane."]}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    </ValidatorForm>
                    {(!loading && data) && <SuccessAlert />}
                    {error && <ErrorAlert error={error} />}
                    {(data && data.waiterUpdate.errors) && <ErrorAlert modelName="waiter" fieldError={data.waiterUpdate.errors} />}
                  </>
                )}
              </Mutation>
            )
          }}
        </Query>
      </>
    )
  }

  _confirm = async (data: any) => {
    if (!data) return
    if (!data.waiterUpdate) return
    
    this.props.history.push(ROUTES.waiters.index.path)
  }
}


export default withStyles(uiStyles)(EditWaiter)