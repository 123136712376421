// GraphQL
import gql from 'graphql-tag'

export const INDEX_RESTAURANTS_QUERY = gql`
  query restaurants($activeOnly : Boolean){
    restaurants(activeOnly: $activeOnly){
      id
      name
      activated
      active
      mainImageUrl
      blocked
      blockedReason {
        name
      }
      status
    }
  }
`

export const SHOW_RESTAURANT_QUERY = gql`
  query restaurant($id : ID!){
    restaurant(id: $id){
      id
      name
      accountNumber
      accountCountryCode
      nip
      phone
      www
      city
      postcode
      street
      lat
      lng
      email
      fvEmail
      contactEmail
      contactFullName
      contactPhone
      activated
      active
      blocked
      status
      mainImageUrl 
      fvEmail
      tableDeliveryPossible
      blockedReason {
        name
      }
      schedule {
        monFrom
        monTo
        monLastOrderHour
        tueFrom
        tueTo
        tueLastOrderHour
        wedFrom
        wedTo
        wedLastOrderHour
        thuFrom
        thuTo
        thuLastOrderHour
        friFrom
        friTo
        friLastOrderHour
        satFrom
        satTo
        satLastOrderHour
        sunFrom
        sunTo
        sunLastOrderHour
      }
      menuCategories {
        id
        name
        public
        menuItems {
          allergens {
            name
            id
          }
          imageSignedBlobId
          imageUrl
          calories
          price
          description
          id
          name
          public
        }
      }
    }
  }
`
export const RESTAURANT_UPDATE_MUTATION = gql`
  mutation($id: ID!, $name: String!, $street: String, $city: String, $postcode: String, $email: String, $phone: String, $contactFullName: String, $contactEmail: String, $contactPhone: String, $nip: String, $accountNumber: String!, $accountCountryCode: String, $www: String, $lat: Float!, $lng: Float!, $fvEmail: String!, $tableDeliveryPossible: Boolean){
    restaurantUpdate(input:{id: $id, name: $name, street: $street, city: $city, postcode: $postcode, email: $email, phone: $phone, contactFullName: $contactFullName, contactEmail: $contactEmail, contactPhone: $contactPhone, nip: $nip, accountNumber: $accountNumber, accountCountryCode: $accountCountryCode, www: $www, lat: $lat, lng: $lng, fvEmail: $fvEmail, tableDeliveryPossible: $tableDeliveryPossible }){
      errors {
        field
        messages
      }
    }
  }
`

const RESTAURANT_ACTIVATE_MUTATION = gql`
  mutation($id: ID!){
    restaurantActivate(input:{id: $id}){
      errors {
        field
        messages
      }
    }
  }
`

const RESTAURANT_BLOCK_MUTATION = gql`
  mutation($id: ID!, $blockedReasonId: ID!){
    restaurantBlock(input:{id: $id, blockedReasonId: $blockedReasonId}){
      errors {
        field
        messages
      }
    }
  }
`

const RESTAURANT_UNBLOCK_MUTATION = gql`
  mutation($id: ID!){
    restaurantUnblock(input:{id: $id}){
      errors {
        field
        messages
      }
    }
  }
`

const RESTAURANT_DESTROY_MUTATION = gql`
  mutation($id: ID!){
    restaurantDestroy(input:{id: $id}){
      errors {
        field
        messages
      }
    }
  }
`

const RESTAURANT_LOGIN_ADMIN_MUTATION = gql`
  mutation($id: ID!){
    restaurantLoginAdmin(input:{id: $id}){
      errors {
        field
        messages
      }
      token
    }
  }
`


export const ACTIONS_RESTAURANT = {
  restaurantActivate: RESTAURANT_ACTIVATE_MUTATION,
  restaurantBlock: RESTAURANT_BLOCK_MUTATION,
  restaurantUnblock: RESTAURANT_UNBLOCK_MUTATION,
  restaurantDestroy: RESTAURANT_DESTROY_MUTATION,
  restaurantLoginAdmin: RESTAURANT_LOGIN_ADMIN_MUTATION,
}