import React from 'react'
import clsx from 'clsx'
import ROUTES from '../../routes'
import { RouteComponentProps } from 'react-router-dom'

// UI
import uiStyles from '../../assets/styles/styles'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import {
  Paper,
  Typography,
  Badge,
  AppBar,
  Tabs,
  Tab,
  Box,
  Grid, Button, Tooltip
} from '@material-ui/core'
import Loading from '../../components/Loading/Loading'
import { ErrorAlert } from '../../components/FlashMessage/FlashMessage'
import DataGridEmpty from '../../components/DataGridEmpty/DataGridEmpty'
import DataGridTips from './DataGridTips/DataGridTips'
import CreateSettlement from './CreateSettlement/CreateSettlement'


// GraphQL
import { Query } from 'react-apollo'
import { INDEX_TIPS_QUERY } from './repositoryGraphQL'
import { FormattedPrice } from '../../components/FormattedData/FormattedData'



const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    top: theme.spacing(2),
    right: theme.spacing(-2),
  },
}))(Badge)

interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {}

type State = {
  tabIndex: number
}

class IndexTips extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      tabIndex: 0,
    }
  }


  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue
    })
  }

  goToSettlements = () => this.props.history.push(ROUTES.settlements.index.path)

  downloadCsv = () => {
    const url = `${process.env.REACT_APP_GRAPHQL_API ? process.env.REACT_APP_GRAPHQL_API.slice(0, -7) : null}downloads/tips`
    window.open(url);
  }

  render() {
    const { classes } = this.props
    const { tabIndex } = this.state

    const types: Array<any> = [false, true]

    return (
      <Query
        query={INDEX_TIPS_QUERY}
        fetchPolicy="network-only"
        //onCompleted={data => console.log(data)}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loading />
          if (error) return <ErrorAlert error={error} />

          const { tips } = data.tips

          const toSettle = tips ? tips.filter(tips => !tips.settled).length : 0
          const settled = tips ? tips.filter(tips => tips.settled).length : 0

          return (
            <>        
            <Paper className={classes.paper}>
              <StyledBadge badgeContent={tips ? Object.keys(tips).length : 0} color="primary">
                <Typography variant="h5" component="h3">
                  Napiwki
                </Typography>
              </StyledBadge>
              <Typography variant="caption" component="p">
                Lista napiwków
              </Typography>
              <Grid  className={classes.marginTop} container spacing={2}>
                <Grid item xs={12} sm={6}>
                  Do rozliczenia pozostało: <span style={{fontSize: 15, fontWeight: 500}}><FormattedPrice value={data.tips.amountToSettled} /></span>
                </Grid>
                <Grid style={{textAlign: 'right'}} item xs={12} sm={6}>
        <Tooltip title="Pobierz">
          <Button aria-label="CSV" variant="outlined" size="small" onClick={this.downloadCsv}> Wygeneruj CSV </Button>
        </Tooltip>
                </Grid>
              </Grid>
              </Paper>
              <AppBar position="static" color="default" className={classes.menuTabs}>
                <Tabs
                  value={tabIndex}
                  onChange={this.handleTabChange}
                  variant="fullWidth"
                  indicatorColor="secondary"
                >
                  <Tab label={`Do rozliczenia (${toSettle})`} />
                  <Tab label={`Rozliczone (${settled})`} />
                </Tabs>
              </AppBar>
              <Paper className={classes.tabs}>
                {
                  types.map((item, index) => (
                    <Box key={index} component="div" paddingLeft={2} paddingRight={2} className={clsx(classes.tab, tabIndex === index && classes.tabShow)}>
                      {tips && tips.filter(tip => tip.settled === item).length > 0 ? <DataGridTips rows={tips.filter(tip => tip.settled === item)} /> : <DataGridEmpty />}
                    </Box>
                  ))
                }
                {
                  tabIndex === 0 && 
                  <Box padding={2}><CreateSettlement onCompleted={this.goToSettlements} /></Box>
                }   
              </Paper>
            </>
          )
        }}
      </Query>
    )
  }
}

export default withStyles(uiStyles)(IndexTips)