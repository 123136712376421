// GraphQL
import gql from 'graphql-tag'

export const INDEX_REPORTS_QUERY = gql`
  query{
    reports{
      amountToSettled
      reports{
        id
        createdAt
        fromDate
        toDate
        restaurant{
          id
          name
        }
        totalAmount
        settled
        linkToCsv 
      }
    }
  }
`


export const INDEX_RESTAURANTS_QUERY = gql`
  query{
    restaurants{
      id
      name
    }
  }
`

export const CREATE_REPORT_QUERY = gql`
  mutation($restaurantIds: [ID!]!, $fromDate: ISO8601Date, $toDate: ISO8601Date!){
    reportsCreate(input:{restaurantIds: $restaurantIds, fromDate: $fromDate, toDate: $toDate}){
      errors {
        field
        messages
      }
      reports {
        id
      }
    }
  }
`


export const CREATE_SETTLEMENT_QUERY = gql`
  mutation($reportIds: [ID!]!){
    settlementCreate(input:{reportIds: $reportIds}){
      errors {
        field
        messages
      }
    }
  }
`

export const REPORT_SHOW_QUERY = gql`
query report($id: ID!){
  report(id: $id){
    id
    createdAt
    fromDate
    toDate
    restaurant{
      id
      name
    }
    orders{
      id
      uniqueNumber
      status
      total 
      createdAt
    }
    totalAmount
    settled
    linkToCsv 
  }
}
`


