import {
  InterfaceActionTypes,
  UPDATE_FLASH_MESSAGE,
  RESET_FLASH_MESSAGE,
  UPDATE_NAVI,
  UPDATE_CURRENT_VIEW
} from './types'


export function updateFlashMessage(state: any): InterfaceActionTypes {
  return {
    type: UPDATE_FLASH_MESSAGE,
    payload: state
  }
}

export function resetFlashMessage(): InterfaceActionTypes {
  return {
    type: RESET_FLASH_MESSAGE
  }
}

export function updateNavi(view: object): InterfaceActionTypes {
  return {
    type: UPDATE_NAVI,
    view
  }
}

export function updateCurrentView(view: object): InterfaceActionTypes {
  return {
    type: UPDATE_CURRENT_VIEW,
    view
  }
}