// GraphQL
import gql from 'graphql-tag'

export const USER_ACTIVATE_MUTATION = gql`
  mutation($token: String!){
    userActivate(input: { token: $token }){
      errors {
        field
        messages
      }
    }
  }
`