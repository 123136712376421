import React from "react";
import ROUTES from "../../../routes";
import { prepareRouteUrl } from "../../../utils/utils";
import { Link as LinkRouter } from "react-router-dom";

import { Link } from "@material-ui/core";
import { Star, StarBorder } from "@material-ui/icons";
import { DataGridTable } from "../../../components/DataGridTable/DataGridTable";
import { FormattedDate } from "../../../components/FormattedData/FormattedData";

interface Props {
  rows: Array<{}>;
}

interface State {
  rows: any;
  columns: Array<{
    field: string;
    title: string;
    render?: any;
    sorting?: boolean;
    cellStyle?: any;
    headerStyle?: any;
    customSort?: any;
  }>;
}

class DataGridFeedback extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      columns: [
        {
          field: "value",
          title: "Ocena",
          headerStyle: { maxWidth: "5%" },
          render: (row) => this.FormattedValue(row.value),
        },
        {
          field: "description",
          title: "Opis",
          headerStyle: { minWidth: "60%" },
        },
        {
          field: "user.email",
          title: "Użytkownik",
          headerStyle: { minWidth: "20%" },
          render: (row) => (row.user ? this.FullName(row) : "brak"),
        },
        {
          field: "updatedAt",
          title: "Utworzono",
          headerStyle: { minWidth: "25%", textAlign: "center" },
          cellStyle: { textAlign: "center" },
          render: (row) => <FormattedDate value={row.updatedAt} />,
        },
      ],
      rows: this.props.rows,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return props.rows !== state.rows ? { rows: props.rows } : null;
  }

  FormattedValue = (value: number) => {
    return (
      <>
        {Array(value)
          .fill(1)
          .map((el, i) => (
            <Star color="primary" />
          ))}
        {Array(5 - value)
          .fill(1)
          .map((el, i) => (
            <StarBorder color="primary" />
          ))}
      </>
    );
  };

  FullName = (row) => {
    const showUrl: string = prepareRouteUrl(ROUTES.users.show.path, {
      id: row.user.id,
    });

    if (!row) return "-";
    const { firstName, lastName, email } = row.user;
    const fullName = firstName && lastName ? `${firstName} ${lastName}` : email;

    return (
      <Link aria-label="Show" component={LinkRouter} to={showUrl}>
        {fullName}
        <br />
        {email}
      </Link>
    );
  };

  ActionsLinks = (row) => {
    let actions: Array<any> = [];

    // const showUrl: string = prepareRouteUrl(ROUTES.waiters.show.path, {
    //   id: row.id,
    // });
    // const editUrl: string = prepareRouteUrl(ROUTES.waiters.edit.path, {
    //   id: row.id,
    // });

    // actions.push(
    //   <Tooltip key={`ShowId-${row.id}`} title="Podgląd">
    //     <IconButton
    //       aria-label="Show"
    //       size="medium"
    //       color="primary"
    //       component={LinkRouter}
    //       to={showUrl}
    //     >
    //       <RemoveRedEye fontSize="inherit" />
    //     </IconButton>
    //   </Tooltip>
    // );

    // actions.push(
    //   <Tooltip key={`EditId-${row.id}`} title="Edycja">
    //     <IconButton
    //       aria-label="Edit"
    //       size="medium"
    //       component={LinkRouter}
    //       to={editUrl}
    //     >
    //       <EditIcon fontSize="inherit" />
    //     </IconButton>
    //   </Tooltip>
    // );

    // actions.push(
    //   <DestroyUser key={`DestroyId-${row.id}`}  id={row.id} name={row.name} btnSmall={true} onCompleted={this.props.onCompleted} />
    // )

    return actions;
  };

  render() {
    const { rows, columns } = this.state;

    return <DataGridTable columns={columns} rows={rows} />;
  }
}

export default DataGridFeedback;
