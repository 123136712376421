import React from 'react'
import ROUTES from '../../../routes'
import { RouteComponentProps } from 'react-router-dom'

// UI
import uiStyles from '../../../assets/styles/styles'
import clsx from 'clsx'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Paper,
  Box,
  Grid,
  Tabs,
  Tab,
  AppBar,
  Typography,
  FormGroup,
  Switch,
  FormControlLabel
} from '@material-ui/core'
import { SuccessAlert, ErrorAlert } from '../../../components/FlashMessage/FlashMessage'
import Loading from '../../../components/Loading/Loading'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { SubmitActionBtn, LoaderBtn, BackActionBtn } from '../../../components/FormActionLinks/FormActionLinks'
import TopBackButton from '../../../components/TopBackButton/TopBackButton'

// GraphQL
import { Mutation, Query } from 'react-apollo'
import { SHOW_USER_QUERY, USER_UPDATE_MUTATION } from '../repositoryGraphQL'


interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  match: any
}

type State = {
  email: string
  firstName: string
  lastName: string
  admin: boolean
  tabIndex: number
}

class EditUser extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      admin: false,
      tabIndex: 0
    }
  }

  handleQueryComplete = (data: any) => {
    const { user } = data

    this.setState({
      email: user.email ? user.email : '',
      admin: user.admin ? user.admin: false,
      firstName: user.firstName ? user.firstName : '',
      lastName: user.lastName ? user.lastName : ''
    })
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ admin: e.target.checked })
  }

  handleTabChange = (e: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      tabIndex: newValue
    })
  }

  render() {
    const { classes } = this.props
    const { id } = this.props.match.params
    const { tabIndex, admin, email, firstName, lastName } = this.state

    const backUrl: string = ROUTES.users.index.path

    return (
      <>
        <Query
          query={SHOW_USER_QUERY}
          variables={{ id }}
          onCompleted={query_data => this.handleQueryComplete(query_data)}
          fetchPolicy='network-only'
        >
          {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return <ErrorAlert error={error} />

            const showName =  firstName &&  lastName ?  firstName + ' ' +  lastName :  email

            return (
              <Mutation
                mutation={USER_UPDATE_MUTATION }
                variables={{ id, email, admin, lastName, firstName }}
                onCompleted={(data: any) => this._confirm(data)}
              >
                {(submitForm: any, { loading, error, data }: any) => (
                  <>
                    <ValidatorForm
                      ref="form"
                      onSubmit={submitForm}
                      onError={(errors: any) => console.log(errors)}
                    >
                      <Paper className={classes.paper}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TopBackButton
                              url={backUrl}
                              title={`${showName}`}
                              subtitle="Edycja użytkownika"
                            />
                          </Grid>
                          <Grid item xs={6} className={classes.formAction}>
                            {!loading && <BackActionBtn text={'Anuluj'} to={backUrl} />}
                            {!loading && <SubmitActionBtn text="Zapisz" />}
                            {loading && <LoaderBtn />}
                          </Grid>
                        </Grid>
                      </Paper>
                      <AppBar position="static" color="default" className={classes.menuTabs}>
                        <Tabs
                          value={tabIndex}
                          onChange={this.handleTabChange}
                          variant="fullWidth"
                          indicatorColor="secondary"
                        >
                          <Tab label="Dane Użytkownika" />
                        </Tabs>
                      </AppBar>
                      <Paper className={classes.tabs}>
                        <Box component="div" className={clsx(classes.tab, tabIndex === 0 && classes.tabShow)} padding={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography>
                                Wprowadź dane użytkownika
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextValidator
                                autoComplete="firstName"
                                name="firstName"
                                fullWidth
                                id="firstName"
                                label="Imię"
                                value={firstName}
                                onChange={(e: { target: { value: string } }) => this.setState({ firstName: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <TextValidator
                                autoComplete="lastName"
                                name="lastName"
                                fullWidth
                                id="lastNamel"
                                label="Nazwisko"
                                value={lastName}
                                onChange={(e: { target: { value: string } }) => this.setState({ lastName: e.target.value })}
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}></Grid>
                            <Grid item xs={12} sm={4}>
                              <TextValidator
                                required
                                fullWidth
                                id="email"
                                label="Adres e-mail"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={(e: { target: { value: string } }) => this.setState({ email: e.target.value })}
                                validators={['required', 'isEmail']}
                                errorMessages={['Pole wymagane.', 'Adres e-mail jest nieprawidłowy.']}
                              />
                            </Grid>
                            <Grid item xs={12} sm={8}></Grid>
                            <Grid item xs={12} sm={5}>
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Switch checked={admin} onChange={this.handleChange} value="1" />
                                  }
                                  label="Administrator"
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </Box>
                      </Paper>
                    </ValidatorForm>
                    {(!loading && data) && <SuccessAlert />}
                    {error && <ErrorAlert error={error} />}
                    {(data && data.userUpdate.errors) && <ErrorAlert modelName="user" fieldError={data.userUpdate.errors} />}
                  </>
                )}
              </Mutation>
            )
          }}
        </Query>
      </>
    )
  }

  _confirm = async (data: any) => {
    if (!data) return

    if (!data.userUpdate) return

    this.setState({
    })

    this.props.history.push(ROUTES.users.index.path)
  }
}


export default withStyles(uiStyles)(EditUser)