import React from 'react'

// UI
import uiStyles from '../../../../../assets/styles/styles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { SuccessAlert, ErrorAlert } from '../../../../../components/FlashMessage/FlashMessage'
import AddIcon from '@material-ui/icons/Add'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { ClosePopupBtn, SubmitActionBtn, LoaderBtn } from '../../../../../components/FormActionLinks/FormActionLinks'
import { CustomDialogTitle } from '../../../../../components/Dialog/Dialog'
import { SketchPicker } from 'react-color'

// GraphQL
import { Mutation } from 'react-apollo'
import { CREATE_VALUE_MUTATION, EDIT_VALUE_MUTATION } from '../../../repositoryGraphQL'

interface Props extends WithStyles<typeof uiStyles> {
  row: any
  edit?: boolean
  sysName?: string
  onCompleted?: any
}

type State = {
  open: boolean
  openPicker: boolean
  name: string
  lockVersion?: number
}

class FormValue extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      open: false,
      openPicker: false,
      name: this.props.edit ? this.props.row.name : '',
      lockVersion: this.props.edit ? this.props.row.lockVersion : ''
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
      return (props.row.lockVersion !== state.lockVersion) ? { lockVersion: props.row.lockVersion } : null
  }

  handleOpen = ():void => ( this.setState({ open: true }) )

  handleClose = ():void =>  ( this.setState({ open: false }) )

  onClick = ():void => this.setState({openPicker: true})

  handleClosePicker = ():void => this.setState({openPicker: false})

  handleChange = (color):void => this.setState({name : color.hex})

  render() {
    const { classes, edit } = this.props
    const { id } = this.props.row
    const { name, openPicker, lockVersion} = this.state

    const editAction = edit ? edit : false
    const colorsDictonary = Object.is(this.props.sysName, 'hex_colors') ? true : false

    return (
      <>
        {
          editAction ?
              <Tooltip title="Edycja">
                <IconButton aria-label="Edit" size="medium" onClick={() => this.handleOpen()}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            :
              <Button aria-label="Add" variant="outlined" color="primary" size="small" className={classes.btn_add_value} onClick={() => this.handleOpen()}>
                <AddIcon /> Nowa pozycja
              </Button>
        }
        <Mutation
          mutation={editAction ? EDIT_VALUE_MUTATION : CREATE_VALUE_MUTATION}
          variables={{ id, name, lockVersion }}
          onCompleted={(data: any) => this._confirm(data)}
        >
          {(submitForm: any, { loading, error, data }: any) => {

            return (
              <>
                <Dialog fullWidth maxWidth="md" open={this.state.open} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title">
                  <CustomDialogTitle id="form-dialog-title" onClose={() => this.handleClose()}>
                  {
                      editAction ? 'Edycja pozycji słownika' : 'Nowa pozycja słownika'
                  }  
                </CustomDialogTitle>
                  <ValidatorForm
                    ref="form"
                    onSubmit={submitForm}
                  >
                    <DialogContent dividers>
                      <DialogContentText>
                        Dane pozycji słownika
                    </DialogContentText>
                   
                      <Grid className={classes.bottomSpace} container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          {
                            colorsDictonary &&
                            <Dialog open={openPicker} onClose={() => this.handleClosePicker()} aria-labelledby="form-dialog-title">
                              <SketchPicker 
                                color={ this.state.name }
                                onChangeComplete={this.handleChange} 
                              /> 
                           </Dialog>
                          }
                          <TextValidator
                            autoComplete="name"
                            name="name"
                            required
                            fullWidth
                            id="name"
                            label="Nazwa"
                            value={name}
                            onClick={colorsDictonary ? this.onClick : null}
                            onChange={(e: { target: { value: string } }) => this.setState({ name: e.target.value })}
                            validators={['required']}
                            errorMessages={['Pole wymagane.']}
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      {!loading && <ClosePopupBtn onClick={() => this.handleClose()} text="Anuluj" />}
                      {!loading && <SubmitActionBtn text="Zapisz" />}
                      {loading && <LoaderBtn />}
                    </DialogActions>
                  </ValidatorForm>
                </Dialog>
                {(!loading && data) && <SuccessAlert message="Utworzono pomyślnie." />}
                {error && <ErrorAlert error={error} />}
                {(data && data.dictionaryValueCreate) && <ErrorAlert modelName="dictionaryValue" fieldError={data.dictionaryValueCreate.errors} />}
                {(data && data.dictionaryValueUpdate) && <ErrorAlert modelName="dictionaryValue" fieldError={data.dictionaryValueUpdate.errors} />}
              </>
            )
          }}
        </Mutation>
      </>
    )
  }

  _confirm = async (data: any) => {
    if (!data) return

    this.setState({
      open: false,
      name: '',
      lockVersion: data.dictionaryValueUpdate ? data.dictionaryValueUpdate.value.lockVersion : '',
    })

    this.props.onCompleted()
  }
}
export default withStyles(uiStyles)(FormValue)