import React from 'react'


// UI
import MaterialTable from 'material-table'
import { ArrowUpward } from '@material-ui/icons'
import { DataGridGroupRow } from './DataGridGroupRow/DataGridGroupRow'
import { DataGridTipsGroupRow } from './DataGridTipsGroupRow/DataGridTipsGroupRow'


interface Props {
  columns: Array<{}>
  rows: Array<{}>
  pageSize?: number 
  search?: boolean
  selection?: boolean
  onSelectionChange?: any
  selectionProps?: any
  selectAll?: boolean
  waiterTips?: boolean
}

export const DataGridTable: React.FC<Props> = (props) => {

  return (
    <MaterialTable
      components={{
        Container: props => props.children,
        GroupRow: props_ => props.waiterTips ? <DataGridTipsGroupRow {...props_} /> : <DataGridGroupRow {...props_} />
      }}
      title=""
      columns={props.columns}
      data={props.rows}
      options={{
        actionsColumnIndex: -1,
        showTitle: false,
        pageSize: props.pageSize ? props.pageSize : 20,
        search: props.search,
        toolbar: props.search,
        emptyRowsWhenPaging: false,
        selection: props.selection,
        selectionProps: props.selectionProps,
        showSelectAllCheckbox: props.selectAll,
        pageSizeOptions: [20, 50, 100],
      }}
      localization={{
        header: {
          actions: 'Akcje'
        },
        toolbar: {
          searchTooltip: 'Szukaj',
          searchPlaceholder: 'Szukaj'
        },
        pagination: {
          labelRowsSelect: 'wierszy',
          labelDisplayedRows: ' {from}-{to} z {count}',
          firstTooltip: 'Pierwsza strona',
          previousTooltip: 'Poprzednia strona',
          nextTooltip: 'Następna strona',
          lastTooltip: 'Ostatnia strona'
        }
      }}
      icons={{
        SortArrow: React.forwardRef((props, ref) => <ArrowUpward {...props} fontSize="small" ref={ref}/>)
     }}
     onSelectionChange={props.onSelectionChange}
    />
  )
}