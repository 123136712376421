import React from 'react'

// UI
import uiStyles from '../../../assets/styles/styles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormGroup,
  Tooltip,
  Button,
  Grid
} from '@material-ui/core'
import { ValidatorForm } from 'react-material-ui-form-validator'
import FormDatePicker from '../../../components/FormDatePicker/FormDatePicker'
import { ErrorAlert, SuccessAlert } from '../../../components/FlashMessage/FlashMessage'
import { SubmitActionBtn, LoaderBtn, ClosePopupBtn } from '../../../components/FormActionLinks/FormActionLinks'
import { CustomDialogTitle } from '../../../components/Dialog/Dialog'

// GraphQL
import { Mutation } from 'react-apollo'
import { CREATE_SETTLEMENT_QUERY } from '../repositoryGraphQL'


interface Props extends WithStyles<typeof uiStyles> {
  onCompleted?: any
}

type State = {
  fromDate: Date | null
  toDate: Date | null
  restaurantIds: Array<any>
  open: boolean
}

class CreateSettlement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      fromDate: null,
      toDate: new Date(),
      restaurantIds: [],
      open: false,
    }
  }

  handleChange = (value): void => this.setState({ restaurantIds: value })

  open = (): void => this.setState({ open: true })

  close = (): void => this.setState({ open: false })

  render() {
    const { classes } = this.props
    const { fromDate, toDate, restaurantIds, open } = this.state

    return (
      <>
        <Tooltip title="Utwórz raport">
          <Button aria-label="Settlement" variant="outlined" onClick={this.open}> Wygeneruj rozliczenie </Button>
        </Tooltip>
        <Mutation
          mutation={CREATE_SETTLEMENT_QUERY}
          variables={{ fromDate, toDate, restaurantIds }}
          onCompleted={(data: any) => this._confirm(data)}
        >
          {(submitForm: any, { loading, error, data }: any) => {

            return (
              <>
                {
                  open &&
                  <Dialog fullWidth maxWidth="md" open={this.state.open} onClose={this.close} aria-labelledby="form-dialog-title">
                    <CustomDialogTitle id="form-dialog-title" onClose={this.close}>
                      Wygeneruj rozliczenie
                          </CustomDialogTitle>
                    <ValidatorForm
                      ref="form"
                      onSubmit={submitForm}
                    >
                      <DialogContent style={{ minHeight: 120 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormGroup row>
                              <FormDatePicker
                                label="Od"
                                name="fromDate"
                                value={fromDate}
                                className={classes.formControl}
                                onChange={(date: any) => this.setState({ fromDate: date })}
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormGroup row>
                              <FormDatePicker
                                required
                                label="Do"
                                name="toDate"
                                maxDate={new Date()}
                                value={toDate}
                                className={classes.formControl}
                                onChange={(date: any) => this.setState({ toDate: date })}
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        {!loading && <ClosePopupBtn onClick={this.close} text="Anuluj" />}
                        {!loading && <SubmitActionBtn  text="Generuj" />}
                        {loading && <LoaderBtn />}
                      </DialogActions>
                    </ValidatorForm>
                  </Dialog>
                }

                {!loading && data &&
                  (
                    data.settlementCreateForWaiters.settlement && data.settlementCreateForWaiters.settlement.id ?
                      <SuccessAlert message="Utworzono pomyślnie." />
                      :
                      <ErrorAlert message="Dla wybranych parametrów nie zostało wygenerowane żadne rozliczenie." />
                  )
                }
                {error && <ErrorAlert error={error} />}
                {(data && data.settlementCreateForWaiters) && <ErrorAlert modelName="report" fieldError={data.settlementCreateForWaiters.errors} />}
              </>
            )
          }}
        </Mutation>
      </>
    )
  }


  _confirm = async (data: any) => {
    if (!data || data.settlementCreateForWaiters.settlement === null) return

    this.setState({ open: false })

    this.props.onCompleted()
  }
}

export default withStyles(uiStyles)(CreateSettlement)