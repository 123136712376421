import React from 'react'
import Auth from '../../utils/Auth/Auth'
import { RouteComponentProps, Link as LinkRouter } from 'react-router-dom'
import ROUTES from '../../routes'

// UI
import uiStyles from '../../assets/styles/styles'
import {
  Avatar,
  CssBaseline,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Typography,
  Container
} from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { ErrorAlert, SuccessAlert } from '../../components/FlashMessage/FlashMessage'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { LockOutlined, Visibility, VisibilityOff } from '@material-ui/icons'
import { LongActionBtn, LoaderLongBtn } from '../../components/FormActionLinks/FormActionLinks'

// GraphQL
import { Mutation } from 'react-apollo'
import { LOGIN_MUTATION } from './repositoryGraphQL'


interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  //
}

type State = {
  email: string,
  password: string,
  showPassword: boolean
}

class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      showPassword: false
    }
    Auth.clear()
  }

  handleClickShowPassword = () => {this.setState({ showPassword: !this.state.showPassword})}

  render() {
    const { classes } = this.props
    const { email, password, showPassword } = this.state

    return (
      <Mutation
        mutation={LOGIN_MUTATION}
        variables={{ email, password }}
        onCompleted={(data: any) => this._confirm(data)}
      >
        {(submitForm: any, { loading, data, error }: any) => (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper_auth}>
              <Avatar className={classes.avatar}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Logowanie
              </Typography>
              <ValidatorForm
                ref="form"
                onSubmit={submitForm}
                className={classes.form}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Adres e-mail"
                      name="email"
                      autoComplete="email"
                      value={email.trim()}
                      onChange={(e: { target: { value: string } }) => this.setState({ email: e.target.value })}
                      validators={['required', 'isEmail']}
                      errorMessages={['Pole wymagane.', 'Adres e-mail jest nieprawidłowy.']}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Hasło"
                      id="password"
                      autoComplete="password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e: { target: { value: string } }) => this.setState({ password: e.target.value })}
                      validators={['required']}
                      errorMessages={['Pole wymagane.']}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              aria-label="Toggle password visibility"
                              onClick={this.handleClickShowPassword}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox value="1" color="primary" />}
                      label="Zapamiętaj mnie"
                    />
                  </Grid>
                </Grid>
                {!loading && <LongActionBtn text="Zaloguj się" />}
                {loading && <LoaderLongBtn />}
                {(!loading && data) && <SuccessAlert message="Zalogowano pomyślnie." />}
                {error && <ErrorAlert error={error} />}
                {(data && !data.login.user.admin) && <ErrorAlert message="Brak uprawnień" />}
                {(data && data.login.errors) && <ErrorAlert modelName="login" fieldError={data.login.errors} />}
                <Grid container justify="flex-end">
                  <Grid xs={12} item component="div" className={classes.alignRight}>
                    <Link variant="body2" component={LinkRouter} to={ROUTES.resetPassword.request.path}>
                        Przypomnij hasło
                    </Link>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
          </Container>
        )}
      </Mutation>
    )
  }


  _confirm = async (data: any) => {
    if (data) {
      const { token, user } = data.login
      if (token !== null && user && data.login.user.admin)  {
          Auth.setToken(token)
          Auth.setId(user.id)

          this.props.history.push(ROUTES.home.path)
      }
    }
  }
}

export default withStyles(uiStyles)(Login)