import React from 'react'

// UI
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { InputLabel, Typography } from '@material-ui/core'

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      margin: theme.spacing(3, 0, 1, 1),
    },
    typography: {
      margin: theme.spacing(0, 0, 0, 1),
    }
  }),
)

type Props = {
  label: string
  value: any
}

export const DisplayLabelValue: React.FC<Props> = (props) => {

  const classes = uiStyles()

  return (
    <>
      <InputLabel
        shrink
        className={classes.inputLabel}>
        {props.label}
      </InputLabel>
      <Typography
        variant="body2"
        component="div"
        className={classes.typography}
      >
        {props.value}
      </Typography>
    </>
  )
}
