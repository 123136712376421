import React, { useEffect } from "react";
import { connect } from "react-redux";
import { AppState } from "../../../store";
import ROUTES from "../../../routes";

// UI
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  Icon,
} from "@material-ui/core";
import { Link as LinkRouter, useLocation } from "react-router-dom";

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

const naviLinks = [
  // {
  //   name: '',
  //   children: [
  //     {
  //       name: 'Dashboard',
  //       url: ROUTES.home.path,
  //       icon: 'apps'
  //     }
  //   ]
  // },
  {
    name: "",
    children: [
      {
        name: "Użytkownicy",
        url: ROUTES.users.index.path,
        route: "user",
        icon: "people_alt",
      },
      {
        name: "Restauracje",
        url: ROUTES.restaurants.index.path,
        route: "restaurant",
        icon: "local_dining",
      },
      {
        name: "Kelnerzy",
        url: ROUTES.waiters.index.path,
        route: "waiter",
        icon: "supervised_user_circle",
      },
      {
        name: "Klienci",
        url: ROUTES.clients.index.path,
        route: "client",
        icon: "people",
      },
      {
        name: "Promocje",
        url: ROUTES.promotions.index.path,
        route: "promotion",
        icon: "assessment",
      },
    ],
  },
  {
    name: "Rozliczenia",
    nested: false,
    children: [
      {
        name: "Raporty",
        url: ROUTES.reports.index.path,
        route: "report",
        icon: "assessment",
      },
      {
        name: "Napiwki",
        url: ROUTES.tips.index.path,
        route: "tip",
        icon: "assessment",
      },
      {
        name: "Rozliczenia",
        url: ROUTES.settlements.index.path,
        route: "settlement",
        icon: "receipt",
      },
    ],
  },
  {
    name: "Ustawienia",
    icon: "settings",
    nested: false,
    children: [
      {
        name: "Słowniki",
        url: ROUTES.dictionaries.index.path,
        route: "dictionar",
        icon: "books",
      },
      {
        name: "Feedback",
        url: ROUTES.feedback.index.path,
        route: "feedback",
        icon: "star",
      },
    ],
  },
];

type Props = {
  interface: any;
};

const Navi: React.FC<Props> = (props) => {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
  const [open, setOpen] = React.useState<boolean>(false);
  const classes = uiStyles();

  const { pathname } = useLocation();

  let selectedIndex2 = selectedIndex;

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ): void => setSelectedIndex(index);
  const handleNestedClick = (): void => setOpen(!open);

  // Set active item of window location path
  naviLinks.forEach((group: any) => {
    group.children.forEach((item: any) => {
      const currentPath = pathname;
      item.active = item.route
        ? currentPath.includes(item.route)
        : currentPath === item.url || currentPath.includes(`${item.url}/`);
      selectedIndex2 = -1;
    });
  });

  useEffect(() => {
    naviLinks.forEach((group: any) => {
      group.children.forEach((item: any) => {
        const currentPath = pathname;
        item.active = item.route
          ? currentPath.includes(item.route)
          : currentPath === item.url || currentPath.includes(`${item.url}/`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        selectedIndex2 = -1;
      });
    });
  }, [pathname]);

  return (
    <>
      {naviLinks.map(
        (navi_group: any, i: number): JSX.Element => {
          if (!navi_group.nested) {
            return (
              <React.Fragment key={i}>
                <List
                  component="nav"
                  subheader={
                    <ListSubheader component="div">
                      {navi_group.name}
                    </ListSubheader>
                  }
                >
                  {navi_group.children.map(
                    (link: any, j: number): JSX.Element => {
                      let index = i * 10 + j;

                      return (
                        <ListItem
                          key={index}
                          button
                          component={LinkRouter}
                          to={link.url}
                          selected={selectedIndex2 === index || link.active}
                          onClick={(event) => handleClick(event, index)}
                        >
                          {link.icon && (
                            <ListItemIcon>
                              <Icon>{link.icon}</Icon>
                            </ListItemIcon>
                          )}
                          <ListItemText primary={link.name} />
                        </ListItem>
                      );
                    }
                  )}
                </List>
                <Divider />
              </React.Fragment>
            );
          } else {
            // Check if open collapse
            let openCollapse: boolean = false;
            navi_group.children.forEach((item: any) => {
              if (item.active) openCollapse = true;
            });

            return (
              <React.Fragment key={i}>
                <ListItem button onClick={handleNestedClick}>
                  <ListItemIcon>
                    <Icon>{navi_group.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={navi_group.name} />
                  <Icon>
                    {open || openCollapse ? "expand_less" : "expand_more"}
                  </Icon>
                </ListItem>

                {navi_group.children.map(
                  (link: any, j: number): JSX.Element => {
                    let index = i * 10 + j;

                    return (
                      <Collapse
                        key={index}
                        in={open || openCollapse}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <ListItem
                            button
                            component={LinkRouter}
                            className={classes.nested}
                            to={link.url}
                            selected={selectedIndex2 === index || link.active}
                            onClick={(event) => handleClick(event, index)}
                          >
                            {link.icon && (
                              <ListItemIcon>
                                <Icon>{link.icon}</Icon>
                              </ListItemIcon>
                            )}
                            <ListItemText primary={link.name} />
                          </ListItem>
                        </List>
                      </Collapse>
                    );
                  }
                )}
                <Divider />
              </React.Fragment>
            );
          }
        }
      )}
    </>
  );
};

// export default Navi

const mapStateToProps = (state: AppState) => ({
  interface: state.interface.navi,
});

export default connect(mapStateToProps)(Navi);
