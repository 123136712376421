import React from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import ROUTES from '../../../routes'
import { prepareRouteUrl } from '../../../utils/utils'

import { IconButton, Tooltip, Link } from '@material-ui/core'
import { DataGridTable } from '../../../components/DataGridTable/DataGridTable'
import { FormattedDate, FormattedPrice } from '../../../components/FormattedData/FormattedData'
import { RemoveRedEye } from '@material-ui/icons'


interface Props {
  rows: Array<{}>
}

interface State {
  rows: any
  columns: Array<{
    field: string
    title: string
    render?: any,
    cellStyle?: any,
    headerStyle?: any
    customSort?:any
  }>
}

class DataGridTips extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      columns: [
        { field: 'order.uniqueNumber', title: 'Numer zamówienia' },
        { field: 'order.restaurant.name', title: 'Restauracja', headerStyle: { minWidth: '20%' }, render: row => this.toLink(row),  customSort: (a, b) => a.order.restaurant.name > b.order.restaurant.name },
        { field: 'value', title: 'Kwota', headerStyle: { minWidth: '10%', textAlign: 'center' }, cellStyle: { textAlign: 'center' }, render: row => <FormattedPrice value={row.value} />},
        { field: 'order.createdAt', title: 'Data utworzenia', headerStyle: { minWidth: '20%' }, render: row => <FormattedDate format="DD.MM.YYYY HH:mm:ss" value={row.order.createdAt} />},
        // { field: 'actions', title: 'Akcje', headerStyle: { minWidth: '25%', textAlign: 'right' }, cellStyle: { textAlign: 'right' }, render: row => this.ActionsLinks(row) }
      ],
      rows: this.props.rows
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return (props.rows !== state.rows) ? { rows: props.rows } : null
  }

  toLink = (row) => {
    const showUrl: string = prepareRouteUrl(ROUTES.reports.show.path, { id: row.id })
    
    return (<Link aria-label="Show" component={LinkRouter} to={showUrl}>{row.order.restaurant.name}</Link>) 
  }

  ActionsLinks = (row) => {

    let actions: Array<any> = []

    const showUrl: string = prepareRouteUrl(ROUTES.reports.show.path, { id: row.id })

    actions.push(
      <Tooltip key={`ShowId-${row.id}`} title="Podgląd">
        <IconButton aria-label="Show" size="medium" color="primary" component={LinkRouter} to={showUrl}>
          <RemoveRedEye fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )

    return actions
  }

  render() {

    const {
      rows, columns
    } = this.state

    return (
      <DataGridTable columns={columns} rows={rows} />
    )
  }
}

export default DataGridTips