import React from 'react'

// UI
import uiStyles from './uiStyles'
import {
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Input,
  ListItemText,
  Chip,
} from '@material-ui/core'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Props = {
  required?: boolean
  data:any
  onChange: any
}

const SelectCheckbox: React.FC<Props> = ({ data, onChange }) => {
  const classes = uiStyles()

  const [ids, setIds] = React.useState<Array<{}>>([])

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    onChange(event.target.value)
    setIds(event.target.value)
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="select-multiple-checkbox">Wybierz restauracje</InputLabel>
      <Select
        required
        multiple
        value={ids}
        onChange={handleChange}
        input={<Input id="select-multiple-checkbox" />}
        renderValue={selected => (
          <Grid className={classes.noWrap}>
            {(selected as string[]).map(value => {
              const values = data.filter((item: any) => (item.id === value))
              return (
                <Chip key={value} label={values[0].name} className={classes.chipWithMargin} />
              )
            })}
          </Grid>
        )}
        MenuProps={MenuProps}
        inputProps={{
          required: true
        }}
      >
        {data.map(value => (
          <MenuItem key={value.name} value={value.id}>
            <Checkbox checked={ids.indexOf(value.id) > -1} />
            <ListItemText primary={value.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectCheckbox