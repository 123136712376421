import {
  InterfaceState,
  InterfaceActionTypes,
  UPDATE_FLASH_MESSAGE,
  RESET_FLASH_MESSAGE,
  UPDATE_NAVI,
  UPDATE_CURRENT_VIEW
} from './types'


const initialState: InterfaceState = {
  currentView: {
    name: ''
  },
  navi: {
    name: ''
  },
  flashMessage: {
    open: false,
    type: 'default',
    message: '',
    autoHideDuration: null,
    refreshAction: false,
    closeAction: true,
  },
}

export function interfaceReducer(
  state = initialState,
  action: InterfaceActionTypes
): InterfaceState {
  switch (action.type) {
    case UPDATE_FLASH_MESSAGE: {
      return {
        ...state,
        flashMessage: {
          ...state.flashMessage,
          ...action.payload
        }
      }
    }
    case RESET_FLASH_MESSAGE: {
      return {
        ...state,
        flashMessage: {
          ...state.flashMessage,
          open: false,
          autoHideDuration: null,
          refreshAction: false,
          closeAction: true,
        }
      }
    }
    case UPDATE_NAVI: {
      return {
        ...state,
        navi: {
          ...action.view
        }
      }
    }
    case UPDATE_CURRENT_VIEW: {
      return {
        ...state,
        currentView: {
          ...action.view
        }
      }
    }
    default:
      return state
  }
}