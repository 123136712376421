import React from 'react'

// UI
import Moment from 'react-moment'
import { amountFormat, BankAccountNumberFormatter, PostCodeFormatter } from '../../utils/formatters'

/**
 * Date
 */
export const FormattedDate = (props: { value: string | null, format?: string }) => {
  if (!props.value) return <>{'-'}</>

  return <Moment format={props.format || 'DD.MM.YYYY'}>{props.value}</Moment>
  // <>{DateFormatter(props.value)}</>
}

/**
 * Phone
 */
export const FormattedPhone = (props: { children: string }) => (
  props.children
)

/**
 * Post Code
 */
export const FormattedPostCode = (props: { value: string | null }) => (
  <>{PostCodeFormatter(props.value)}</>
)

/**
 * Price
 */
export const FormattedPrice = (props: { value: number }) => (
  <>{amountFormat(props.value)}</>
)

/**
 * Bank Account Number
 */
 export const FormattedBankAccountNumber = (props: { value: number, countryCode: string }) => (
  <>{props.countryCode} {BankAccountNumberFormatter(props.value)}</>
)