import React from 'react'
import { connect } from 'react-redux'
import { setAuthUser } from '../../store/auth_user/actions'
import { updateDictionaries } from '../../store/dictionaries/actions'

// UI
import IntroSplash from '../IntroSplash/IntroSplash'

// GraphQL
import { ApolloClient } from 'apollo-client'
import { withApollo, compose } from 'react-apollo'
import { VIEWER_QUERY, DICTIONARIES_QUERY } from './repositoryGraphQL'

interface Props {
  children?: JSX.Element
  client: ApolloClient<any>
  setAuthUser: typeof setAuthUser
  updateDictionaries: typeof updateDictionaries
}

type State = {
  progress: number
  total: number
}

class AppDataFetch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      progress: 0,
      total: 2
    }

    this.fetchAuthUser()
    this.fetchDictionaries()
  }

  fetchAuthUser() {
    this.props.client.query({
      query: VIEWER_QUERY
    })
      .then((result: any) => {
        const { data } = result

        this.props.setAuthUser(data.viewer)

        this.setState({ progress: this.state.progress + 1 })
      })
  }

  fetchDictionaries() {
    this.props.client.query({
      query: DICTIONARIES_QUERY
    })
      .then((result: any) => {
        let newDictionaries: Array<{}> = []

        result.data.dictionaries.forEach((item: any) => {
          newDictionaries[item.sysName] = item
        })

        this.props.updateDictionaries(newDictionaries)

        this.setState({ progress: this.state.progress + 1 })
      })
  }

 

  render() {
    const { progress, total } = this.state
    return progress === total ? this.props.children : <IntroSplash />
  }
}

export default compose(
  withApollo,
  connect(null, { setAuthUser, updateDictionaries})
)(AppDataFetch)