import { createStyles, Theme } from '@material-ui/core/styles'

const uiStyles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  cell: {
    padding: theme.spacing(0.5)
  },
  cellHead: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    fontSize: '0.75em',
    lineHeight: 1.4
  },
})

export default uiStyles