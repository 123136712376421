import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import ROUTES from '../../../routes'
import Auth from '../../../utils/Auth/Auth'

// UI
import uiStyles from '../../../assets/styles/styles'
import {
  CssBaseline,
  Avatar,
  Typography,
  Container,
  withStyles,
  WithStyles
} from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { ErrorAlert, SuccessAlert } from '../../../components/FlashMessage/FlashMessage'
import { LongActionBtn, LoaderLongBtn } from '../../../components/FormActionLinks/FormActionLinks'

// GraphQL
import { Mutation } from 'react-apollo'
import { USER_ACTIVATE_MUTATION } from '../repositoryGraphQL'

interface Props extends WithStyles<typeof uiStyles>, RouteComponentProps {
  match: any
}


class ActivateUser extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)

    Auth.clear()
  }

  render() {
    const { classes } = this.props
    const { token } = this.props.match.params

    return (
      <Mutation
        mutation={USER_ACTIVATE_MUTATION}
        variables={{ token }}
        onCompleted={(data: any) => this._confirm(data)}
      >
        {(submitForm: any, { loading, data, error }: any) => (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper_auth}>
              <Avatar className={classes.avatar}>
                <LockOutlined />
              </Avatar>
              <Typography component="h1" variant="h5">
                Aktywacja konta
              </Typography>
              <ValidatorForm
                ref="form"
                onSubmit={submitForm}
                className={classes.form}
              >
                {!loading && <LongActionBtn text="Aktywuj" />}
                {loading && <LoaderLongBtn />}
                {(!loading && data) && <SuccessAlert message="Konto zostało aktywowane" />}
                {error && <ErrorAlert error={error} />}
                {(data && data.userActivate.errors) && <ErrorAlert modelName="user" fieldError={data.userActivate.errors} />}
              </ValidatorForm>
            </div>
          </Container>
        )}
      </Mutation>
    )
  }

  _confirm = async (data: any) => {
    if (data)
      this.props.history.push(ROUTES.auth.login.path)
  }
}

export default withStyles(uiStyles)(ActivateUser)