import React from "react";

// UI
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { Link as LinkRouter } from "react-router-dom";

const uiStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginBtn: {
      margin: theme.spacing(1),
    },
    longBtn: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);

type Props = {
  to?: string;
  text?: string;
  onClick?: any;
  disabled?: boolean;
};

/**
 * Back
 */
export const BackActionBtn: React.FC<Props> = (props) => {
  const classes = uiStyles();

  return (
    <Button
      component={LinkRouter}
      to={props.to || "/"}
      className={classes.marginBtn}
    >
      {props.text || "Anuluj"}
    </Button>
  );
};

/**
 * Back - close POPUP
 */
export const ClosePopupBtn: React.FC<Props> = (props) => {
  const classes = uiStyles();

  return (
    <Button onClick={() => props.onClick()} className={classes.marginBtn}>
      {props.text || "Anuluj"}
    </Button>
  );
};

/**
 * Submit
 */
export const SubmitActionBtn: React.FC<Props> = (props) => {
  const classes = uiStyles();

  return (
    <Button
      disabled={props.disabled ? props.disabled : false}
      type="submit"
      variant="contained"
      color="primary"
      className={classes.marginBtn}
    >
      {props.text || "Zapisz"}
    </Button>
  );
};

/**
 * Submit
 */
export const SubmitBtn: React.FC<Props> = (props) => {
  return (
    <Button
      disabled={props.disabled ? props.disabled : false}
      onClick={() => props.onClick()}
      size="small"
      variant="contained"
      color="primary"
      formNoValidate
    >
      {props.text || "Zapisz"}
    </Button>
  );
};

/**
 * Loader
 */
export const LoaderBtn: React.FC<Props> = (props) => {
  const classes = uiStyles();

  return (
    <Button
      className={classes.marginBtn}
      type="button"
      variant="contained"
      disabled
    >
      <CircularProgress size={24} color="primary" />
    </Button>
  );
};

/**
 * Long Button
 */
export const LongActionBtn: React.FC<Props> = (props) => {
  const classes = uiStyles();

  return (
    <Button
      fullWidth
      type="submit"
      size="large"
      variant="contained"
      color="primary"
      className={classes.longBtn}
    >
      {props.text || "Zapisz"}
    </Button>
  );
};

/**
 * Long button Loader
 */
export const LoaderLongBtn = () => {
  const classes = uiStyles();

  return (
    <Button
      fullWidth
      type="button"
      size="large"
      variant="contained"
      className={classes.longBtn}
      disabled
    >
      <CircularProgress size={24} color="primary" />
    </Button>
  );
};
