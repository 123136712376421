// GraphQL
import gql from "graphql-tag";

export const INDEX_FEEDBACK_QUERY = gql`
  query {
    feedbacks {
      id
      description
      updatedAt
      value
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;
