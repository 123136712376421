import React from 'react'

import uiStyles from '../../../../assets/styles/styles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import EditValue from '../Value/EditValue/EditValue'
import DestroyValue from '../Value/DestroyValue/DestroyValue'
import { DataGridTable } from '../../../../components/DataGridTable/DataGridTable'
import { Box } from '@material-ui/core'

interface Props extends WithStyles<typeof uiStyles>{
  rows: Array<{}>
  sysName?: string
  updateDataGrid: any

}

interface State {
  rows: any
  columns: Array<{
    field: string
    title: string
    render?: any,
    cellStyle?: any,
    headerStyle?: any
  }>
}

class DataGridValues extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)

    this.state = {
      columns: [
        { field: 'name', title: 'Nazwa', headerStyle: { minWidth: '80%' }, render: row => this.CustomName(row) },
        { field: 'actions', title: 'Akcje', headerStyle: { minWidth: '20%', textAlign: 'right' }, cellStyle: { textAlign: 'right' }, render: row => this.ActionsLinks(row) }
      ],
      rows: this.props.rows
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    return (props.rows !== state.rows) ? { rows: props.rows } : null
  }

  CustomName = (row) => {

    const styles = {
      width: 10, 
      height: 10, 
      display: 'inline-block',
      border: '1px solid #000',
      verticalAlign: 'middle'
    }

    if(Object.is(this.props.sysName, 'hex_colors')) 
    return (
      <><Box style={styles} bgcolor={row.name} p={1} m={1}></Box><span style={{display: 'inline-block', verticalAlign: 'middle'}}>{row.name}</span></>
    )

    return row.name
  }
  
  ActionsLinks = (row) => {

    let actions: Array<any> = []

    if (row.editable) {
      actions.push(<EditValue edit key={`EditId-${row.id}`} sysName={this.props.sysName} row={row} onCompleted={() => this.props.updateDataGrid()} />)
    }

    if (row.destroyable) {
      actions.push(<DestroyValue key={`DestroyId-${row.id}`} id={row.id} name={row.name} btnSmall={true} onCompleted={() => this.props.updateDataGrid()} />)
    }

    return actions
  }

  render() {

    const {
      rows, columns
    } = this.state

    return (

      <DataGridTable columns={columns} rows={rows} />

    )
  }
}

export default withStyles(uiStyles)(DataGridValues)