import React from 'react'

// UI
import uiStyles from '../../../../assets/styles/styles'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import {
  Button,
  Tooltip,
  Grid,
  Typography,
  InputLabel,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Divider,
  Chip
} from '@material-ui/core'
import { CustomDialogTitle } from '../../../../components/Dialog/Dialog'
import { RemoveRedEye } from '@material-ui/icons'

interface Props extends WithStyles<typeof uiStyles> {
  row: any
}

type State = {
  open: boolean,
}


class ShowMenuItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      open: false
    }
  }

  handleClickOpen = () => {
    this.setState({
      open: true
    })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {

    const { classes } = this.props
    const { name, description, category, price, calories, allergens, imageUrl } = this.props.row

    return (
      <>
        <Tooltip title="Podgląd">
          <IconButton aria-label="Edit" size="medium" onClick={() => this.handleClickOpen()}>
            <RemoveRedEye fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Dialog fullWidth maxWidth="md" open={this.state.open} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title">
          <CustomDialogTitle id="form-dialog-title" onClose={() => this.handleClose()}> {name} </CustomDialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              {
                imageUrl &&
                <Grid container alignContent="center">
                  <Grid item sm={12}>
                    <img alt="" src={imageUrl} className={classes.img} />
                  </Grid>
                </Grid>
              }
              <Grid item xs={12} sm={6}>
                <InputLabel
                  shrink
                  className={classes.inputLabel}>
                  Nazwa
                </InputLabel>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.typography}
                >
                  {name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  shrink
                  className={classes.inputLabel}>
                  Kategoria
                </InputLabel>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.typography}
                >
                  {category}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel
                  shrink
                  className={classes.inputLabel}>
                  Opis
                  </InputLabel>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.typography}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  shrink
                  className={classes.inputLabel}>
                  Cena:
                  </InputLabel>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.typography}
                >
                  {price}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  shrink
                  className={classes.inputLabel}>
                  Kalorie:
                  </InputLabel>
                <Typography
                  variant="body2"
                  component="p"
                  className={classes.typography}
                >
                  {calories}
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  shrink
                  className={classes.inputLabel}>
                  Alergeny:
                  </InputLabel>
                <Typography
                  variant="body2"
                  component="div"
                  className={classes.typography}
                >
                  {
                    allergens.map(item => (<Chip key={item.id} label={item.name} className={classes.chipWithMargin} />))
                  }

                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} size="small" color="primary">
              Anuluj
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

export default withStyles(uiStyles)(ShowMenuItem)